import React, { useState } from "react";
import CustomButton from "../elements/CustomButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { REACT_TOAST_CONFIG } from "../../utils/utilsFunctions";
import {
  ForgotPasswordFormSchema,
  ResetPasswordFormSchema,
} from "../../validations/formDataSchema";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Field from "../inputs/Field";

const BASE_URL = process.env.REACT_APP_BASE_URL;

type Inputs = z.infer<typeof ForgotPasswordFormSchema>;

interface ForgotPasswordDialogProps {
  onClose: () => void;
}

const ResetPassword: React.FC<ForgotPasswordDialogProps> = ({ onClose }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(ResetPasswordFormSchema),
    mode: "onBlur",
  });

  const onInvalid = (errors: any) => console.error(errors);

  const [loading, setLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [axiosErrors, setAxiosErrors] = useState<any>(null);

  const handleResetPassword: SubmitHandler<Inputs> = async (data: any) => {
    setLoading(true);

    const { email, resetPin, password } = data;

    try {
      const { data } = await axios.put(
        `${BASE_URL}/account/forgetPasswordReset/${resetPin}`,
        {
          email,
          password,
        }
      );

      console.log("result", data);

      if (!data?.isError === true) {
        setLoading(false);
        setResetEmailSent(true);
        // setOpenForgotPassword(false);
        onClose();
        reset();
        toast.success(data?.message, REACT_TOAST_CONFIG);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setAxiosErrors(error);
    }
  };

  return (
    <>
      {!resetEmailSent && (
        <form
          onSubmit={handleSubmit(handleResetPassword, onInvalid)}
          className="h-full"
        >
          <div className="mb-2 h-full py-2">
            <Field
              type="email"
              label="Enter your email"
              fieldName="email"
              errors={errors}
              control={control}
            />
          </div>
          <div className="mb-2 h-full py-2">
            <Field
              type="number"
              label="Enter your reset pin"
              fieldName="resetPin"
              errors={errors}
              control={control}
            />
          </div>
          <div className="mb-2 h-full py-2">
            <Field
              type="password"
              label="Enter your new password"
              fieldName="password"
              errors={errors}
              control={control}
            />
          </div>
          <div className="mb-2 h-full py-2">
            <Field
              type="password"
              label="Confirm new password"
              fieldName="confirmPassword"
              errors={errors}
              control={control}
            />
          </div>

          {axiosErrors?.response?.status >= 300 && (
            <p className="text-[13px] text-red-700 mb-2">
              {axiosErrors?.response?.data?.message}
            </p>
          )}

          <CustomButton
            loading={loading}
            type="submit"
            className="custom-button"
            onClick={handleSubmit(handleResetPassword, onInvalid)}
          >
            Continue
          </CustomButton>
        </form>
      )}
      {resetEmailSent && (
        <p className="pt-2 text-[14px] ">Password Reset successfully.</p>
      )}
    </>
  );
};

export default ResetPassword;
