import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../context/authContext";
import {
  useCheckCurrentCarHost,
  useData,
  useIsMobile,
} from "../../Hooks/useData";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "../../components/elements/CustomButton";
import { formatDate } from "../car-rental/CarRentalBooking";
import { formatCurrency } from "../../utils/stringFunctions";
import DmmDialog from "../../components/modal/DmmDialog";
import Summary from "../../components/bookingSummary.tsx/Summary";
import OrderSummary from "../../components/bookingSummary.tsx/OrderSummary";
import { useUserStatus } from "../../context/userStatusContext";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ActivateBooking = () => {
  const navigate = useNavigate();
  const [sendQuoteModal, setSendQuoteModal] = useState(false);
  const [price, setPrice] = useState("");
  const [confirmQuoteModal, setConfirmQuoteModal] = useState(false);
  const [declineBookingModal, setDeclineBookingModal] = useState(false);
  const [isActivate, setIsActivate] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);
  const [code, setCode] = useState<String>(" ");
  const [declineReasonModal, setDeclineReasonModal] = useState(false);
  const [driver, setDriver] = useState<any>({});
  const location = useLocation();
  const data = location.state;
  const { state } = useAuth();
  const { userData } = useData();
  const role = userData?.role?.name;
  const [isActivatingPin, setIsActivatingPin] = useState(false);
  const [confirmSendPinLoading, setConfirmSendPinLoading] = useState(false);
  const [serviceTypeData, setServiceTypeData] = useState<any[]>([]);
  const [allServiceTypeData, setAllServiceTypeData] = useState<any[]>([]);
  const [carCity, setCarCity] = useState<any>("");
  const isMobile = useIsMobile();
  const { userStatus } = useUserStatus();

  const HandleConfirmquotation = () => {
    setConfirmQuoteModal(true);
    setSendQuoteModal(false);
  };
  const HandleConfirmquotationoff = () => {
    setConfirmQuoteModal(false);
  };

  const HandleDeclineBookingModal = () => {
    setDeclineBookingModal(true);
  };
  const HandleDeclineReason = () => {
    setDeclineReasonModal(true);
    setDeclineBookingModal(false);
  };

  const HandleActivateBooking = async () => {
    try {
      setIsActivatingPin(true);
      const response = await axios.put(
        `${BASE_URL}/booking/activateBooking/${data?._id}/${state.userId}/${code}`
      );

      if (!response.data?.isError === true) {
        setIsActivatingPin(false);

        setDeclineReasonModal(false);
        navigate("/profile/bookings");
      } else {
        toast.error("Invalid pin", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      }
    } catch (error) {
      setDeclineReasonModal(false);
      toast.error("Invalid Booking Pin Code", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    }
    setIsActivatingPin(false);
  };
  const HandleSendPin = async () => {
    setPinLoading(true);
    setConfirmSendPinLoading(true);
    try {
      const response = await axios.put(
        `${BASE_URL}/booking/generateUserPinCode/${state.userId}/${data?._id}`,
        {}
      );

      if (!response.data?.isError === true) {
        setConfirmSendPinLoading(false);
        setPinLoading(false);

        toast.success("Pin successful sent", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });

        setDeclineReasonModal(false);
      }
    } catch (error) {
      setDeclineReasonModal(false);
      setPinLoading(false);
    }
  };
  const HandleCancelbooking = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/booking/cancelBooking/${data?._id}`,
        {
          hostId: data?.hostId,
          userId: state.userId,
          cancellerId: state.userId,
        }
      );

      if (!response.data?.isError === true) {
        setPinLoading(false);

        toast.success("successfully cancelled", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });

        setDeclineReasonModal(false);
        navigate("/profile/bookings");
      }
    } catch (error) {
      setDeclineReasonModal(false);
    }
  };

  const { isCurrentCarHost } = useCheckCurrentCarHost({
    hostId: data?.hostId,
  });

  useEffect(() => {
    setCarCity(data?.data?.city);
    setServiceTypeData(data?.carId?.serviceTypes);

    const fetchDriver = async () => {
      const response = await axios.get(
        `${BASE_URL}/host/getDriverDetails/${data?.carId?._id}/${data?.hostId}`
      );
      setDriver(response.data.data);
    };

    const fetchServiceType = async () => {
      const response = await axios.get(`${BASE_URL}/general/getServiceTypes`);
      setAllServiceTypeData(response?.data?.data);
      // setLoading(false);
    };

    fetchDriver();
    fetchServiceType();
  }, []);

  return (
    <div>
      <ToastContainer />

      <Summary data={data} />

      <div className="mx-auto md:flex md:flex-row flex flex-col justify-center mt-10 w-full max-w-[1480px] lg:gap-20 lg:mb-10">
        <div className="w-full flex flex-col px-6 lg:px-10">
          <div className="flex items-center mb-3 gap-2 text-[20px] md:text-[25px]">
            <h2>Booking received for </h2>
            <span>{data?.carId?.carMakeID?.make}</span>
          </div>

          <p className="text-[15px] md:text-[18px] font-light mt-4 mb-4 py-3">
            Hi {data?.userId?.firstName} <span>{data?.userId?.lastName}</span>,
            you have a booking for{" "}
            <span className="font-medium">{data?.carId?.carMakeID?.make}</span>.
            <br></br>
            Please check below for details.
          </p>

          <hr className="mb-3 mt-1" />

          {/* details */}

          <div className=" flex flex-col justify-between">
            <div className=" flex justify-between mb-4">
              <div className=" flex w-full flex-col md:flex-row py-3 hover:border-orange-300 cursor-pointer md:space-x-16">
                <div className="flex flex-col md:block text-[14px] md:text-[16px]">
                  <div className="flex justify-between md:block items-center">
                    <p className="font-light">Booking date:</p>

                    <p>
                      {formatDate(data?.bookingStartDate)} -{" "}
                      {formatDate(data?.bookingEndDate)}
                    </p>
                  </div>

                  <div className="flex justify-between md:block items-center">
                    <p className="md:mt-4 font-light">Driver:</p>

                    <p>
                      {driver?.firstName} {driver?.lastName}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col md:block text-[14px] md:text-[16px]">
                  <div className="flex justify-between md:block items-center">
                    <p className="font-light">Service type:</p>

                    <p className="">
                      {
                        allServiceTypeData.find(
                          (val) => val?._id === data?.serviceTypeId
                        )?.name
                      }
                    </p>
                  </div>

                  <div className="flex justify-between md:block items-center">
                    <p className="md:mt-4 font-light">Status:</p>

                    <p className="">{data?.status}</p>
                  </div>
                </div>

                <div className="flex md:block text-[14px] md:text-[16px]">
                  <div className="flex justify-between md:block items-center w-full">
                    <p className="font-light">Amount offered:</p>

                    <p className="">{formatCurrency(data?.bookingPrice)}</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex flex-col md:flex-row justify-start 
             items-center w-full gap-4"
            >
              {role === "Host" && isCurrentCarHost && userStatus !== "you" && (
                <>
                  <button
                    type="button"
                    className="text-white bg-[#0e1427] border-gray-500 border rounded-full md:w-[30%] px-2 py-2 hover:shadow-md w-full"
                    onClick={() => {
                      setIsActivate(true);
                    }}
                  >
                    Activate
                  </button>
                  <CustomButton
                    loading={pinLoading}
                    type="submit"
                    className={`text-white ${
                      pinLoading ? "bg-[#0E1427]/50" : "bg-[#0E1427]"
                    } border-gray-500 border rounded-full w-full md:w-[30%] px-2 py-2 hover:shadow-md mb- flex justify-center items-center gap-4`}
                    onClick={HandleSendPin}
                    disabled={pinLoading}
                  >
                    Send Pin
                  </CustomButton>
                </>
              )}

              <button
                className="text-red-600 hover:border rounded-full px-5 p-2"
                onClick={HandleDeclineBookingModal}
              >
                Cancel Booking
              </button>
            </div>
          </div>
          <hr className="my-4" />
        </div>

        <OrderSummary data={data} />
      </div>

      <DmmDialog
        open={sendQuoteModal}
        onClose={() => {
          setSendQuoteModal(false);
        }}
        title="Input your price"
        fullScreen={isMobile}
      >
        <div className="">
          <div className=" flex flex-col w- gap-4 mt-1 mb-2">
            <input
              type="text"
              placeholder="N00,000"
              className=" border-2 mb-2 rounded-lg p-2"
              onChange={(e) => {
                setPrice(e.target.value);
              }}
            />
          </div>
          <hr />

          <div className="mt-3 mb-3">
            <div className="flex justify-between space-x-12">
              <p className="font-light">Pickup Location</p>
              <p className="font-semibold">Lagos</p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="font-light">Drop off Location</p>
              <p className="font-semibold">Abuja</p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="font-light">Commodity</p>
              <p className="font-semibold">Cement</p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="font-light">Capacity</p>
              <p className="font-semibold">20 tonnes</p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="font-light">Truck Type</p>
              <p className="font-semibold">Flatbed</p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="font-light">Haulage distance</p>
              <p className="font-semibold">15km</p>
            </div>
            <div className="flex justify-between mt-3 space-x-20">
              <p className="font-light">Estimated haulage time Location</p>
              <p className="font-semibold">2 hours</p>
            </div>
          </div>

          <hr />
          <div className="flex flex-col justify-center">
            <button
              type="button"
              className={`text-white ${
                !(price.length > 0) ? "bg-gray-600" : "bg-indigo-950"
              } border-gray-500 border w-full rounded-full px-10 py-1 hover:shadow-md mt-10`}
              onClick={HandleConfirmquotation}
              disabled={!(price.length > 0)}
            >
              Confirm and send
            </button>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={confirmQuoteModal}
        onClose={() => {
          setConfirmQuoteModal(false);
        }}
        title="You're about to send"
        fullScreen={isMobile}
      >
        <div className="">
          <h1 className="text-base font-light"></h1>
          <p className="text-2xl font-bold mb-4">N00,000</p>
          <hr />

          <div className="mt-3 mb-3">
            <div className="flex justify-between space-x-12">
              <p className="font-light">Pickup Location</p>
              <p className="font-semibold">Lagos</p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="font-light">Drop off Location</p>
              <p className="font-semibold">Abuja</p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="font-light">Commodity</p>
              <p className="font-semibold">Cement</p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="font-light">Capacity</p>
              <p className="font-semibold">20 tonnes</p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="font-light">Truck Type</p>
              <p className="font-semibold">Flatbed</p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="font-light">Haulage distance</p>
              <p className="font-semibold">15km</p>
            </div>
            <div className="flex justify-between mt-3 space-x-20">
              <p className="font-light">Estimated haulage time Location</p>
              <p className="font-semibold">2 hours</p>
            </div>
          </div>

          <hr />
          <div className="flex flex-col justify-center">
            <button
              type="button"
              className="text-white w-full bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md mt-10"
              onClick={HandleConfirmquotationoff}
            >
              Confirm and send
            </button>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={declineBookingModal}
        onClose={() => {
          setDeclineBookingModal(false);
        }}
        title="Please confirm!"
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mb-2"></h1>
          <p className="text-sm">
            Are you sure you want to decline this booking?
          </p>
          <div className="flex justify-center items-center space-x-16 mt-5 mb-3">
            <button
              type="button"
              className="text-white w-full bg-indigo-950 border-gray-500 border rounded-full px-10 py-2 hover:shadow-md"
              onClick={HandleDeclineReason}
            >
              Yes
            </button>

            <button
              type="button"
              className="text-indigo-950 w-full bg-white-700 border-indigo-900 border rounded-full px-10 py-2 hover:shadow-md"
              onClick={() => {
                setDeclineBookingModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={isActivate}
        onClose={() => {
          setIsActivate(false);
        }}
        title="Activate Booking"
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-base mb-2">Enter Pin</h1>
          <input
            type="number"
            className=" border-1 border rounded-lg p-2"
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />

          <div className="flex space-x-6 mt-7 mb-3 w-full justify-center">
            <CustomButton
              type="button"
              loading={isActivatingPin}
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-5 py-2 hover:shadow-md w-full"
              onClick={() => {
                HandleActivateBooking();
              }}
            >
              Continue
            </CustomButton>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={declineReasonModal}
        onClose={() => {
          setDeclineReasonModal(false);
        }}
        title="Continue to decline"
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mb-2"></h1>
          {/* <h1 className="text-lg font-semibold mb-2">Decline reason</h1>
          <p className="text-sm mb-4">
            What is your reason for declining this booking?
          </p>
          <div className="mb-4 border border-gray-300 rounded-3xl">
            <textarea
              //   type="textarea"
              name="textarea"
              title="textarea"
              placeholder=""
              onChange={handleTextareaChange}
              cols={50}
              rows={4}
            />
          </div> */}
          <div className="flex space-x-6 mt-7 mb-3">
            <button
              type="button"
              className="text-white text-sm bg-indigo-950 border-gray-500 border rounded-full px-3 py-2 hover:shadow-md w-full"
              onClick={() => {
                HandleCancelbooking();
              }}
            >
              {/* Post and go home */}
              Continue
            </button>
            <button
              type="button"
              className="text-indigo-950 text-sm bg-white-700 border-indigo-900 border rounded-full px-3 py-2 hover:shadow-md w-full"
              onClick={() => {
                setDeclineReasonModal(false);
              }}
            >
              Cancel and go back
            </button>
          </div>
        </div>
      </DmmDialog>
    </div>
  );
};

export default ActivateBooking;
