import React, { useEffect, useState } from "react";
import CarDetails from "./CarDetails";
import CarDocuments from "./CarDocuments";
import ServicesPricing from "./ServicesPricing";
import DriversDetails from "./DriversDetails";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DmmSpinner } from "../../components/Spinner";
import { REACT_TOAST_CONFIG } from "../../utils/utilsFunctions";
import { motion } from "framer-motion";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { EditFormDataSchema } from "../../validations/formDataSchema";
import ListingButton from "../../components/listingProcess.tsx/ListingButton";
import {
  extractListingInformation,
  extractUpdateInformation,
} from "../../utils/extractInformation";
import { useData, useMediaQuery } from "../../Hooks/useData";
import _ from "lodash";
import { compareObjects } from "../../utils/compareObjects";
import { data } from "../../utils/formPages";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export type Inputs = z.infer<typeof EditFormDataSchema>;

const EditListVehicle = () => {
  const [previousStep, setPreviousStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const delta = currentStep - previousStep;
  const [isLoading, setIsLoading] = useState(false);
  const [prevData, setPrevData] = useState<{ car: any; driver: any }>({
    car: {},
    driver: {},
  });
  const [updateErrors, setUpdateErrors] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const carDetails = useLocation().state;
  const { userData } = useData();
  const navigate = useNavigate();
  const isMdUp = useMediaQuery("(min-width: 768px)");

  const fields = [
    "Car details",
    "Car documents",
    "Service & pricing",
    "Drivers details",
  ];

  const editData = fields.map((field) =>
    data.find((curr) => curr.title === field)
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    trigger,

    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(EditFormDataSchema),
    mode: "onBlur",
  });

  useEffect(() => {
    const getDetails = async () => {
      try {
        if (carDetails && userData?.userId) {
          const result = await axios.get(
            `${BASE_URL}/host/getFormDetailsForUpdate/${carDetails._id}/${userData?.userId}`,

            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (result.status >= 200 && result.status < 300) {
            setIsLoading(false);
            const data = result?.data?.data;

            setPrevData(data);

            const formattedData = await extractListingInformation(data);

            Object.keys(formattedData).forEach((key: any) => {
              setValue(key, formattedData[key]);
            });
          } else {
            setIsLoading(false);

            toast.error(`${result?.data?.message}`, REACT_TOAST_CONFIG);

            console.error("Error:", result.statusText);
          }
        }
      } catch (err) {
        setIsLoading(false);

        toast.error(
          `${(err as any).response?.data?.message || "An error occurred"}`,
          REACT_TOAST_CONFIG
        );

        console.log("error", err);
      }
    };

    getDetails();
  }, [carDetails, setValue, userData?.userId]);

  const onInvalid = (errors: any) => console.error(errors);

  const processForm: SubmitHandler<Inputs> = async (data: any) => {
    const { car, driver: driverData } = extractUpdateInformation(data);
    const formattedData = {
      car: {
        ...car,
        _id: prevData.car?._id,
      },
      driver: {
        ...driverData,
        hostID: prevData.driver?.hostID,
        carID: prevData.driver?.carID,
      },
    };

    console.log("Formatted Data:", formattedData);

    const isEqual = _.isEqual(formattedData, prevData);

    if (!isEqual) {
      const diff = compareObjects(prevData, formattedData);

      console.log("Difference:", diff);

      try {
        setIsLoading(true);

        const result = await axios.patch(
          `${BASE_URL}/host/updateListingDetails/${carDetails._id}/${userData?.userId}`,
          diff,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (result.status >= 200 && result.status < 300) {
          setIsLoading(false);

          // toast.success(`${result?.data?.message}`, REACT_TOAST_CONFIG);

          navigate(`/profile/listings/${carDetails?.carCode}`);
        } else {
          setIsLoading(false);

          toast.error(`${result?.data?.message}`, REACT_TOAST_CONFIG);

          console.error("Error:", result.statusText);
        }
      } catch (err) {
        setIsLoading(false);

        toast.error(
          `${(err as any).response?.data?.message || "An error occurred"}`,
          REACT_TOAST_CONFIG
        );

        console.log("error", err);
      }
    } else {
      setUpdateErrors(
        "It seems you have not made any changes, kindly make a change or close the edit form"
      );
    }
  };

  type FieldName = keyof Inputs;

  const next = async () => {
    console.log(getValues());

    const fields = editData?.[currentStep]?.fields;
    const output = await trigger(fields as FieldName[], { shouldFocus: true });

    if (!output) return;

    setPreviousStep(currentStep);
    setCurrentStep((step) => step + 1);
  };

  const prev = () => {
    if (currentStep > 0) {
      setPreviousStep(currentStep);
      setCurrentStep((step) => step - 1);
    }
  };

  const skip = () => {
    if (currentStep < data.length - 1) {
      setPreviousStep(currentStep);
      setCurrentStep((step) => step + 1);
    }
  };

  const goTo = (step: React.SetStateAction<number>) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen pb-8 gap-2 items-center w-full ">
      {/* ............top bar............... */}
      <div className=" bg-[#F1F1F2] flex py-4 justify-evenly w-full mb-10">
        <div className=" w-full rounded-full flex items-center justify-between max-w-[1140px] mx-auto px-6 py-2">
          {editData?.map((item, i) => (
            <div
              className="flex flex-col items-center"
              // onClick={() => goTo(i)}
              key={i}
            >
              <p
                className={`${
                  currentStep === i ? "text-[#0E1427]" : "text-[#B7B7B7]"
                } border-2 font-medium border-[#C8C9CC] rounded-full w-[25px] h-[25px] md:w-[45px] md:h-[45px] bg-white flex justify-center items-center text-[12px] md:text-[18px]`}
              >
                {i + 1}
              </p>

              <p
                className={`${
                  currentStep === i
                    ? "font-medium text-black"
                    : "font-light text-[#B7B7B7]"
                }  text-[11px] md:text-[15px] pt-2`}
              >
                {windowWidth <= 768
                  ? item?.title.split(" ")[0]
                  : windowWidth > 768
                  ? item?.title
                  : ""}
              </p>
            </div>
          ))}
        </div>
      </div>

      <form
        onSubmit={handleSubmit(processForm, onInvalid)}
        className="w-full flex justify-center items-center max-w-[1140px] mx-auto"
      >
        {currentStep === 0 && (
          <motion.div
            initial={
              isMdUp ? { x: delta >= 0 ? "50%" : "-50%", opacity: 0 } : {}
            }
            animate={isMdUp ? { x: 0, opacity: 1 } : {}}
            transition={isMdUp ? { duration: 0.3, ease: "easeInOut" } : {}}
            className="w-full"
          >
            <CarDetails
              register={register}
              errors={errors}
              control={control}
              watch={watch}
              setValue={setValue}
            />
          </motion.div>
        )}

        {currentStep === 1 && (
          <motion.div
            initial={
              isMdUp ? { x: delta >= 0 ? "50%" : "-50%", opacity: 0 } : {}
            }
            animate={isMdUp ? { x: 0, opacity: 1 } : {}}
            transition={isMdUp ? { duration: 0.3, ease: "easeInOut" } : {}}
            className="w-full"
          >
            <CarDocuments
              errors={errors}
              control={control}
              watch={watch}
              register={register}
            />
          </motion.div>
        )}

        {currentStep === 2 && (
          <motion.div
            initial={
              isMdUp ? { x: delta >= 0 ? "50%" : "-50%", opacity: 0 } : {}
            }
            animate={isMdUp ? { x: 0, opacity: 1 } : {}}
            transition={isMdUp ? { duration: 0.3, ease: "easeInOut" } : {}}
            className="w-full"
          >
            <ServicesPricing
              errors={errors}
              control={control}
              watch={watch}
              setValue={setValue}
            />
          </motion.div>
        )}

        {currentStep === 3 && (
          <motion.div
            initial={
              isMdUp ? { x: delta >= 0 ? "50%" : "-50%", opacity: 0 } : {}
            }
            animate={isMdUp ? { x: 0, opacity: 1 } : {}}
            transition={isMdUp ? { duration: 0.3, ease: "easeInOut" } : {}}
            className="w-full"
          >
            <DriversDetails
              errors={errors}
              control={control}
              watch={watch}
              register={register}
            />
          </motion.div>
        )}
      </form>

      <div className="flex justify-start flex-col w-full mt-10 gap-4 max-w-[1140px] px-6 lg:px-0">
        {!!updateErrors && (
          <p className="text-[12px] mt-1 text-red-700 px-3 text-left w-full">
            {updateErrors}
          </p>
        )}

        <div className="w-full justify-start items-center gap-4 md:gap-8  flex flex-col md:flex-row ">
          {currentStep > 0 && <ListingButton name="Back" handleClick={prev} />}

          {currentStep < fields.length - 1 && (
            <ListingButton name="Next" handleClick={next} />
          )}

          {currentStep === 3 && (
            <ListingButton name="Skip and List " handleClick={skip} skip />
          )}

          {currentStep === fields.length - 1 && (
            <ListingButton
              name="Update Asset"
              type="submit"
              handleClick={handleSubmit(processForm, onInvalid)}
              disabled={isLoading}
            >
              {isLoading && <DmmSpinner size={25} />}
            </ListingButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditListVehicle;
