import React, { useState } from "react";
import DmmDialog from "./DmmDialog";
import CustomButton from "../elements/CustomButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { REACT_TOAST_CONFIG } from "../../utils/utilsFunctions";
import { ForgotPasswordFormSchema } from "../../validations/formDataSchema";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Field from "../inputs/Field";
import ResetPassword from "../signup/ResetPassword";
import { useIsMobile } from "../../Hooks/useData";

const BASE_URL = process.env.REACT_APP_BASE_URL;

type Inputs = z.infer<typeof ForgotPasswordFormSchema>;

interface ForgotPasswordDialogProps {
  onClose: () => void;
  openForgotPassword: boolean;
  setOpenForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = ({
  onClose,
  openForgotPassword,
  setOpenForgotPassword,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(ForgotPasswordFormSchema),
    mode: "onBlur",
  });

  const onInvalid = (errors: any) => console.error(errors);

  const [loading, setLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [axiosErrors, setAxiosErrors] = useState<any>(null);
  const isMobile = useIsMobile();

  const handleForgotPasswordClose = () => {
    setOpenForgotPassword(false);
  };

  const handleForgotPassword: SubmitHandler<Inputs> = async (data: any) => {
    setLoading(true);

    const email = data["email"];

    try {
      const { data } = await axios.post(
        `${BASE_URL}/account/forgetPasswordEmail/${email}`
      );

      if (!data?.isError) {
        setLoading(false);
        setResetEmailSent(true);
        // setOpenForgotPassword(false);
        // onClose();
        reset();
        toast.success(data?.message, REACT_TOAST_CONFIG);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setAxiosErrors(error);
    }
  };

  return (
    <DmmDialog
      title="Forgot Password"
      onClose={handleForgotPasswordClose}
      open={openForgotPassword}
      fullScreen={isMobile}
    >
      <>
        {!resetEmailSent && (
          <form
            onSubmit={handleSubmit(handleForgotPassword, onInvalid)}
            className="h-full"
          >
            <div className="mb-2 h-full py-2">
              <Field
                type="email"
                label="Enter your email"
                fieldName="email"
                errors={errors}
                control={control}
              />
            </div>

            {axiosErrors?.response?.status >= 300 && (
              <p className="text-[13px] text-red-700 mb-2">
                {axiosErrors?.response?.data?.message}
              </p>
            )}

            <CustomButton
              loading={loading}
              type="submit"
              className="custom-button"
              onClick={handleSubmit(handleForgotPassword, onInvalid)}
            >
              Continue
            </CustomButton>
          </form>
        )}
        {resetEmailSent && <ResetPassword onClose={onClose} />}
      </>
    </DmmDialog>
  );
};

export default ForgotPasswordDialog;
