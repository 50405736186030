import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import SideMenu from "../SideMenu/SideMenu";
import { useAuth } from "../../context/authContext";
import axios from "axios";
import { useData } from "../../Hooks/useData";
import Logo from "./comps/Logo";
import LoginSignUp from "./comps/LoginSignUp";
import MenuIconButton from "./comps/MenuIconButton";
import SignupDialog from "../modal/SignupDialog";
import LoginDialog from "../modal/LoginDialog";
import { useDataContext } from "../../providers/DataProvider";
import DmmDialog from "../modal/DmmDialog";
import { SupportContent } from "./comps/SupportButton";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Navbar = () => {
  const [isLoginDialogOpen, setLoginDialogOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSideModalOpen, setSideModalOpen] = useState(false);
  const [isNotifications, setIsNotifications] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const { state, dispatch } = useAuth();
  const { userData } = useData();
  const navigate = useNavigate();
  const { setActiveTab } = useDataContext();

  useEffect(() => {
    if (state?.isLoggedInToggle) {
      setLoginDialogOpen(true);
      // dispatch({
      //   type: "TOGGLELOGIN",
      // });
    }
  }, [state?.isLoggedInToggle]);

  useEffect(() => {
    if (state?.isSignUpToggle) {
      setDialogOpen(true);
      // dispatch({
      //   type: "TOGGLELOGIN",
      // });
    }
  }, [state?.isSignUpToggle]);

  const fetchNotifications = async () => {
    try {
      if (userData?.userId) {
        const { data } = await axios.get(
          `${BASE_URL}/notification/getNotificationByUserId/${userData?.userId}`
        );
        setIsNotifications(
          data.data.filter((val: any) => val?.status === "UNREAD").length > 0
        );
      }
    } catch (error) {
      console.log("error ", error);
    }
  };
  useEffect(() => {
    fetchNotifications();
  }, [userData]);

  const Linksets = [
    {
      id: 1,
      link: "/profile/wallet",
      title: "Wallet",
    },
    {
      id: 2,
      link: "/profile/listings",
      title: "Listings",
    },
    {
      id: 3,
      link: "profile/bookings",
      title: "Bookings",
    },
    {
      id: 4,
      link: "/notifications",
      title: "Notifications",
    },
  ];
  // const handleNavigate = () => {
  //   navigate("/profile/listings");
  //   console.log("boy");
  // };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenSideModal = () => {
    setSideModalOpen(true);
  };

  const handleCloseSideModal = () => {
    setSideModalOpen(false);
  };
  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
    });

    navigate("/");
  };

  let isOpenLoginDialog: string | null =
    localStorage.getItem("openLoginDialog");

  return (
    <header className="relative top-0 z-9999 h-[80px]">
      <nav className="bg-white text-[#0e1427] w-full z-20 top-0 left-0 h-full flex justify-center items-center">
        <div className="max-w-[1480px] w-full flex items-center justify-between mx-auto flex-1 px-2">
          <Logo />

          {/* <ServiceButtons navigate={navigate} /> */}

          <LoginSignUp
            state={state}
            isNotifications={isNotifications}
            fetchNotifications={fetchNotifications}
            navigate={navigate}
            handleLogout={handleLogout}
            setLoginDialogOpen={setLoginDialogOpen}
            openDialog={openDialog}
          />

          <MenuIconButton handleOpenSideModal={handleOpenSideModal} />
        </div>

        <SideMenu isOpen={isSideModalOpen} onClose={handleCloseSideModal}>
          <div className="flex flex-col h-full">
            <div className="flex flex-col px-10 flex-1 h-full">
              <h2 className="text-[20px] text-black my-6 font-light">Menu</h2>

              <hr className="mb-2" />

              <div className="flex-1 flex flex-col gap-4 h-[30%] pt-4 font-light">
                {state.userId &&
                  Linksets.map((item) =>
                    item.title === "Listings" &&
                    userData?.role?.name !== "Host" ? null : (
                      <div
                        key={item.title}
                        className="z-50 cursor-pointer w-[100%] hover:bg-gray-200 text-[17px] rounded-md py-1"
                        onClick={() => {
                          navigate(`${item.link}`);
                          setActiveTab(item.title);
                          handleCloseSideModal();
                        }}
                      >
                        {item.title}
                      </div>
                    )
                  )}

                {!state.userId && (
                  <div className="flex flex-col gap-4">
                    <div
                      className="z-50 cursor-pointer w-[100%] hover:bg-gray-200 text-[17px] rounded-md py-1"
                      onClick={() => {
                        dispatch({
                          type: "TOGGLELOGIN",
                        });
                      }}
                    >
                      Login
                    </div>
                    <div
                      className="z-50 cursor-pointer w-[100%] hover:bg-gray-200 text-[17px] rounded-md py-1"
                      onClick={() => {
                        dispatch({
                          type: "TOGGLESIGNUP",
                        });
                      }}
                    >
                      Sign Up
                    </div>
                  </div>
                )}

                <div className="flex flex-col gap-3">
                  <div
                    className="z-50 cursor-pointer w-[100%] hover:bg-gray-200 text-[17px] rounded-md py-1"
                    onClick={() => {
                      navigate("/car/list-vehicle", { state: 1 });
                    }}
                  >
                    I have a car
                  </div>

                  <div
                    className="z-50 cursor-pointer w-[100%] hover:bg-gray-200 text-[17px] rounded-md py-1"
                    onClick={() => {
                      setIsSupportOpen(true);
                    }}
                  >
                    Support
                  </div>
                </div>
              </div>
              <hr className="mt-5" />
            </div>

            <div className="py-4 flex justify-center items-center space-x-4 ">
              <div className="flex gap-3">
                {state.userId && (
                  <div
                    className="z-50 cursor-pointer w-[100%] hover:bg-gray-200 text-[17px] rounded-md py-2"
                    onClick={handleLogout}
                  >
                    Log out
                  </div>
                )}
              </div>
            </div>
          </div>
        </SideMenu>

        <SignupDialog
          setLoginDialog={() => setLoginDialogOpen(true)}
          isOpen={isDialogOpen}
          onClose={() => {
            closeDialog();
            dispatch({
              type: "TOGGLESIGNUPOFF",
            });
          }}
        />

        <LoginDialog
          setSignUpDialog={() => setDialogOpen(true)}
          isOpen={isOpenLoginDialog === "true" || isLoginDialogOpen}
          onClose={() => {
            dispatch({
              type: "TOGGLELOGINOFF",
            });
            setLoginDialogOpen(false);
            localStorage.setItem("openLoginModal", " ");
          }}
        />

        <DmmDialog
          title="Support"
          open={isSupportOpen}
          onClose={() => {
            setIsSupportOpen(false);
          }}
          fullScreen
        >
          <SupportContent />
        </DmmDialog>
      </nav>
    </header>
  );
};

export default Navbar;
