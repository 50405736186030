import React, { useEffect, useState } from "react";
import HostDetails from "./HostDetails";
import CarDetails from "./CarDetails";
import CarDocuments from "./CarDocuments";
import { useAuth } from "../../context/authContext";
import ServicesPricing from "./ServicesPricing";
import DriversDetails from "./DriversDetails";
import BankDetails from "./BankDetails";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Declaration from "./Declaration";
import { DmmSpinner } from "../../components/Spinner";
import { REACT_TOAST_CONFIG } from "../../utils/utilsFunctions";
import SignupDialog from "../../components/modal/SignupDialog";
import LoginDialog from "../../components/modal/LoginDialog";
import { motion } from "framer-motion";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { FormDataSchema } from "../../validations/formDataSchema";
import ListingButton from "../../components/listingProcess.tsx/ListingButton";
import { extractInformation } from "../../utils/extractInformation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { data } from "../../utils/formPages";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export type Inputs = z.infer<typeof FormDataSchema>;

const ListVehicle = () => {
  const [previousStep, setPreviousStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const delta = currentStep - previousStep;
  const { state, dispatch } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoginDialogOpen, setLoginDialogOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const isMdUp = useMediaQuery("(min-width: 768px)");

  const closeDialog = () => {
    setDialogOpen(false);
  };
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(FormDataSchema),
    mode: "onBlur",
  });

  const onInvalid = (errors: any) => console.error(errors);

  const processForm: SubmitHandler<Inputs> = async (data: any) => {
    const formattedData = extractInformation(data);
    console.log("Formatted Data:", formattedData);

    setIsLoading(true);

    try {
      const result = await axios.post(
        `${BASE_URL}/host/createHost`,
        formattedData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status >= 200 && result.status < 300) {
        setIsLoading(false);

        navigate("/car/host-listing-completed", { state: result.data });

        reset();
      } else {
        setIsLoading(false);

        toast.error(`${result?.data?.message}`, REACT_TOAST_CONFIG);

        console.error("Error:", result.statusText);
      }
    } catch (err) {
      setIsLoading(false);

      toast.error(
        `${(err as any).response?.data?.message || "An error occurred"}`,
        REACT_TOAST_CONFIG
      );

      console.log("error", err);
    }
  };

  type FieldName = keyof Inputs;

  const next = async () => {
    console.log(getValues());

    const fields = data[currentStep].fields;
    const output = await trigger(fields as FieldName[], { shouldFocus: true });

    if (!output) return;

    setPreviousStep(currentStep);
    setCurrentStep((step) => step + 1);
  };

  const prev = () => {
    if (currentStep > 0) {
      setPreviousStep(currentStep);
      setCurrentStep((step) => step - 1);
    }
  };

  const skip = () => {
    if (currentStep < data.length - 1) {
      setPreviousStep(currentStep);
      setCurrentStep((step) => step + 1);
    }
  };

  const goTo = (step: React.SetStateAction<number>) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen pb-8 gap-2 items-center w-full">
      {/* ............top bar............... */}
      <div className=" bg-[#F1F1F2] flex py-4 justify-evenly w-full mb-10">
        <div className=" w-full rounded-full flex items-center justify-between max-w-[1140px] mx-auto px-6 py-2">
          {data?.map((item, i) => (
            <div
              className="flex flex-col items-center"
              // onClick={() => goTo(i)}
              key={i}
            >
              <p
                className={`${
                  currentStep === i ? "text-[#0E1427]" : "text-[#B7B7B7]"
                } border-2 font-medium border-[#C8C9CC] rounded-full w-[25px] h-[25px] md:w-[45px] md:h-[45px] bg-white flex justify-center items-center text-[12px] md:text-[18px]`}
              >
                {i + 1}
              </p>

              <p
                className={`${
                  currentStep === i
                    ? "font-medium text-black"
                    : "font-light text-[#B7B7B7]"
                }  text-[15px] pt-2 hidden md:block`}
              >
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>

      <form
        onSubmit={handleSubmit(processForm, onInvalid)}
        className="w-full max-w-[1140px] mx-auto flex justify-center items-center"
      >
        {currentStep === 0 && (
          <motion.div
            initial={
              isMdUp ? { x: delta >= 0 ? "50%" : "-50%", opacity: 0 } : {}
            }
            animate={isMdUp ? { x: 0, opacity: 1 } : {}}
            transition={isMdUp ? { duration: 0.3, ease: "easeInOut" } : {}}
            className="w-full"
          >
            <HostDetails
              register={register}
              errors={errors}
              watch={watch}
              control={control}
              setValue={setValue}
            />
          </motion.div>
        )}

        {currentStep === 1 && (
          <motion.div
            initial={
              isMdUp ? { x: delta >= 0 ? "50%" : "-50%", opacity: 0 } : {}
            }
            animate={isMdUp ? { x: 0, opacity: 1 } : {}}
            transition={isMdUp ? { duration: 0.3, ease: "easeInOut" } : {}}
            className="w-full"
          >
            <CarDetails
              register={register}
              errors={errors}
              control={control}
              watch={watch}
              setValue={setValue}
            />
          </motion.div>
        )}

        {currentStep === 2 && (
          <motion.div
            initial={
              isMdUp ? { x: delta >= 0 ? "50%" : "-50%", opacity: 0 } : {}
            }
            animate={isMdUp ? { x: 0, opacity: 1 } : {}}
            transition={isMdUp ? { duration: 0.3, ease: "easeInOut" } : {}}
            className="w-full"
          >
            <CarDocuments
              errors={errors}
              control={control}
              watch={watch}
              register={register}
            />
          </motion.div>
        )}

        {currentStep === 3 && (
          <motion.div
            initial={
              isMdUp ? { x: delta >= 0 ? "50%" : "-50%", opacity: 0 } : {}
            }
            animate={isMdUp ? { x: 0, opacity: 1 } : {}}
            transition={isMdUp ? { duration: 0.3, ease: "easeInOut" } : {}}
            className="w-full"
          >
            <ServicesPricing
              errors={errors}
              control={control}
              watch={watch}
              setValue={setValue}
            />
          </motion.div>
        )}

        {currentStep === 4 && (
          <motion.div
            initial={
              isMdUp ? { x: delta >= 0 ? "50%" : "-50%", opacity: 0 } : {}
            }
            animate={isMdUp ? { x: 0, opacity: 1 } : {}}
            transition={isMdUp ? { duration: 0.3, ease: "easeInOut" } : {}}
            className="w-full"
          >
            <DriversDetails
              errors={errors}
              control={control}
              watch={watch}
              register={register}
            />
          </motion.div>
        )}

        {currentStep === 5 && (
          <motion.div
            initial={
              isMdUp ? { x: delta >= 0 ? "50%" : "-50%", opacity: 0 } : {}
            }
            animate={isMdUp ? { x: 0, opacity: 1 } : {}}
            transition={isMdUp ? { duration: 0.3, ease: "easeInOut" } : {}}
            className="w-full"
          >
            <BankDetails
              errors={errors}
              control={control}
              watch={watch}
              setValue={setValue}
            />
          </motion.div>
        )}

        {currentStep === 6 && (
          <motion.div
            initial={
              isMdUp ? { x: delta >= 0 ? "50%" : "-50%", opacity: 0 } : {}
            }
            animate={isMdUp ? { x: 0, opacity: 1 } : {}}
            transition={isMdUp ? { duration: 0.3, ease: "easeInOut" } : {}}
            className="w-full"
          >
            <Declaration
              control={control}
              errors={errors}
              register={register}
              watch={watch}
            />
          </motion.div>
        )}
      </form>

      <div className="w-full justify-start items-center gap-4 md:gap-8 px-6 lg:px-0 flex flex-col md:flex-row max-w-[1140px] mt-10">
        {currentStep > 0 && <ListingButton name="Back" handleClick={prev} />}

        {currentStep < data.length - 1 && (
          <ListingButton name="Next" handleClick={next} />
        )}

        {currentStep === 4 && (
          <ListingButton name="Skip" handleClick={skip} skip />
        )}
        {currentStep === data.length - 1 && (
          <ListingButton
            name="List asset"
            type="submit"
            handleClick={handleSubmit(processForm, onInvalid)}
            disabled={isLoading}
          >
            {isLoading && <DmmSpinner size={25} />}
          </ListingButton>
        )}
      </div>

      <SignupDialog
        setLoginDialog={() => setLoginDialogOpen(true)}
        isOpen={isDialogOpen}
        hideCloseButton
        onClose={() => {
          closeDialog();
          dispatch({
            type: "TOGGLESIGNUPOFF",
          });
        }}
      />
      <LoginDialog
        setSignUpDialog={() => setDialogOpen(true)}
        isOpen={!state.userId}
        hideCloseButton
        onClose={() => {
          dispatch({
            type: "TOGGLELOGINOFF",
          });
          setLoginDialogOpen(false);
          localStorage.setItem("openLoginModal", " ");
        }}
      />
    </div>
  );
};

export default ListVehicle;
