import React, { useRef, useState } from "react";
import { Button } from "@mui/material";
import { useFieldArray, Controller, Control } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FaRegFileLines } from "react-icons/fa6";
import { MdDeleteForever } from "react-icons/md";
import axios from "axios";

const CLOUD_NAME = process.env.REACT_APP_CLOUD_NAME as string;
const UPLOAD_PRESET = process.env.REACT_APP_UPLOAD_PRESET as string;

interface HiddenInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const HiddenInput = React.forwardRef<HTMLInputElement, HiddenInputProps>(
  (props, ref) => <input {...props} ref={ref} style={{ display: "none" }} />
);

interface FileUploadProps {
  control: Control<any>;
  name: string;
  errors: any;
  watch: any;
  label: string;
  acceptLabel?: string;
  accept?: string;
  multiple?: boolean;
  message?: string;
  errMessage?: string;
  fileSizeLimit?: number;
}

const FileUpload: React.FC<FileUploadProps> = ({
  control,
  name,
  errors,
  watch,
  label,
  acceptLabel = "Image, PDF",
  accept = "image/*, application/PDF",
  multiple = false,
  message,
  errMessage,
  fileSizeLimit = 5000 * 1024,
}) => {
  const errorMessage = errMessage || errors?.[name]?.message;

  const [fileErrors, setFileErrors] = useState<string[]>([]);

  const { append, remove, replace } = useFieldArray({
    control,
    name,
  });

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const onAddDocuments = () => {
    hiddenFileInput.current?.click();
  };

  const handleAddDocuments = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const uploadedFiles = Array.from(event.target.files || []);
    const validFiles: File[] = [];
    const errors: string[] = [];

    uploadedFiles.forEach((file) => {
      // Check file size
      if (file.size > fileSizeLimit) {
        errors.push(
          `${file.name} is too large (${(file.size / (1024 * 1024)).toFixed(
            2
          )}MB). Max file size: ${fileSizeLimit / 1000 / 1024}MB`
        );
      } else {
        validFiles.push(file);
      }
    });

    setFileErrors(errors);

    if (!!!validFiles.length) return;

    if (multiple) {
      handleMultipleFilesUpload(validFiles);
    } else {
      handleSingleFileUpload(validFiles[0]);
    }

    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
  };

  const handleMultipleFilesUpload = async (newFiles: File[]) => {
    try {
      const updatedURLs = await Promise.all(
        newFiles.map((file) => handleUpload(file))
      );
      append(updatedURLs.map((url) => url));
    } catch (error) {
      console.log("Upload error --> ", error);
    }
  };

  const handleSingleFileUpload = async (file: File) => {
    try {
      const fileURL = await handleUpload(file);

      replace([fileURL]);
    } catch (error) {
      console.log("Upload error --> ", error);
    }
  };

  const handleUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", UPLOAD_PRESET);

      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/upload`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const fileUrl = response.data.secure_url;

      console.log("URL:", fileUrl);

      return fileUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const fieldFiles = watch(name, []);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div className="w-full">
          <HiddenInput
            ref={hiddenFileInput}
            type="file"
            accept={accept}
            multiple={multiple}
            onChange={async (e) => await handleAddDocuments(e)}
          />

          <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            sx={{
              width: "100%",
              borderRadius: "8px",
              color: "#000000",
              borderColor: !!errorMessage ? "red" : "#BABABA",
              minHeight: "55px",
              textTransform: "none",
            }}
            endIcon={
              <KeyboardArrowDownIcon
                sx={{
                  background: "#000000",
                  color: "white",
                  fontSize: 100,
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                }}
              />
            }
            onClick={onAddDocuments}
          >
            <div className="flex justify-between items-center w-full">
              <p
                className={`font-normal text-[16px] ${
                  !!errors?.[name]?.message ? "text-red-700" : "text-black/60"
                }`}
              >
                {label}
                <span className="ml-1 font-light">({acceptLabel})</span>
              </p>
            </div>
          </Button>

          {!!message && <p className="text-[13px] mt-1 px-3">{message}</p>}

          {!!fieldFiles.length && (
            <div className="w-full flex flex-col gap-2 mt-1">
              {fieldFiles.map((item: any, index: number) => (
                <div key={index} className="w-full px-2  mt-1">
                  {item && (
                    <div className="flex justify-between items-center w-full gap-4">
                      <a
                        className="text-[11px] md:text-[14px] flex justify-start items-center gap-4"
                        href={item || ""}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FaRegFileLines className="text-[14px] md:text-[18px]" />

                        <span className="flex-1">
                          {item?.fileName || item?.split("/").pop()}
                        </span>
                      </a>

                      <div>
                        <MdDeleteForever
                          className="text-[20px] md:text-[25px] cursor-pointer"
                          onClick={() => remove(index)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {!!errorMessage && (
            <p className="text-[12px] mt-1 text-red-700 px-3">{errorMessage}</p>
          )}

          {!!fileErrors.length &&
            fileErrors.map((error, idx) => (
              <p key={idx} className="text-[12px] mt-1 text-red-700 px-3">
                {error}
              </p>
            ))}
        </div>
      )}
    />
  );
};

export default FileUpload;
