import React from "react";
import { formatCurrency } from "../../utils/stringFunctions";

export interface Car {
  _id: string;
  carImages: string[];
  carMakeID: { make: string; mode: string };
  carCode: string;
  carType: string;
  serviceTypes: { price: number }[];
  status: string;
  negotiable: boolean;
}

interface ListingsCarCardProps {
  car: Car;
  selectedCarId: string;
  navigate: (path: string, options?: { state: Car }) => void;
  handleStatusChange: (id: string) => void;
  handleSelectCarId: (id: any) => void;
}

const statusClasses: Record<string, string> = {
  PENDING: "text-red-600 bg-gray-300",
  Suspended: "text-[#a16207] bg-orange-50",
  CANCELED: "text-red-600 bg-red-200",
  ACTIVE: "text-green-600 bg-green-50",
  DEFAULT: "bg-[#fff]",
};

const ListingsCarCard: React.FC<ListingsCarCardProps> = ({
  car,
  navigate,
  selectedCarId,
  handleStatusChange,
  handleSelectCarId,
}) => {
  const statusClass = statusClasses[car.status] || statusClasses.DEFAULT;

  return (
    <div
      className={`flex flex-col justify-center items-center gap-4 mb-8 bg-white border border-1 border-[#ADAFB0] w-full  m-auto rounded-3xl p-5 py-10 h-[140px] shadow-md cursor-pointer focus:bg-black active:bg-green-200 ${
        selectedCarId === car?._id ? " shadow-lg shadow-slate-950" : " "
      }`}
      onClick={
        () => handleSelectCarId(car?._id)
        // setListingDetails({ data: car, showDetails: true })
      }
    >
      <div className="flex gap-6 w-full h-[60px]">
        <div
          className="basis-[30%]"
          style={{
            backgroundImage: `url(${car.carImages[0]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        <div className="flex basis-[70%] items-center text-sm md:text-base justify-between pt-2 pb-5 border-b border-b-1 border-b-[#ADAFB0]">
          <div className="flex-1 w-fll">
            <h2 className="font-light text-[11px] md:text-[13px]">{`${car.carMakeID.make}`}</h2>

            <h2 className="font-medium text-[13px] md:text-[15px]">
              {formatCurrency(car.serviceTypes[0]?.price)}
            </h2>
          </div>

          <div className="flex flex-col gap-2 ">
            <h2
              className={`font-bold text-[9px] md:text-[11px] text-xs rounded-full bg-gray-300 py-1 px-2 items-center text-center  ${statusClass}`}
            >
              {car.status}
            </h2>

            <h2 className="text-[9px] md:text-[11px] font-light text-xs rounded-full bg-gray-300 py-1 px-2 items-center text-center">
              {car.negotiable ? "Negotiable" : "Non-negotiable"}
            </h2>
          </div>
        </div>
      </div>

      <div className="flex gap-2  sm:gap-4 w-full">
        <div
          className="basis-[33%] border border-1 border-[#ADAFB0] text-[12px] md:text-sm flex-1 rounded-full px-2 py-1 flex justify-center items-center font-light text-center shadow-md cursor-pointer "
          onClick={
            () => navigate(`/profile/listings/${car.carCode}`)
            // setListingDetails({ data: car, showDetails: true })
          }
        >
          Details
        </div>

        <div
          className="basis-[33%] border border-1 border-[#ADAFB0] text-[12px] md:text-sm flex-1 rounded-full px-2 py-1 flex justify-center items-center font-light text-center shadow-md cursor-pointer "
          onClick={() => navigate("/car/edit-list-vehicle", { state: car })}
        >
          Edit
        </div>

        <div
          className={`basis-[33%] border border-1 border-[#ADAFB0] flex-1 rounded-full text-[12px] md:text-sm w-20 text-center shadow-md flex ${
            car.status === "ACTIVE" ? "justify-end" : "justify-start"
          }`}
        >
          <div
            className={`${
              car.status === "ACTIVE" ? "bg-green-600" : "bg-red-600"
            } w-[80%] rounded-full text-[#F4F4F4] cursor-pointer font-light flex justify-center items-center py-1 `}
            onClick={() => handleStatusChange(car._id)}
          >
            {car.status === "ACTIVE" ? "Active" : "Inactive"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingsCarCard;
