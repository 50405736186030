import { FC } from "react";
import ListingFormHeader from "../../components/listingProcess.tsx/ListingFormHeader";
import FileUpload from "../../components/inputs/FileUpload";

interface handleNextClickProps {
  control: any;
  watch: any;
  errors: any;
  register: any;
}

const CarDocuments: FC<handleNextClickProps> = ({
  control,
  watch,
  errors,
  register,
}) => {
  return (
    <div className="w-full flex flex-col items-center gap-8 lg:max-w-[1140px] max-w-[1140px] lg:px-0 px-6">
      <ListingFormHeader
        header="Car images, documents and notes"
        subHeader="Please provide clear images/pdfs."
      />

      <div className=" w-full grid grid-cols-reverse lg:grid-cols-2 grid-cols-1 gap-4 opacity-70">
        <div className=" flex flex-col  lg:w-full w-full gap-4">
          <FileUpload
            control={control}
            name="carVehicleInsurance"
            errors={errors}
            watch={watch}
            label="Share vehicle insurance"
          />

          <FileUpload
            control={control}
            name="proofOfOwnership"
            errors={errors}
            watch={watch}
            label="Share proof of ownership"
          />

          <FileUpload
            control={control}
            name="carImages"
            errors={errors}
            watch={watch}
            label="Upload vehicle images"
            accept="4-10 images"
            multiple
          />
        </div>

        <div className=" flex flex-col  lg:w-full w-full gap-4">
          <FileUpload
            control={control}
            name="carVehicleLicense"
            errors={errors}
            watch={watch}
            label="Share vehicle license"
          />

          <FileUpload
            control={control}
            name="roadWorthiness"
            errors={errors}
            watch={watch}
            label="Share road worthiness"
          />

          <FileUpload
            control={control}
            name="carVideo"
            errors={errors}
            watch={watch}
            label="Share a short video about the vehicle"
            acceptLabel="Video"
            accept="video/*"
            fileSizeLimit={50000 * 1024}
          />

          <div>
            <label className="block mb-1 text-[12px]">
              Share more notes about the vehicle
            </label>
            <textarea
              id="notes"
              className="w-full border border-gray-300 rounded-md p-2"
              name="notes"
              placeholder="Notes"
              rows={6}
              {...register("notes")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarDocuments;
