import { z } from "zod";
import { OTHERS_SPECIFY_MESSAGE, serviceTypes } from "../utils/constants";

type FileSchema = {
  message?: string;
  optional?: boolean;
  min?: number;
  max?: number;
};

const getFileSchema = ({
  message = "",
  optional = false,
  min = 1,
  max = 10,
}: FileSchema) => {
  if (optional) {
    return z.union([
      // Array of files
      z
        .array(
          z.object({
            file: z.instanceof(File),
          })
        )
        .optional(),

      // Array of strings (URLs)
      z.array(z.string()).optional(),

      // Single string (URL)
      z.string().optional(),
    ]);
  }

  return z.union([
    // Array of files
    z
      .array(
        z.object({
          file: z.instanceof(File),
        })
      )
      .min(1, { message })
      .max(max, { message: "You can have a maximum of 10 files" }),

    // Array of strings (URLs)
    z
      .array(z.string())
      .min(min, { message })
      .max(max, { message: "You can have a maximum of 10 files" }),

    // Single string (URL)
    z
      .string()
      .min(min, { message })
      .max(max, { message: "You can have a maximum of 10 files" }),
  ]);
};

const HostDetailsSchema = z.object({
  firstName: z
    .string({ errorMap: () => ({ message: "First name is required" }) })
    .min(1, { message: "First name is required" }),
  lastName: z
    .string({ errorMap: () => ({ message: "Last name is required" }) })
    .min(1, { message: "Last name is required" }),
  email: z
    .string({ errorMap: () => ({ message: "Email is required" }) })
    .min(1, "Email is required")
    .email("Invalid email address"),
  phoneNumber: z
    .string({ errorMap: () => ({ message: "Phone number is required" }) })
    .min(1, { message: "Phone number is required" }),
  address: z
    .string({ errorMap: () => ({ message: "Address is required" }) })
    .min(1, { message: "Address is required" }),
  companyDetails: z
    .object({
      registerCompany: z.boolean().optional().default(false),
      cacDocument: getFileSchema({ optional: true }),
    })
    .superRefine((data, ctx) => {
      if (data.registerCompany && !data.cacDocument?.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "CAC document is required",
          path: ["cacDocument"],
        });
      }
    }),
  hostDateOfBirth: z
    .string({ errorMap: () => ({ message: "Date of birth is required" }) })
    .min(1, { message: "Date of birth is required" }),
  passport: getFileSchema({ optional: true }),
  idType: z
    .string({ errorMap: () => ({ message: "ID type is required" }) })
    .min(1, { message: "ID type is required" }),
  validIDCard: getFileSchema({ message: "Valid ID is required" }),
  utilityBill: getFileSchema({ message: "Utility bill is required" }),
});

const CarDetailsSchema = z.object({
  carMake: z
    .string({ errorMap: () => ({ message: "Car make is required" }) })
    .min(1, { message: "Car make is required" }),
  registrationNumber: z
    .string({
      errorMap: () => ({ message: "Registration number is required" }),
    })
    .min(1, { message: "Registration number is required" }),
  carModel: z
    .string({ errorMap: () => ({ message: "Car model is required" }) })
    .min(1, { message: "Car model is required" }),
  carYear: z
    .string({ errorMap: () => ({ message: "Car year is required" }) })
    .min(1, { message: "Car year is required" }),
  location: z.object({
    address: z.string().min(1, { message: "Address is required" }),
    lng: z.number().refine((value) => value >= -180 && value <= 180, {
      message: "Longitude must be between -180 and 180",
    }),
    lat: z.number().refine((value) => value >= -90 && value <= 90, {
      message: "Latitude must be between -90 and 90",
    }),
  }),
  vehicleType: z
    .string({ errorMap: () => ({ message: "Vehicle body type is required" }) })
    .min(1, "Vehicle body type is required"),
  specialFeatures: z
    .array(z.string())
    .min(1, { message: "At least one special feature is required" }),
  carExteriorColor: z
    .object({
      primary: z.string().min(1, { message: "Car exterior color is required" }),
      secondary: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (data.primary === OTHERS_SPECIFY_MESSAGE && !data.secondary) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Car exterior color is required",
          path: ["secondary"],
        });
      }
    }),
  carInteriorColor: z
    .object({
      primary: z.string().min(1, { message: "Car interior color is required" }),
      secondary: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (data.primary === OTHERS_SPECIFY_MESSAGE && !data.secondary) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Car interior color is required",
          path: ["secondary"],
        });
      }
    }),
  canGuestDriveYourCar: z
    .string({
      errorMap: () => ({
        message: "Can guest drive your car must be 'Yes' or 'No'",
      }),
    })
    .min(1, { message: "Can guest drive your car must be 'Yes' or 'No'" }),
  availableForInterState: z
    .string({
      errorMap: () => ({
        message: "Available for inter-state must be 'Yes' or 'No'",
      }),
    })
    .min(1, { message: "Available for inter-state must be 'Yes' or 'No'" }),
  wasCarUpgraded: z
    .string({
      errorMap: () => ({ message: "Was car upgraded must be 'Yes' or 'No'" }),
    })
    .min(1, { message: "Was car upgraded must be 'Yes' or 'No'" }),
  upgradedYear: z.string().optional(),
  numberOfDoors: z
    .string({ errorMap: () => ({ message: "Number of doors is required" }) })
    .min(1, { message: "Number of doors is required" }),
  estimatedCarValue: z
    .string({
      errorMap: () => ({ message: "Estimated car value is required" }),
    })
    .min(1, { message: "Estimated car value is required" }),
  numberOfPassengers: z
    .string({
      errorMap: () => ({ message: "Number of passengers is required" }),
    })
    .min(1, { message: "Number of passengers is required" }),
});

const CarDocumentsSchema = z.object({
  carVehicleLicense: getFileSchema({ message: "Vehicle license is required" }),
  roadWorthiness: getFileSchema({ message: "Road worthiness is required" }),
  carVehicleInsurance: getFileSchema({
    message: "Vehicle insurance is required",
  }),
  proofOfOwnership: getFileSchema({
    message: "Proof of ownership is required",
  }),
  carImages: getFileSchema({
    message: "At least 4 car images are required",
    min: 4,
  }),
  carVideo: getFileSchema({
    message: "Short video is required",
  }),
  notes: z.string().optional(),
});

const ServicePricingSchema = z.object({
  availability: z
    .string({
      errorMap: () => ({ message: "Availability must be 'Yes' or 'No'" }),
    })
    .min(1, { message: "Availability must be 'Yes' or 'No'" }),
  daysAvailable: z
    .array(z.string())
    .min(1, { message: "At least one day is required" }),
  noticePeriod: z
    .string({ errorMap: () => ({ message: "Notice period is required" }) })
    .min(1, { message: "Notice period is required" }),
  selectedServices: z
    .object({
      services: z
        .array(z.string())
        .min(1, { message: "At least one service is required" }),
      servicesIds: z
        .array(z.string())
        .min(1, { message: "At least one service is required" }),
      allowDMMSetPrice: z.boolean().optional(),
      service_001: z.string().optional(),
      service_002: z.string().optional(),
      service_003: z.string().optional(),
      service_004: z.string().optional(),
      service_005: z.string().optional(),
      service_006: z.string().optional(),
      service_007: z.string().optional(),
      service_008: z.string().optional(),
      service_009: z.string().optional(),
      service_010: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      data.servicesIds.forEach((service, idx) => {
        const serviceName = data.services?.[idx];

        const isQuoteRequired = serviceTypes.find(
          (service) => service.name === serviceName
        )?.isQuoteRequired;

        const dmmCanSetPrice = data.allowDMMSetPrice;

        if (
          !isQuoteRequired &&
          !dmmCanSetPrice &&
          (data[service as keyof typeof data] === undefined ||
            data[service as keyof typeof data] === "")
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `${serviceName} Price is required`,
            path: [service as keyof typeof data],
          });
        }
      });
    }),
  serviceTypes: z
    .array(
      z.object({
        isReturned: z.boolean(),
        name: z.string(),
        price: z.number(),
        _id: z.string(),
      })
    )
    .min(1, { message: "At least one service type is required" }),
  allowOffer: z.boolean().optional(),
});

const DriverDetailsSchema = z.object({
  driverFirstName: z.string().optional(),
  driverLastName: z.string().optional(),
  driverDateOfBirth: z.string().optional(),
  driverPhoneNumber: z.string().optional(),
  driverStateOfOrigin: z.string().optional(),
  driverLicense: z.string().optional(),
  driverLicenseUpload: getFileSchema({ optional: true }),
  driverPicture: getFileSchema({ optional: true }),
  driverLanguages: z.array(z.string()).optional(),
  driverStatesFamiliar: z.array(z.string()).optional(),
});

const BankDetailsSchema = z.object({
  accountName: z
    .string({ errorMap: () => ({ message: "Account name is required" }) })
    .min(1, { message: "Account name is required" }),
  accountNum: z
    .string({ errorMap: () => ({ message: "Account number is required" }) })
    .min(1, { message: "Account number is required" }),
  bankName: z
    .string({ errorMap: () => ({ message: "Bank name is required" }) })
    .min(1, { message: "Bank name is required" }),
  sortCode: z.string().optional(),
});

const DeclarationSchema = z.object({
  myCarInACPerfectCondition: z.boolean().optional(),
  myCarHasNoMechanicalOrElectricalIssue: z.boolean().optional(),
  noCosmeticDamage: z.boolean().optional(),
  functionalStereo: z.boolean().optional(),
  functionalWindowWinders: z.boolean().optional(),
  nonSmokingVehicle: z.boolean().optional(),
  noUnusualSound: z.boolean().optional(),
  servicedRegularly: z.boolean().optional(),
  readyForRental: z.boolean().optional(),
  carNeedOtherFixing: z.boolean().optional(),
  commentStateOfCar: z.string().optional(),
  acknowledged: z.boolean().refine((val) => val === true, {
    message: "Acknowledgement is required",
  }),
});

export const FormDataSchema = z.object({
  ...HostDetailsSchema.shape,
  ...CarDetailsSchema.shape,
  ...CarDocumentsSchema.shape,
  ...ServicePricingSchema.shape,
  ...DriverDetailsSchema.shape,
  ...BankDetailsSchema.shape,
  ...DeclarationSchema.shape,
});

export const EditFormDataSchema = z.object({
  ...CarDetailsSchema.shape,
  ...CarDocumentsSchema.shape,
  ...ServicePricingSchema.shape,
  ...DriverDetailsSchema.shape,
});

export const AssignDriverFormDataSchema = z.object({
  driverFirstName: z
    .string({ errorMap: () => ({ message: "First name is required" }) })
    .min(1, { message: "First name is required" }),
  driverLastName: z
    .string({ errorMap: () => ({ message: "Last name is required" }) })
    .min(1, { message: "Last name is required" }),
  driverDateOfBirth: z
    .string({ errorMap: () => ({ message: "Date of birth is required" }) })
    .min(1, { message: "Date of birth is required" }),
  driverPhoneNumber: z
    .string({ errorMap: () => ({ message: "Phone number is required" }) })
    .regex(/^\d+$/, "Phone number must be valid and contain only numbers")
    .min(1, { message: "Phone number is required" }),
  driverStateOfOrigin: z
    .string({ errorMap: () => ({ message: "State of origin is required" }) })
    .min(1, { message: "State of origin is required" }),
  driverLicense: z
    .string({
      errorMap: () => ({ message: "Driver license number is required" }),
    })
    .min(1, { message: "Driver license number is required" }),
  driverLicenseUpload: getFileSchema({ message: "Driver license is required" }),
  driverPicture: getFileSchema({ message: "Driver picture is required" }),
  driverLanguages: z
    .array(z.string())
    .min(1, { message: "At least one language is required" }),
  driverStatesFamiliar: z.array(z.string()).optional(),
});

export const LoginFormSchema = z.object({
  email: z
    .string({ errorMap: () => ({ message: "Email address is required" }) })
    .min(1, "Email address is required")
    .email({ message: "Invalid email address" }),
  password: z
    .string({ errorMap: () => ({ message: "Password is required" }) })
    .min(1, "Password is required"),
});

export const ForgotPasswordFormSchema = z.object({
  email: z
    .string({ errorMap: () => ({ message: "Email address is required" }) })
    .min(1, "Email address is required")
    .email({ message: "Invalid email address" }),
});

export const ResetPasswordFormSchema = z
  .object({
    email: z
      .string({ errorMap: () => ({ message: "Email address is required" }) })
      .min(1, "Email address is required")
      .email({ message: "Invalid email address" }),
    resetPin: z.string(),
    password: z
      .string({
        errorMap: () => ({
          message: "Password must be at least 6 characters long",
        }),
      })
      .min(6, "Password must be at least 6 characters long")
      .refine(
        (value) => {
          const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/;
          return passwordRegex.test(value);
        },
        {
          message:
            "Password must be at least 6 characters long, include at least one uppercase letter, one lowercase letter, and one number",
        }
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords must match",
    path: ["confirmPassword"],
  });

export const SignupFormSchema = z
  .object({
    firstName: z
      .string({ errorMap: () => ({ message: "First name is required" }) })
      .min(1, "First name is required"),
    lastName: z
      .string({ errorMap: () => ({ message: "Last name is required" }) })
      .min(1, "Last name is required"),
    email: z
      .string({ errorMap: () => ({ message: "Invalid email address" }) })
      .email("Invalid email address"),
    phoneNumber: z
      .string({
        errorMap: () => ({
          message: "Phone number must be valid and contain only numbers",
        }),
      })
      .regex(/^\d+$/, "Phone number must be valid and contain only numbers"),
    password: z
      .string({
        errorMap: () => ({
          message: "Password must be at least 6 characters long",
        }),
      })
      .min(6, "Password must be at least 6 characters long")
      .refine(
        (value) => {
          const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/;
          return passwordRegex.test(value);
        },
        {
          message:
            "Password must be at least 6 characters long, include at least one uppercase letter, one lowercase letter, and one number",
        }
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export const ResendVerificationFormSchema = z.object({
  email: z
    .string({ errorMap: () => ({ message: "Email address is required" }) })
    .min(1, "Email address is required")
    .email({ message: "Invalid email address" }),
});

export const TopUpFormSchema = z.object({
  amount: z
    .string({ errorMap: () => ({ message: "Enter a valid amount to top up" }) })
    .min(1, { message: "Enter a valid amount to top up" }),
});

export const WalletWithdrawalFormSchema = z.object({
  amount: z
    .string({
      errorMap: () => ({ message: "Enter a valid amount to withdraw" }),
    })
    .min(1, { message: "Enter a valid amount to withdraw" }),
  bank: z
    .string({ errorMap: () => ({ message: "Bank name is required" }) })
    .min(1, { message: "Bank name is required" }),
  accountNumber: z
    .string({ errorMap: () => ({ message: "Account Number is required" }) })
    .min(1, { message: "Account Number is required" }),
  accountName: z
    .string({ errorMap: () => ({ message: "Account Name is required" }) })
    .min(1, { message: "Account Name is required" }),
  description: z.string().optional(),
});
