import axios from "axios";
import { useEffect, useState } from "react";
import {
  TransactionProps,
  transactionWallet,
} from "../../assets/jsons/transaction-wallet";
import { walletHistory } from "../../assets/jsons/wallet-history";
import { useData } from "../../Hooks/useData";
import ProfileTransactionCard from "../../components/card/ProfileTransactionCard";
import TransactionEntry from "../../components/profile/TransactionEntry";

const BASE_URL = process.env.REACT_APP_BASE_URL;

interface StatusProps {
  component: TransactionProps[];
  title: string;
  id: number;
}

const Transactions = () => {
  const [external, setExternal] = useState([]);
  const [internal, setInternal] = useState([]);
  const { userData } = useData();

  useEffect(() => {
    const getWallet = async () => {
      try {
        if (userData?.userId) {
          const internalResponse = await axios.get(
            `${BASE_URL}/wallet/internalTransctions/${userData?.userId}/660521261fef07f66ba0e2df`
          );
          const externalResponse = await axios.get(
            `${BASE_URL}/wallet/externalTransctions/${userData?.userId}/660520708eb0dce655438c8d`
          );
          setInternal(internalResponse?.data?.data?.reverse());
          setExternal(externalResponse?.data?.data?.reverse());
        }
      } catch (error) {
        console.log("error fetching  data --> ", error);
      }
    };

    getWallet();
  }, [userData]);

  const [currentComponent, setCurrentComponent] = useState<
    TransactionProps[] | null
  >(transactionWallet);

  const status: StatusProps[] = [
    { title: "Transactions", component: transactionWallet, id: 1 },
    { title: "Deposit", component: walletHistory, id: 2 },
  ];

  const handleSetComponent = (component: TransactionProps[]) => {
    setCurrentComponent(component);
  };

  return (
    <div className="flex flex-col w-full items-center justify-center">
      <div className="w-full md:hidden">
        <div className="bg-[#f4f4f4] w-full py-6">
          <div className="grid grid-cols-2 gap-2 items-center justify-center px-2">
            {status.map((status, i) => (
              <button
                key={status.id}
                className={`${
                  status.component === currentComponent
                    ? "bg-[#efcb76] border-none font-normal"
                    : "bg-white-600 font-light"
                }  border border-1 border-[#8B8989] text-[14px] rounded-full py-2 px-10 whitespace-nowrap`}
                onClick={() => handleSetComponent(status.component)}
              >
                {status.title}
              </button>
            ))}
          </div>
        </div>

        <div className="rounded-b-3xl px-0 md:px-6 overflow-y-auto">
          {currentComponent === transactionWallet &&
            internal?.map((val: any, idx) => (
              <TransactionEntry value={val} key={idx} />
            ))}
        </div>

        <div className="rounded-b-3xl px-0 md:px-6 overflow-y-auto">
          {currentComponent === walletHistory &&
            external.map((val: any, idx) => (
              <TransactionEntry value={val} key={idx} />
            ))}
        </div>

        <div className="flex lg:hidden w-full flex-col px-8 justify-center ">
          {/* {currentComponent?.map((component) => (
            <TransactionItem component={component} />
          ))} */}
        </div>
      </div>

      <div className="hidden flex-col lg:flex-row md:flex gap-8 justify-center items-center w-full">
        {/* .....LEFT.... */}
        <ProfileTransactionCard title="Transaction">
          {internal?.map((val: any, idx) => (
            <TransactionEntry value={val} key={idx} />
          ))}
        </ProfileTransactionCard>

        {/* .....RIGHT.... */}
        <ProfileTransactionCard variant="green" title="Transaction">
          {external?.map((val: any, idx) => (
            <TransactionEntry value={val} key={idx} />
          ))}
        </ProfileTransactionCard>
      </div>
    </div>
  );
};

export default Transactions;
