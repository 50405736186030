import React, { useState } from "react";
import CustomButton from "../elements/CustomButton";
import { useDataContext } from "../../providers/DataProvider";
import { IoSearchOutline } from "react-icons/io5";
import DmmAutocomplete from "../field/DmmAutocomplete";
import DmmSelect from "../field/DmmSelect";
import { useLocation, useNavigate } from "react-router-dom";
import { updateParams } from "../../utils/utilsFunctions";
import { serviceTypes } from "../../utils/constants";
import DmmCalendarPicker from "./DmmCalendarPicker";
import dayjs, { Dayjs } from "dayjs";

interface SearchBoxProps {
  buttonTitle?: string;
  flexDirection?: string;
  searchboxHeight?: string;
  searchBoxClasses?: any;
  displayTitles?: boolean;
  allowSearch?: boolean;
  applyToolTip?: boolean;
  vehicleServiceTypeData?: any[];
  showMobileVersion?: boolean;
  insideButton?: boolean;
  outsideButton?: boolean;
  width?: string;
}

const SearchBox: React.FC<SearchBoxProps> = ({
  buttonTitle,
  searchBoxClasses,
  displayTitles = true,
  allowSearch = true,
  vehicleServiceTypeData,
  applyToolTip,
  showMobileVersion = false,
  insideButton = false,
  outsideButton = false,
  flexDirection = "flex-row",
  searchboxHeight = "",
  width = "",
}) => {
  const {
    fromWhereInputValue,
    fromWhereSelected,
    handleFromWhereSelected,
    updateFromWhereInputValue,
    finalDestinationInputValue,
    finalDestinationSelected,
    handleFinalDestinationSelected,
    updateFinalDestinationInputValue,
    returnTime,
    pickupTime,
    returnType,
    // serviceTypeData,
    serviceType,
    handleServiceTypeChange,
    setPickupTime,
    setReturnTime,
    fetchData,
  } = useDataContext();
  const [showEdit, setShowEdit] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showDestinationBox =
    serviceType.includes("Airport Pickup") ||
    serviceType.includes("Airport Dropoff") ||
    serviceType.includes("Evening Hangout");

  const handlePickupTimeChange = (value: Dayjs | null | string) => {
    if (typeof value === "string") {
      const parsedValue = dayjs(value);

      if (parsedValue.isValid()) {
        setPickupTime(parsedValue.toISOString());
      }
    } else if (value && value.isValid()) {
      setPickupTime(value.toISOString());
    }
  };

  const handleReturnTimeChange = (value: Dayjs | null | string) => {
    if (typeof value === "string") {
      const parsedValue = dayjs(value);

      if (parsedValue.isValid()) {
        setReturnTime(parsedValue.toISOString());
      }
    } else if (value && value.isValid()) {
      setReturnTime(value.toISOString());
    }
  };

  const handleSearch = async () => {
    setSearchLoading(true);

    await fetchData();

    const params = updateParams({
      serviceType,
      fromWhereSelected,
      finalDestinationSelected,
      pickupTime,
      returnTime,
      queryParams,
    });

    const url = `/car?${params.toString()}`;
    navigate(url);

    setSearchLoading(false);
  };

  const handleEdit = async () => {
    setEditLoading(true);

    await fetchData();

    const params = updateParams({
      serviceType,
      fromWhereSelected,
      finalDestinationSelected,
      pickupTime,
      returnTime,
      queryParams,
    });

    const url = `/car?${params.toString()}`;
    navigate(url);

    setEditLoading(false);
  };

  const getUpdatedServiceType = () => {
    const updatedServiceType: any[] = [];

    vehicleServiceTypeData?.forEach((val: any) => {
      const foundServiceType = serviceTypes?.find(
        (service) => service._id === val._id
      );

      if (foundServiceType) {
        updatedServiceType.push({
          ...val,
          description: foundServiceType.description,
        });
      }
    });

    return updatedServiceType;
  };

  return (
    <>
      <div className="hidden lg:flex justify-center items-center w-full gap-6">
        <div
          className={` ${flexDirection} flex-1 hidden lg:flex  bg-white items-center justify-center rounded-full px-2 gap-4 w-full ${
            displayTitles
              ? `h-[80px] py-2 ${searchboxHeight}`
              : `h-[60px] py-1 ${searchboxHeight}`
          } `}
        >
          <div className={` ${width} flex justify-start items-center flex-1 `}>
            {/* <div className="w-full"> */}
            <div
              className={`bg-[#F1F1F2] w-full rounded-full px-2 pt-2 ${
                displayTitles ? "pb-0" : "pb-1"
              }`}
            >
              <div className="w-full m-auto px-4">
                {displayTitles && (
                  <p className="text-[13px] font-light">From where?</p>
                )}
                <div className={`${width}`}>
                  <DmmAutocomplete
                    selected={fromWhereSelected.address}
                    setSelected={handleFromWhereSelected}
                    setInputValue={updateFromWhereInputValue}
                    inputValue={fromWhereInputValue}
                    disabled={!allowSearch}
                    placeholder="Enter pickup location"
                  />
                </div>
              </div>
            </div>

            {showDestinationBox && (
              <div className="px-2 w-full pt-2">
                <div className="w-full m-auto px-4">
                  {displayTitles && (
                    <p className="text-[13px] font-light">Final destination?</p>
                  )}
                  <div className="">
                    <DmmAutocomplete
                      selected={finalDestinationSelected.address}
                      setSelected={handleFinalDestinationSelected}
                      setInputValue={updateFinalDestinationInputValue}
                      inputValue={finalDestinationInputValue}
                      placeholder="Enter return location"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className={` ${width} flex flex-col items-center justify-center text-left border-x-2 px-4 w-[20%] max-w-[20%] grow-0 `}
          >
            <div className="w-full">
              {displayTitles && (
                <p className="text-[12px] font-light pt-1">Service type</p>
              )}

              <DmmSelect
                handleChange={handleServiceTypeChange}
                options={
                  !!vehicleServiceTypeData
                    ? getUpdatedServiceType()
                    : serviceTypes
                }
                value={serviceType}
                applyToolTip={applyToolTip}
              />
            </div>
          </div>

          <div
            className={` ${width} w-[15%] px-2 flex flex-col justify-center `}
          >
            {displayTitles && (
              <p className="text-[12px] font-light mb-1">Pick-up date & time</p>
            )}

            <DmmCalendarPicker
              pickupTime={pickupTime}
              handleChange={handlePickupTimeChange}
            />
          </div>

          {returnType && (
            <div
              className={` ${width} w-[15%] px-2 flex flex-col justify-center`}
            >
              {displayTitles && (
                <p className="text-[12px] font-light mb-1">
                  Return date & time
                </p>
              )}

              <DmmCalendarPicker
                pickupTime={returnTime}
                handleChange={handleReturnTimeChange}
              />
            </div>
          )}

          {insideButton && (
            <>
              <div
                className={` ${
                  searchBoxClasses?.buttonClass
                } rounded-full bg-[#0e1427] min-w-[10%] ${
                  displayTitles ? "py-[20px]" : "py-3"
                } justify-center items-center hidden xl:flex`}
              >
                <CustomButton
                  className={`  text-center text-lg font-light  text-[15px]`}
                  loading={searchLoading}
                  onClick={handleSearch}
                >
                  {buttonTitle || "Search"}
                </CustomButton>
              </div>

              <div
                className={`rounded-full bg-[#0e1427] flex xl:hidden justify-center items-center ${
                  displayTitles
                    ? "min-w-[70px] w-[70px] h-[70px]"
                    : "min-w-[50px] w-[50px] h-[50px]"
                }`}
              >
                <CustomButton
                  className="text-center text-lg font-light text-white text-[15px]"
                  loading={searchLoading}
                  onClick={handleSearch}
                >
                  {!searchLoading && <IoSearchOutline />}
                </CustomButton>
              </div>
            </>
          )}
        </div>

        {outsideButton && allowSearch && (
          <div className="text-amber bg-white-700 border-[#0e1427] border rounded-full px-10 py-1">
            <CustomButton
              className={`text-center text-lg font-light text-[15px]`}
              loading={editLoading}
              color="black"
              onClick={handleEdit}
            >
              {buttonTitle || "Search"}
            </CustomButton>
          </div>
        )}
      </div>

      {showMobileVersion && (
        <div className="block lg:hidden">
          {!showEdit && (
            <div className="flex justify-start items-start py-2">
              <div className="grid grid-cols-2 flex-1 gap-2">
                <p className="text-[13px] font-light">
                  {fromWhereInputValue || "From where?"}
                </p>
                {showDestinationBox && (
                  <p className="text-[13px] font-light">
                    {finalDestinationInputValue || "Final destination?"}
                  </p>
                )}
                <p className="text-[13px] font-light">
                  {pickupTime || "Pick-up date & time"}
                </p>
                {returnType && (
                  <p className="text-[13px] font-light">
                    {returnTime || "Return date & time"}
                  </p>
                )}
                <p className="text-[13px] font-light">
                  {serviceType || "Service type"}
                </p>
              </div>

              <div
                className="lg:hidden px-4 py-1 border border-1 border-black rounded-full text-[14px] cursor-pointer"
                onClick={() => {
                  setShowEdit(true);
                }}
              >
                Edit
              </div>
            </div>
          )}

          {showEdit && (
            <div>
              <div>
                <div className="w-full flex flex-col gap-3">
                  <div className="w-full m-auto px-8 border border-black border-1 rounded-full pt-2 h-[60px]">
                    <p className="text-[13px] font-light">From where?</p>
                    <div className="">
                      <DmmAutocomplete
                        selected={fromWhereSelected.address}
                        setSelected={handleFromWhereSelected}
                        setInputValue={updateFromWhereInputValue}
                        inputValue={fromWhereInputValue}
                      />
                    </div>
                  </div>

                  {showDestinationBox && (
                    <div className="w-full m-auto px-8 border border-black border-1 rounded-full pt-2 h-[60px]">
                      <p className="text-[13px] font-light">
                        Final destination?
                      </p>
                      <div className="">
                        <DmmAutocomplete
                          selected={finalDestinationSelected.address}
                          setSelected={handleFinalDestinationSelected}
                          setInputValue={updateFinalDestinationInputValue}
                          inputValue={finalDestinationInputValue}
                        />
                      </div>
                    </div>
                  )}

                  <div className="w-full m-auto px-8 border border-black border-1 rounded-full pt-2 h-[60px]">
                    <p className="text-[12px] font-light">Service type</p>
                    <DmmSelect
                      handleChange={handleServiceTypeChange}
                      options={serviceTypes}
                      value={serviceType}
                    />
                  </div>

                  <div className="w-full m-auto px-8 border border-black border-1 rounded-full pt-2 h-[60px]">
                    <p className="text-[12px] font-light mb-1">
                      Pick-up date & time
                    </p>

                    <DmmCalendarPicker
                      pickupTime={pickupTime}
                      handleChange={handlePickupTimeChange}
                    />
                  </div>

                  {returnType && (
                    <div className="w-full m-auto px-8 border border-black border-1 rounded-full pt-2 h-[60px]">
                      <p className="text-[12px] font-light mb-1">
                        Return date & time
                      </p>

                      <DmmCalendarPicker
                        pickupTime={returnTime}
                        handleChange={handleReturnTimeChange}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex justify-center items-center w-full gap-4 mt-2">
                <button
                  className="w-full text-base font-medium text-black rounded-full bg-transparent shadow-none outline outline-1 outline-black px-10 md:px-[15%] py-3 mt-3"
                  onClick={() => {
                    setShowEdit(false);
                  }}
                >
                  Close
                </button>

                {outsideButton && (
                  <button
                    className="w-full text-base font-medium text-white rounded-full bg-black px-10 md:px-[15%] py-3 mt-3"
                    onClick={handleEdit}
                  >
                    Search
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SearchBox;
