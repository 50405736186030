import axios from "axios";
import React, { useEffect, FC, useState, FormEvent } from "react";
import ListingFormHeader from "../../components/listingProcess.tsx/ListingFormHeader";
import FieldSelect, {
  booleanOptions,
} from "../../components/inputs/FieldSelect";
import FieldAutocomplete from "../../components/inputs/FieldAutocomplete";
import Field from "../../components/inputs/Field";
import DmmAutocompleteWrapper from "../../components/inputs/DmmAutocompleteWrapper";
import FieldCurrency from "../../components/inputs/FieldCurrency";
import {
  exteriorColors,
  interiorColors,
  OTHERS_SPECIFY_MESSAGE,
  specialFeatures,
  vehicleTypes,
} from "../../utils/constants";

const BASE_URL = process.env.REACT_APP_BASE_URL;

interface handleNextClickProps {
  register: any;
  control: any;
  errors: any;
  watch: any;
  setValue: any;
}

const CarDetails: FC<handleNextClickProps> = ({
  register,
  errors,
  control,
  watch,
  setValue,
}) => {
  const [carModel, setCarModel] = useState<any>();

  const [carMake, setCarMake] = useState<
    {
      id: number;
      make: string;
    }[]
  >([]);

  const [showOtherExteriorColorField, setShowOtherExteriorColorField] =
    useState(false);
  const [showOtherInteriorColorField, setShowOtherInteriorColorField] =
    useState(false);

  const watchExteriorColor = watch("carExteriorColor.primary", "");
  const watchInteriorColor = watch("carInteriorColor.primary", "");
  const selectedCar = watch("carMake", "");
  const selectedCarModel = watch("carModel", "");
  const carProductionYear = selectedCarModel.split(" - ").pop();
  const isCarUpgraded = watch("wasCarUpgraded", false);

  useEffect(() => {
    setShowOtherExteriorColorField(
      watchExteriorColor === OTHERS_SPECIFY_MESSAGE ? true : false
    );

    setShowOtherInteriorColorField(
      watchInteriorColor === OTHERS_SPECIFY_MESSAGE ? true : false
    );
  }, [watchExteriorColor, watchInteriorColor]);

  const getModel = async () => {
    try {
      // Fetch car makes
      if (selectedCar) {
        const response = await axios.get(
          `${BASE_URL}/general/getCarModeByMake/${selectedCar}`
        );

        setCarModel(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getModel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCar]);

  useEffect(() => {
    const getData = async () => {
      try {
        // Fetch car makes
        const response = await axios.get(`${BASE_URL}/general/getCarMakes`);
        setCarMake(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, []);

  // Function to group car models by mode and year
  const groupCarModels = (data: any) => {
    const grouped: any = {};

    data?.forEach((model: { mode: any; year: any }) => {
      const key = `${model.mode} - ${model.year}`;

      if (!grouped[key]) {
        grouped[key] = model;
      }
    });

    return grouped;
  };

  // Function to generate options for select input
  const generateOptions = (groupedData: any) => {
    const options = Object.keys(groupedData).map((key) => {
      const model = groupedData[key];
      return `${model.mode} - ${model.year}`;
    });

    options.sort((a, b) => a.localeCompare(b));

    return options;
  };

  // Group car models by mode and year
  const groupedCarModels = groupCarModels(carModel);

  // Generate options for select input
  const options = generateOptions(groupedCarModels);

  return (
    <div className="w-full flex flex-col items-center gap-8 lg:max-w-[1140px] max-w-[1140px] lg:px-0 px-6">
      <ListingFormHeader
        header="Car details"
        subHeader="Please provide accurate deals to speed up vehicle verification"
      />

      <div className="w-full grid lg:grid-cols-2 grid-cols-1 gap-4 opacity-70 justify-items-center justify-center">
        <div className=" flex flex-col lg:w-full w-full gap-4 ">
          <FieldAutocomplete
            control={control}
            errors={errors}
            multiple={false}
            options={carMake
              .sort((a, b) => a.make.localeCompare(b.make))
              .map(({ make }) => {
                return make;
              })}
            fieldName="carMake"
            label="Car make"
            placeholder="Search Car Make"
          />

          <FieldAutocomplete
            control={control}
            errors={errors}
            options={options}
            multiple={false}
            onChange={(e: FormEvent, value: string) => {
              const modelYear = value.split(" - ").pop();

              setValue("carYear", modelYear);
            }}
            label="Car model"
            fieldName="carModel"
            placeholder="Search Car Model"
          />

          <FieldSelect
            options={[{ value: carProductionYear, label: carProductionYear }]}
            errors={errors}
            disabled
            label="Production year"
            fieldName="carYear"
            control={control}
          />

          <FieldSelect
            options={vehicleTypes.map((type) => ({
              value: type,
              label: type,
            }))}
            errors={errors}
            label="Select Vehicle body type"
            fieldName="vehicleType"
            control={control}
          />

          <Field
            type="text"
            label="Registration number"
            fieldName="registrationNumber"
            errors={errors}
            control={control}
          />

          <FieldSelect
            options={Object.entries(booleanOptions).map(([key, value]) => ({
              value: value,
              label: key,
            }))}
            errors={errors}
            label="Can guests drive your car?"
            fieldName="canGuestDriveYourCar"
            control={control}
          />

          <FieldSelect
            options={Object.entries(booleanOptions).map(([key, value]) => ({
              value: value,
              label: key,
            }))}
            errors={errors}
            label="Available for inter-state travel?"
            fieldName="availableForInterState"
            control={control}
          />

          <Field
            type="text"
            label="Number of doors"
            fieldName="numberOfDoors"
            errors={errors}
            control={control}
          />
        </div>

        <div className=" flex flex-col lg:w-full w-full gap-4">
          <FieldSelect
            options={exteriorColors.map((value) => ({
              value: value,
              label: value,
            }))}
            errors={errors}
            label="Car Exterior Color"
            fieldName="carExteriorColor.primary"
            control={control}
            errMessage={errors?.carExteriorColor?.primary?.message}
          />

          {showOtherExteriorColorField && (
            <Field
              type="text"
              label="Specify Exterior Color"
              fieldName="carExteriorColor.secondary"
              errors={errors}
              control={control}
              errMessage={errors?.carExteriorColor?.secondary?.message}
            />
          )}

          <FieldSelect
            options={interiorColors.map((value) => ({
              value: value,
              label: value,
            }))}
            errors={errors}
            label="Car Interior Color"
            fieldName="carInteriorColor.primary"
            control={control}
            errMessage={errors?.carInteriorColor?.primary?.message}
          />

          {showOtherInteriorColorField && (
            <Field
              type="text"
              label="Specify Interior Color"
              fieldName="carInteriorColor.secondary"
              errors={errors}
              control={control}
              errMessage={errors?.carInteriorColor?.secondary?.message}
            />
          )}

          <FieldAutocomplete
            control={control}
            errors={errors}
            options={specialFeatures}
            fieldName="specialFeatures"
            label="Special features"
            placeholder="Select special features"
          />

          <FieldCurrency
            control={control}
            name="estimatedCarValue"
            label="Estimated vehicle value (NGN)"
            errors={errors}
          />

          <Field
            type="text"
            label="Number of passengers"
            fieldName="numberOfPassengers"
            errors={errors}
            control={control}
          />

          <DmmAutocompleteWrapper
            name="location"
            control={control}
            label="Enter vehicle location"
            error={!!errors.location}
            errorMessage={errors.location?.message}
            inputStyle={{ fontWeight: 300, fontSize: 15 }}
            outlineStyle={{ borderRadius: "8px" }}
            variant="outlined"
            disabled={false}
          />

          <FieldSelect
            options={Object.entries(booleanOptions).map(([key, value]) => ({
              value: value,
              label: key,
            }))}
            errors={errors}
            label="Was your vehicle upgraded?"
            fieldName="wasCarUpgraded"
            control={control}
          />

          {isCarUpgraded === "true" && (
            <Field
              type="text"
              label="Upgraded to which year?"
              fieldName="upgradedYear"
              errors={errors}
              control={control}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CarDetails;
