import { Avatar } from "@mui/material";
import profilePlaceholder from "../../assets/images/avatar.webp";
import React from "react";
import { User } from "../../utils/types";

interface UserDetailsProps {
  userData: User | null;
}

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: { xs: 60, md: 90 },
      width: { xs: 60, md: 90 },
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};

const UserDetails: React.FC<UserDetailsProps> = ({ userData }) => {
  return (
    <div className="flex gap-6 justify-start items-center  w-full">
      <div>
        <Avatar
          {...stringAvatar(
            `${userData?.firstName || ""} ${userData?.lastName || ""}`
          )}
          src={userData?.pictureUrl || profilePlaceholder}
          alt="Profile image"
          className="border border-1 border-black"
        />
      </div>

      <div className="flex flex-col justify-start items-start font-medium py-2">
        <h2>{`${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`}</h2>

        <p className="text-[12px] font-light">User ID: {userData?.userId}</p>
      </div>
    </div>
  );
};

export default UserDetails;
