import React, { useState } from "react";
import { useAuth } from "../../context/authContext";
import axios from "axios";
import CustomButton from "../elements/CustomButton";
import { FaFacebook, FaGoogle, FaApple } from "react-icons/fa";
import DmmDialog from "./DmmDialog";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import { z } from "zod";
import { LoginFormSchema } from "../../validations/formDataSchema";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Field from "../inputs/Field";
import { useIsMobile } from "../../Hooks/useData";
import ResendVerification from "../signup/ResendVerification";

const BASE_URL = process.env.REACT_APP_BASE_URL;

type Inputs = z.infer<typeof LoginFormSchema>;

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  setSignUpDialog: () => void;
  hideCloseButton?: boolean;
}

export const handleRefresh = () => {
  window.location.reload();
};

const LoginModal: React.FC<LoginModalProps> = ({
  isOpen,
  onClose,
  setSignUpDialog,
  hideCloseButton,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(LoginFormSchema),
    mode: "onBlur",
  });

  const onInvalid = (errors: any) => console.error(errors);

  const [axiosErrors, setAxiosErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [isResetVerification, setIsResetVerification] = useState(false);
  const { dispatch } = useAuth();
  const isMobile = useIsMobile();

  const handleLogin: SubmitHandler<Inputs> = async (data: any) => {
    setLoading(true);

    const formattedData = {
      email: data["email"].toLowerCase(),
      password: data["password"],
    };

    try {
      const { data } = await axios.post(
        `${BASE_URL}/account/login`,
        formattedData
      );

      const isUserVerified = data?.data?.profile?.isVerified;

      if (!data?.isError && isUserVerified) {
        localStorage.setItem("token", data?.data?.token?.token);
        localStorage.setItem("firstName", data?.data?.profile?.firstName);
        localStorage.setItem("lastName", data?.data?.profile?.lastName);
        localStorage.setItem("userId", data?.data?.profile?.userId);
        localStorage.setItem("role", data?.data?.profile?.role?.name);
        localStorage.setItem(
          "UserData",
          JSON.stringify({
            ...data?.data?.profile,
            token: data?.data?.token?.token,
          })
        );

        dispatch({
          type: "LOGIN",
          payload: {
            firstName: data?.data?.profile?.firstName,
            lastName: data?.data?.profile?.lastName,
            userId: data?.data?.profile?.userId,
            userImage: "path/to/image.jpg",
          },
        });
        setLoading(false);

        setAxiosErrors({});

        reset();

        onClose();

        handleRefresh();
      }
    } catch (error) {
      console.error(error);

      setLoading(false);

      setAxiosErrors(error || {});
    }
  };

  const handleSignUp = () => {
    setSignUpDialog();

    setAxiosErrors("");

    onClose();
  };

  return (
    <>
      {!openForgotPassword && !isResetVerification && (
        <DmmDialog
          title="Log In"
          onClose={() => {
            onClose();
            setAxiosErrors("");
          }}
          open={isOpen}
          hideCloseButton={hideCloseButton}
          fullScreen={isMobile}
        >
          {!isResetVerification && (
            <form onSubmit={handleSubmit(handleLogin, onInvalid)}>
              <div className="flex flex-col gap-3 w-full h-full py-2">
                <Field
                  type="text"
                  label="Email address"
                  fieldName="email"
                  errors={errors}
                  control={control}
                />

                <Field
                  type="password"
                  label="Password"
                  fieldName="password"
                  errors={errors}
                  control={control}
                />
              </div>

              {axiosErrors?.response?.status >= 300 && (
                <p className="text-[13px] text-red-700">
                  {axiosErrors?.response?.data?.message}{" "}
                  {axiosErrors?.response?.data?.message ===
                  "This account is yet to be verified, check email for verification link " ? (
                    <span
                      className="underline text-blue-400 font-semibold cursor-pointer"
                      onClick={() => {
                        setIsResetVerification(true);
                      }}
                    >
                      or click to resend verification link
                    </span>
                  ) : null}
                </p>
              )}

              <CustomButton
                loading={loading}
                type="submit"
                className="w-full bg-[#0e1427] text-white py-3 hover:bg-gray-700 focus:outline-none rounded-full mt-4"
                onClick={handleSubmit(handleLogin, onInvalid)}
              >
                Log in
              </CustomButton>

              <div className="flex justify-between items-center mt-4 text-[13px] md:text-[16px]">
                <div className="remember-me">
                  <input type="checkbox" id="remember-me" className="w-4 h-4" />
                  <label htmlFor="remember-me">Remember me</label>
                </div>
                <div
                  className="no-underline cursor-pointer"
                  onClick={() => setOpenForgotPassword(true)}
                >
                  Forgot password?
                </div>
              </div>

              <div className="divider">
                <span>Or log in with</span>
              </div>

              <div className="social-login-buttons flex mt-4 text-white text-[15px] justify-center items-center gap-3 w-full">
                <div className="flex justify-center sm:justify-start sm:pl-3  items-center gap-4 rounded-full bg-[#4268B3] py-2 w-1/3 ">
                  <FaFacebook className="text-[30px]" />{" "}
                  <span className="hidden sm:block">Facebook</span>
                </div>
                <div className="flex justify-center sm:justify-start sm:pl-3 items-center gap-4 rounded-full bg-[#EB2829] py-2 w-1/3 ">
                  <FaGoogle className="text-[30px]" />{" "}
                  <span className="hidden sm:block">Google</span>
                </div>
                <div className="flex justify-center sm:justify-start sm:pl-3  items-center gap-4 rounded-full bg-[#000000] py-2 w-1/3 ">
                  <FaApple className="text-[30px]" />{" "}
                  <span className="hidden sm:block">Apple</span>
                </div>
              </div>

              <div className="text-center mt-8 mb-4">
                <p className="text-[19px] font-light">
                  Don't have an account?{" "}
                  <span
                    className="font-semibold text-[#5CB2FC] cursor-pointer"
                    onClick={handleSignUp}
                  >
                    Sign up
                  </span>
                </p>
              </div>
            </form>
          )}
        </DmmDialog>
      )}

      {!openForgotPassword && isResetVerification && (
        <DmmDialog
          title="Log In"
          onClose={() => {
            onClose();
            setAxiosErrors("");
            setIsResetVerification(false);
          }}
          open={isOpen}
          hideCloseButton={hideCloseButton}
          fullScreen={isMobile}
        >
          <ResendVerification />
        </DmmDialog>
      )}

      {openForgotPassword && (
        <ForgotPasswordDialog
          onClose={() => {
            onClose();

            setAxiosErrors("");
          }}
          openForgotPassword={openForgotPassword}
          setOpenForgotPassword={setOpenForgotPassword}
        />
      )}
    </>
  );
};

export default LoginModal;
