import React, { useEffect, useState } from "react";
import TopOffer from "../../components/top-offers/Topoffer";
import Newsletter from "../../components/newsletter/Newsletter";
import { useData, useIsMobile } from "../../Hooks/useData";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import NavigationButtons from "../../components/profile/NavigationButtons";
import UserDetails from "../../components/profile/UserDetails";
import UserWallet from "../../components/profile/UserWallet";
import UserAssets from "../../components/profile/UserAssets";
import DmmDialog from "../../components/modal/DmmDialog";
import MobileProfile from "../../components/profile/MobileProfile";
import TopUp from "./TopUp";
import WalletWithdrawal from "./WalletWithdrawal";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const dataHost = [
  {
    id: 1,
    title: "Wallet",
    // element: <HostDetails formDetails={formDetails} setFormDetails={setFormDetails}/>,
    link: "wallet",
  },
  {
    id: 2,
    title: "Listings",
    link: "listings",
  },
  {
    id: 3,
    title: "Bookings",
    link: "bookings",
  },
];

const data = [
  {
    id: 1,
    title: "Wallet",
    link: "wallet",
  },

  {
    id: 3,
    title: "Bookings",
    link: "bookings",
  },
];

const Profile = () => {
  const [walletModal, setWalletModal] = useState(false);
  const [isWithdrawal, setIsWithdrawal] = useState(false);
  const isMobile = useIsMobile();
  const [walletData, setWalletData] = useState<any>(0);
  const { userData } = useData();

  const navigate = useNavigate();

  const role = userData?.role?.name;
  const displayData = role === "Host" ? dataHost : data;

  const fetchWalletData = async () => {
    try {
      if (userData?.userId) {
        const { data } = await axios.get(
          `${BASE_URL}/wallet/accountBalance/${userData?.userId}`
        );

        setWalletData(data?.data?.wallet);
      }
    } catch (error) {
      console.log("error fetching account balance data --> ", error);
    }
  };
  useEffect(() => {
    fetchWalletData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col items-center justify-center bg-white md:bg-[#F2F3F5] gap-6 w-full h-[180px] md:h-[300px]">
        <NavigationButtons data={displayData} />

        <div className="hidden md:flex items-center md:justify-between lg:max-w-[1350px] max-w-full w-full relative">
          <div className=" flex flex-col md:flex-row items-start md:items-center justify-center md:justify-between gap-4 w-full m-auto ">
            <UserDetails userData={userData} />

            <UserWallet
              walletData={walletData}
              setWalletModal={setWalletModal}
            />

            <div className="absolute right-4 top-4">
              <UserAssets navigate={navigate} />
            </div>
          </div>
        </div>

        <MobileProfile
          userData={userData}
          setWalletModal={setWalletModal}
          walletData={walletData}
          navigate={navigate}
        />
      </div>

      <div className="lg:max-w-[1350px] max-w-full w-full flex flex-col items-center lg:py-8 my-5 md:my-10 md:px-6">
        <Outlet />
      </div>

      <Newsletter />
      <TopOffer />

      <DmmDialog
        title=""
        open={walletModal}
        onClose={() => {
          setWalletModal(false);
        }}
        padding="p-0 pt-4"
        fullScreen={isMobile}
      >
        <div>
          <div className="px-2 lg:px-10 pt-5 mt-5">
            <div
              className={
                "flex rounded-full justify-between p-1 items-center lg:bg-[#fff] border-[#DDE1E5] border lg:border-1 text-[14px] py-1 mb-6 cursor-pointer"
              }
            >
              <div
                className={`${
                  !isWithdrawal
                    ? "text-[#f4f4f4] bg-[#0E1427] rounded-full items-center"
                    : ""
                } py-3 w-[50%] flex justify-center`}
                onClick={() => {
                  setIsWithdrawal(false);
                }}
              >
                <p>Top Up Wallet</p>
              </div>

              <div
                className={`${
                  isWithdrawal
                    ? "text-[#f4f4f4] bg-[#0E1427] rounded-full items-center"
                    : ""
                } py-3 w-[50%] flex justify-center`}
                onClick={() => {
                  setIsWithdrawal(true);
                }}
              >
                <p>Wallet Withdrawal</p>
              </div>
            </div>
          </div>

          <div className="p-2 lg:p-8 mt-2 rounded-b-lg bg-[#EBF1F4]">
            {!isWithdrawal ? (
              <TopUp setWalletModal={setWalletModal} />
            ) : (
              <WalletWithdrawal setWalletModal={setWalletModal} />
            )}
          </div>
        </div>
      </DmmDialog>
    </div>
  );
};

export default Profile;
