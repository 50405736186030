import React, { useState } from "react";
import DmmAutocomplete from "../field/DmmAutocomplete";
import { useDataContext } from "../../providers/DataProvider";
import DmmSelect from "../field/DmmSelect";
import { updateParams } from "../../utils/utilsFunctions";
import { useNavigate } from "react-router-dom";
import CustomButton from "../elements/CustomButton";
import { serviceTypes } from "../../utils/constants";
import DmmCalendarPicker from "../searchbox/DmmCalendarPicker";

const MobileSearchListCar = () => {
  const {
    handleFromWhereSelected,
    updateFromWhereInputValue,
    fromWhereInputValue,
    handleFinalDestinationSelected,
    updateFinalDestinationInputValue,
    finalDestinationInputValue,
    returnTime,
    pickupTime,
    returnType,
    handleServiceTypeChange,
    handlePickupTimeChange,
    handleReturnTimeChange,
    serviceType,
    // serviceTypeData,
    fromWhereSelected,
    finalDestinationSelected,
    fetchData,
  } = useDataContext();
  const queryParams = new URLSearchParams();
  const navigate = useNavigate();
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const showDestinationBox =
    serviceType.includes("Airport Pickup") ||
    serviceType.includes("Airport Dropoff") ||
    serviceType.includes("Evening Hangout");

  const toggleMobileSearchOpen = () => {
    setMobileSearchOpen(!mobileSearchOpen);
  };

  const handleSearch = async () => {
    setSearchLoading(true);

    await fetchData();

    const params = updateParams({
      serviceType,
      fromWhereSelected,
      finalDestinationSelected,
      pickupTime,
      returnTime,
      queryParams,
    });

    const url = `/car?${params.toString()}`;
    navigate(url);

    setSearchLoading(false);
  };

  return (
    <div className="flex lg:hidden flex-col gap-4 w-full px-6 justify-center items-center transition-all ease-in-out duration-300">
      {mobileSearchOpen && (
        <>
          <div className="w-full flex flex-col gap-3">
            <div className="w-full m-auto px-8 border border-black border-1 rounded-full pt-2 h-[60px]">
              <p className="text-[13px] font-light">From where?</p>
              <div className="">
                <DmmAutocomplete
                  selected={fromWhereSelected.address}
                  setSelected={handleFromWhereSelected}
                  setInputValue={updateFromWhereInputValue}
                  inputValue={fromWhereInputValue}
                />
              </div>
            </div>

            {showDestinationBox && (
              <div className="w-full m-auto px-8 border border-black border-1 rounded-full pt-2 h-[60px]">
                <p className="text-[13px] font-light">Final destination?</p>
                <div className="">
                  <DmmAutocomplete
                    selected={finalDestinationSelected.address}
                    setSelected={handleFinalDestinationSelected}
                    setInputValue={updateFinalDestinationInputValue}
                    inputValue={finalDestinationInputValue}
                  />
                </div>
              </div>
            )}

            <div className="w-full m-auto px-8 border border-black border-1 rounded-full pt-2 h-[60px]">
              <p className="text-[12px] font-light">Service type</p>
              <DmmSelect
                handleChange={handleServiceTypeChange}
                options={serviceTypes}
                value={serviceType}
              />
            </div>

            <div className="w-full m-auto px-8 border border-black border-1 rounded-full pt-2 h-[60px]">
              <p className="text-[12px] font-light mb-1">Pick-up date & time</p>
              <DmmCalendarPicker
                pickupTime={pickupTime}
                handleChange={handlePickupTimeChange}
              />
            </div>
            {returnType && (
              <div className="w-full m-auto px-8 border border-black border-1 rounded-full pt-2 h-[60px]">
                <p className="text-[12px] font-light mb-1">
                  Return date & time
                </p>
                <DmmCalendarPicker
                  pickupTime={returnTime}
                  handleChange={handleReturnTimeChange}
                />
              </div>
            )}
          </div>
        </>
      )}

      <CustomButton
        className={`text-center text-lg text-white flex justify-center items-center bg-black w-full py-2 rounded-full`}
        loading={searchLoading}
        onClick={() => {
          if (mobileSearchOpen) {
            handleSearch();
          } else {
            toggleMobileSearchOpen();
          }
        }}
      >
        Search
      </CustomButton>

      <div className="h-[0.5px] w-[90%] bg-black my-2 mx-5"></div>

      <CustomButton
        className={`border border-gray-600 text-lg text-black rounded-full w-full py-2`}
        onClick={() => {
          navigate("/car/list-vehicle", { state: 1 });
        }}
      >
        List your car
      </CustomButton>
    </div>
  );
};

export default MobileSearchListCar;
