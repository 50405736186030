import React, { useState } from "react";
import { FiPlus, FiTruck } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { getRoutesList } from "./NavigationButtons";
import { useDataContext } from "../../providers/DataProvider";
import { IoCarOutline } from "react-icons/io5";
import { RiSteering2Fill } from "react-icons/ri";

interface UserAssetsProps {
  navigate: (path: string, state?: any) => void;
}

const UserAssets: React.FC<UserAssetsProps> = ({ navigate }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { listingData } = useDataContext();
  const [isOpen, setIsOpen] = useState(false);

  const parts = getRoutesList(currentPath);

  return (
    <div
      className={`w-full lg:w-[550px] ${
        !parts.includes("Wallet") ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center w-[100%]">
        <div className="md:border-black md:border-1 md:border sm:rounded-3xl flex-wrap md:px-6 py-6 flex items-start w-[100%] justify-between gap-6 bg-white">
          <div>
            <p className="text-[12px] font-light">Assets listed</p>
            <p className="text-[18px] -mt-1">{listingData.length}</p>
          </div>

          {!isOpen && (
            <div
              className="lg:flex cursor-pointer border rounded-full px-5 text-[12px] md:text-[14px] py-2 bg-[#0E1427] text-white lg:mt-0 flex justify-center items-center gap-3"
              onClick={() => setIsOpen(true)}
            >
              <span>
                <FiPlus />
              </span>

              <p>Add asset</p>
            </div>
          )}

          {isOpen && (
            <div className="py-4 px-6 border border-black rounded-3xl flex flex-col justify-start items-center gap-4">
              <div
                className="lg:flex cursor-pointer rounded-full flex justify-center items-center gap-4 text-gray-400"
                onClick={() => setIsOpen(false)}
              >
                <span>
                  <FiPlus className="h-6 w-6" />
                </span>

                <p>Add asset</p>
              </div>

              <div
                className="w-full flex justify-start items-center gap-4 cursor-pointer"
                onClick={() => navigate("/car/list-vehicle", { state: 1 })}
              >
                <IoCarOutline className="h-6 w-6" />

                <span>Car</span>
              </div>

              {/* <div className="w-full flex justify-start items-center gap-4 cursor-pointer">
                <FiTruck className="h-6 w-6" />

                <span>Truck</span>
              </div>

              <div className="w-full flex justify-start items-center gap-4 cursor-pointer">
                <RiSteering2Fill className="h-6 w-6" />

                <span>Driver</span>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserAssets;
