import { useEffect, useMemo, useState } from "react";
import { User } from "../utils/types";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const useData = () => {
  const [userData, setUserData] = useState<User | null>(null);

  useEffect(() => {
    const userDataString = localStorage.getItem("UserData");

    if (userDataString !== null) {
      try {
        // Attempt to parse the string back to an object
        const userData = JSON.parse(userDataString);

        // Log the parsed object
        setUserData(userData);
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    } else {
      // console.log("No user data found in localStorage");
    }
  }, []);

  return { userData };
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 640);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

export const useMediaQuery = (query: any) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
};

export const useCheckCurrentCarHost = ({
  hostId,
}: {
  hostId: string | undefined;
}) => {
  const [hostData, setHostData] = useState<{
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  }>({ _id: "", firstName: "", lastName: "", email: "", phoneNumber: "" });

  const { userData } = useData();

  useEffect(() => {
    const getHostInfo = async () => {
      if (!hostId) {
        return;
      }
      try {
        const result = await axios.get(
          `${BASE_URL}/booking/getHostDetails/${hostId}`
        );

        if (result?.data?.isSuccessful) {
          setHostData(result?.data?.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (hostId) {
      getHostInfo();
    }
  }, [hostId]);

  const isCurrentCarHost = useMemo(
    () => hostData?._id === userData?.userId,
    [hostData?._id, userData?.userId]
  );

  return { isCurrentCarHost, hostData };
};
