import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../utils/stringFunctions";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Box, Slider } from "@mui/material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { updateParams } from "../../utils/utilsFunctions";
import { useDataContext } from "../../providers/DataProvider";

const MIN_PRICE = 0;
const MAX_PRICE = 1_000_000;
const BASE_URL = process.env.REACT_APP_BASE_URL;

interface CarRentalFilterProps {
  setShowMobileFilter: any;
  displayNumber: number;
}

const CarRentalFilter: React.FC<CarRentalFilterProps> = ({
  setShowMobileFilter,
  displayNumber,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [priceClick, setPriceClick] = useState(true);
  const [categoryClick, setCategoryClick] = useState(true);
  const [modelYearClick, setModelYearClick] = useState(true);
  const [vehicleData, setVehicleData] = useState<
    {
      id: number;
      index: number;
      name: string;
      imageUrl: string;
    }[]
  >([]);
  const {
    serviceType,
    fromWhereSelected,
    finalDestinationSelected,
    pickupTime,
    returnTime,
  } = useDataContext();
  const navigate = useNavigate();
  const currentVehicleTypes = queryParams.get("vehicleType")?.split(",") || [];
  const isNegotiable = queryParams.get("negotiable");
  const isSelfDrive = queryParams.get("selfDrive");
  const minPrice = Number(queryParams.get("min"));
  const maxPrice = Number(queryParams.get("max")) || MAX_PRICE;
  const carYear = queryParams.get("year");
  const params = updateParams({
    serviceType,
    fromWhereSelected,
    finalDestinationSelected,
    pickupTime,
    returnTime,
    queryParams,
  });

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(`${BASE_URL}/general/vehicleTypes`);

      setVehicleData(response.data.data);
    };
    getData();
  }, []);

  const years = [
    { key: 0, value: 2015 },
    { key: 1, value: 2016 },
    { key: 2, value: 2017 },
    { key: 3, value: 2018 },
    { key: 4, value: 2019 },
    { key: 5, value: 2020 },
    { key: 6, value: 2021 },
    { key: 7, value: 2022 },
    { key: 8, value: 2023 },
    { key: 9, value: 2024 },
  ];

  const updateQueryParams = (key: string, value: string | boolean | null) => {
    if (value === null || value === false || value === "") {
      params.delete(key);
    } else {
      params.set(key, value.toString());
    }

    navigate(`/car?${params.toString()}`);
  };

  const handleVehicleCategoryClick = (vehicleType: string) => {
    const currentVehicleTypes =
      queryParams.get("vehicleType")?.split(",") || [];
    const index = currentVehicleTypes.indexOf(vehicleType);

    if (index === -1) {
      currentVehicleTypes.push(vehicleType);
    } else {
      currentVehicleTypes.splice(index, 1);
    }

    updateQueryParams(
      "vehicleType",
      currentVehicleTypes.length ? currentVehicleTypes.join(",") : null
    );
  };

  const handleNegotiableClick = () => {
    updateQueryParams("negotiable", isNegotiable ? null : "true");
  };

  const handleSelfDriveClick = () => {
    updateQueryParams("selfDrive", isSelfDrive ? null : "true");
  };

  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    let value: number[];
    if (Array.isArray(newValue)) {
      value = newValue;
      updateQueryParams(
        "min",
        value[0] === MIN_PRICE ? null : value[0].toString()
      );
      updateQueryParams(
        "max",
        value[1] === MAX_PRICE ? null : value[1].toString()
      );
    }
  };

  const handleYearChange = (e: any) => {
    const value = e.target.value;
    updateQueryParams("year", value);
  };

  return (
    <div className="w-full">
      <div className="py-6 px-5">
        <div
          className=" flex justify-between items-center cursor-pointer"
          onClick={() => setPriceClick((prev) => !prev)}
        >
          <h2 className="font-medium w-full">Price</h2>

          <div>{priceClick ? <FaChevronUp /> : <FaChevronDown />}</div>
        </div>

        {priceClick && (
          <>
            <div className="relative">
              <Box sx={{ width: "100%", px: 1 }}>
                <Slider
                  value={[minPrice, maxPrice]}
                  min={0}
                  max={1000000}
                  onChange={handlePriceChange}
                  valueLabelDisplay="auto"
                  sx={{
                    color: "#0AA7F0",
                    "& .MuiSlider-thumb": {
                      border: "3px solid white",
                      width: 17,
                      height: 17,
                      boxShadow: 1,
                    },
                  }}
                />
              </Box>

              <div className=""></div>

              <div className="flex justify-between items-center mt-1 w-full font-light text-sm">
                <p>{formatCurrency(minPrice, true)}</p>
                <p>{formatCurrency(maxPrice, true)}</p>
              </div>
            </div>

            <div className="mt-5 flex flex-col justify-start items-start gap-3">
              <div
                className="flex items-center  gap-3 text-[13px] font-light"
                // key={i}
              >
                <input
                  type="checkbox"
                  name="negotiable"
                  checked={!!isNegotiable}
                  onChange={handleNegotiableClick}
                  className="cursor-pointer"
                />
                <label>Negotiable</label>
              </div>

              <div
                className="flex items-center gap-3 text-[13px] font-light"
                // key={i}
              >
                <input
                  type="checkbox"
                  name="selfDrive"
                  checked={!!isSelfDrive}
                  onChange={handleSelfDriveClick}
                  className="cursor-pointer"
                />
                <label>Self Drive</label>
              </div>
            </div>
          </>
        )}
      </div>

      <hr className="border-2 border-white"></hr>

      {vehicleData && (
        <div className=" border-gray-300 rounded-lg pt-6 pb-3 px-5">
          <div
            className=" flex justify-between items-center cursor-pointer"
            onClick={() => setCategoryClick((prev) => !prev)}
          >
            <h2 className="font-medium w-full mb-4">Vehicle features</h2>

            <div>{categoryClick ? <FaChevronUp /> : <FaChevronDown />}</div>
          </div>

          {categoryClick &&
            vehicleData.map((cat: any, i: any) => (
              <div
                className="flex items-center mb-4 gap-3 text-[13px] font-light"
                key={i}
              >
                <input
                  type="checkbox"
                  name={cat.name}
                  title="name"
                  checked={currentVehicleTypes.includes(cat.name)}
                  className="cursor-pointer"
                  onChange={() => handleVehicleCategoryClick(cat.name)}
                />
                <label htmlFor={cat.name}>{cat.name}</label>
              </div>
            ))}
        </div>
      )}

      <hr className="border-2 border-white"></hr>

      <div className="py-6 px-5">
        <div className=" border-gray-300 rounded-lg">
          <div
            className=" flex justify-between items-center cursor-pointer"
            onClick={() => setModelYearClick((prev) => !prev)}
          >
            <h2 className="font-medium w-full pb-2"> Model year</h2>

            <div>{modelYearClick ? <FaChevronUp /> : <FaChevronDown />}</div>
          </div>
          {modelYearClick && (
            <>
              <select
                name="year"
                value={carYear || ""}
                className=" w-full max-w-[70%] border rounded py-2 px-2 text-sm"
                onChange={(e) => handleYearChange(e)}
              >
                <option value="">Select</option>
                {years?.map((val: any) => (
                  <option key={val?.key} value={val?.value}>
                    {val?.value}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      </div>

      <div className="flex lg:hidden justify-center items-center w-full gap-4 bg-white py-6 px-5">
        <div className="py-2 w-[50%] rounded-full border border-1 border-[#0E1427] text-center text-[14px] cursor-pointer">
          Reset filters
        </div>
        <div
          className="py-2 w-[50%] rounded-full bg-[#0E1427] text-center text-white text-[14px] cursor-pointer"
          onClick={() => {
            setShowMobileFilter(false);
          }}
        >
          {`Show cars (${displayNumber})`}
        </div>
      </div>
    </div>
  );
};

export default CarRentalFilter;
