import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Bentley from "../../assets/images/bentley_continental wallpaper.jpg";
import { FaCarSide } from "react-icons/fa";
import { GrStatusGood } from "react-icons/gr";
import { LiaPaperPlane } from "react-icons/lia";
import MobileApp from "../../components/mobileapp/MobileApp";
import Newsletter from "../../components/newsletter/Newsletter";
import TopOffer from "../../components/top-offers/Topoffer";
import Driver from "../../assets/images/driverhome.jpg";
import Taxi from "../../assets/images/boning.jpeg";
import Cab from "../../assets/images/smilingdriver.jpg";

const data = [
  { id: 1, value: "truck1" },
  { id: 2, value: "truck2" },
  { id: 3, value: "truck3" },
];

const DriversPage = () => {
  const [type, setType] = useState<String>(" ");
  const [fromWhere, setFromWhere] = useState<string>("");
  const [finalDestination, setFinalDestination] = useState<string>("");
  const [serviceType, setServiceType] = useState<string>("");
  const [pickupTime, setPickupTime] = useState<string>("");

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value);
  };

  const handleFromWhereChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFromWhere(e.target.value);
  };

  const handleFinalDestinationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFinalDestination(e.target.value);
  };

  const handleServiceTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setServiceType(e.target.value);
  };

  const handlePickupTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPickupTime(e.target.value);
  };

  return (
    <div>
      <div className="mx-auto">
        <div
          className="relative mt-18 h-screen flex flex-col justify-end pb-14 w-full items-center"
          style={{
            backgroundImage: `url(${Driver})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          {/* <img src={Bentley} alt="homepage bg" className="" /> */}
          <div className="flex flex-col gap-4 font-semibold items-center justify-center order-4  w-full  ">
            <h1 className=" text-4xl text-white text-left font-semibold bottom-[50%] top-[50%] w-full max-w-[1280px]">
              Welcome to DMM Marketplace
            </h1>

            {/* ..............................services menu............................ */}
            <div className="  w-full overflow-x-auto overflow-y-hidden max-w-[1180px] min-w-[300px]">
              <div className=" ">
                <ul className="flex  font-normal md:text-lg md:font-normal items-center justify-around   border-blue-600  ">
                  <li className=" ">
                    <Link
                      className={
                        "block py-2 px-8 text-white rounded-full   hover:text-black hover:bg-[#efcb76] opacity-90  font-normal text-center"
                      }
                      to={"/car"}
                    >
                      Car Rental
                    </Link>
                  </li>
                  <li className=" ">
                    <Link
                      className={
                        "block py-2 px-8 text-white rounded-full   hover:text-black hover:bg-[#efcb76]  font-normal text-center"
                      }
                      to={"/jet"}
                    >
                      Private Jet
                    </Link>
                  </li>
                  <li className=" ">
                    <Link
                      className={
                        "block py-2 px-8 text-white rounded-full   hover:text-black hover:bg-[#efcb76]  font-normal text-center"
                      }
                      to={"/boat"}
                    >
                      Boat Charter
                      <div className=" text-black " />
                    </Link>
                  </li>
                  <li className=" ">
                    <Link
                      className={
                        "block py-2 px-8 text-white rounded-full   hover:text-black hover:bg-[#efcb76]  font-normal text-center"
                      }
                      to={"/driver"}
                    >
                      Hire Drivers
                      <div className=" text-black " />
                    </Link>
                  </li>
                  <li className=" ">
                    <Link
                      className={
                        "block py-2 px-8 text-white rounded-full   hover:text-black hover:bg-[#efcb76]  font-normal text-center"
                      }
                      to={"/helicopter"}
                    >
                      Helicopter Charter
                      <div className=" text-black " />
                    </Link>
                  </li>
                  <li className=" ">
                    <Link
                      className={
                        " block py-2 px-8 text-white rounded-full   hover:text-black hover:bg-[#efcb76]  font-normal text-center"
                      }
                      to={"/truck"}
                    >
                      Truck Hire
                      <div className=" text-black " />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* ..............................input fields............................ */}

            <div className="hidden md:flex bg-white items-center justify-between rounded-full p-1 py-1 gap-4 w-full max-w-[1080px]">
              <div className="bg-zinc-100 rounded-full p-1 px-2 flex flex-col gap-1 justify-center items-center text-center flex-1">
                <p className="text-xs font-light">From where?</p>
                <input
                  type="text"
                  placeholder="Enter Address"
                  className="bg-transparent rounded-full text-center text-xs w-full h-7"
                  value={fromWhere}
                  onChange={handleFromWhereChange}
                />
              </div>

              <div className="p-2 px-0 flex flex-col justify-center items-center text-center flex-1">
                <p className="text-xs font-light">Final destination?</p>
                <input
                  type="text"
                  placeholder="Enter Address"
                  className="bg-transparent rounded-full text-center text-xs h-7 w-full"
                  value={finalDestination}
                  onChange={handleFinalDestinationChange}
                />
              </div>

              <div className="flex flex-col w-fit flex-1 items-center justify-center border-l-2 pl-2 border-r-2">
                <p className="text-xs font-light">Service Type</p>
                <select
                  title="service"
                  className="font-light"
                  value={serviceType}
                  onChange={handleServiceTypeChange}
                >
                  <option value="">Select</option>
                  {data?.map((items, i) => (
                    <option key={i} value={items?.value}>
                      {items?.value}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-fit pl-2 flex-1 flex flex-col justify-center">
                <p className="text-xs font-light">Pick-up & time</p>
                <input
                  title="date"
                  className="font-light"
                  type="date"
                  value={pickupTime}
                  onChange={handlePickupTimeChange}
                />
              </div>

              <div className="">
                <p className="text-xs">Transmission</p>
                <select title="transmission" name="" id="">
                  <option value="">Select </option>
                  {data?.map((items, i) => (
                    <option value="">{items?.value} </option>
                  ))}
                </select>
              </div>
              <div className="">
                <p className="text-xs">Vehicle Brand</p>
                <select
                  name=""
                  title="vehicle"
                  id=""
                  onChange={(e) => {
                    handleTypeChange(e);
                  }}
                >
                  <option value="">Select </option>
                  {data?.map((items, i) => (
                    <option value="">{items?.value} </option>
                  ))}
                </select>
              </div>

              <button
                className="rounded-full text-white bg-[#0e1427] px-16 py-4 text-lg font-light"
                // onClick={fetchData}
              >
                Request
              </button>
            </div>
          </div>
        </div>

        <div className="bg-[#f4f4f4] flex justify-center  items-center p-5 mb-10">
          <div className="flex justify-between mt-5 mb-5 space-x-20">
            <div className="flex flex-col items-center justify-center">
              <FaCarSide className="text-[#efcb76]  h-10 w-10" />
              <h2 className="text-lg font-semibold text-center">
                Need a Driver on-Demand?
              </h2>
              <p className="text-sm font-light text-center">
                Get driven by professionals whenever<br></br> required. we have
                the best deal<br></br> from our carefully vetted VIP drivers.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <LiaPaperPlane className="text-[#efcb76] text-center h-10 w-10" />
              <h2 className="text-lg font-semibold text-center">Book on DMM</h2>
              <p className="text-sm font-light text-center">
                Hire from DMM for peace of mind and<br></br> a guaranteed safe
                drive! Defer your hire<br></br> for free 24 hours before your
                trip
              </p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <GrStatusGood className="text-[#efcb76] h-10 w-10" />
              <h2 className="text-lg font-semibold text-center">
                You're Done!
              </h2>
              <p className="text-sm font-light text-center">
                You're ready to have a fantastic trip.<br></br>We go where you
                go.
              </p>
            </div>
          </div>
        </div>

        <div className="">
          <div className=" flex max-w-[1180px] h-56 mb-10 mx-auto p-5">
            <div className="relative w-1/2">
              <img
                src={Taxi}
                alt=""
                className="absolute w-full h-full rounded-l-3xl"
              />
              <div className="absolute bg-gradient-to-l from-[#0e1427] h-full w-full"></div>
            </div>
            <div className="bg-[#0e1427] text-white w-1/2 rounded-r-3xl p-6">
              <h3 className="text-lg mt-2">Recruit Drivers</h3>
              <p className="text-sm mt-2">
                Get driven in ease and with rest of mind, recruit professional
                <br></br> and well vetted drivers you can trust.
              </p>
              <button className="rounded-full text-black bg-[#efcb76] px-10 py-2 mt-4 mb-4">
                RECRUIT
              </button>
            </div>
          </div>
        </div>

        <div className="bg-[#f4f4f4] p-4">
          <div className=" flex max-w-[1180px] h-56 mb- mx-auto p-5">
            <div className="relative w-1/2">
              <img
                src={Cab}
                alt=""
                className="absolute w-full h-full rounded-l-3xl"
              />
              <div className="absolute bg-gradient-to-l from-[#0e1427] h-full w-full"></div>
            </div>
            <div className="bg-[#0e1427] text-white w-1/2 rounded-r-3xl p-6">
              <h3 className="text-lg mt-2">Become a Driver</h3>
              <p className="text-sm mt-2">
                Are you an expert driver with years of experience and wish to Be
                one
                <br></br>of our drivers? Sign up to be a part of our trusted
                team.
              </p>
              <button className="rounded-full text-black bg-[#efcb76] px-10 py-2 mt-4 mb-4">
                SIGN UP
              </button>
            </div>
          </div>
        </div>

        {/* <div className="relative w- h-40 mb-10 mx-10 max-w-[1180px] mx-auto">
          <img
            src={Altis}
            alt="altis"
            className="object-cover w-full h-full rounded-3xl"
          />
          <div className="absolute text-white top-[30px] left-[70px]">
            <h2 className="text-lg font-medium">List your car</h2>
            <p className="text-sm font-extralight">
              Earn big on your car! Sublease your car and earn while you
              <br></br>rest. We manage and run it on your behalf
            </p>
            <Button className="rounded-full text-black bg-[#efcb76] px-10 py-2 mt-4 mb-4">
              POST
            </Button>
          </div>
        </div> */}

        <MobileApp />
        <Newsletter />
        <TopOffer />
      </div>
    </div>
  );
};

export default DriversPage;
