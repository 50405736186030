import axios from "axios";
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  ChangeEvent,
} from "react";
import { getDate } from "../utils/getDate";
import { SelectChangeEvent } from "@mui/material/Select";
import { useData } from "../Hooks/useData";
import { useInView } from "react-intersection-observer";
import { serviceTypes } from "../utils/constants";
import dayjs, { Dayjs } from "dayjs";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const FETCH_LIMIT = 20;
const DEFAULT_SERVICE = "Full Day";
const VAT_RATE = 0.075;

export interface MenuItem {
  _id: string;
  name: string;
}

export type Location = {
  address: string;
  lng: number;
  lat: number;
};

type ListingDetails = {
  data: any;
  showDetails: boolean;
};

type SearchData = {
  numberOfItems: number;
  data: any[];
};

interface DataContextType {
  menuItems: MenuItem[];
  setMenuItems: React.Dispatch<React.SetStateAction<MenuItem[]>>;
  selectedItem: number;
  setSelectedItem: React.Dispatch<React.SetStateAction<number>>;
  fromWhereInputValue: string;
  setFromWhereInputValue: React.Dispatch<React.SetStateAction<string>>;
  fromWhereSelected: Location | any;
  setFromWhereSelected: React.Dispatch<React.SetStateAction<Location | any>>;
  fromWhere: Location | any;
  setFromWhere: React.Dispatch<React.SetStateAction<Location | any>>;
  handleFromWhereSelected: (val: string) => void;
  updateFromWhereInputValue: (newValue: string) => void;
  finalDestinationInputValue: string;
  setFinalDestinationInputValue: React.Dispatch<React.SetStateAction<string>>;
  finalDestination: Location | any;
  setFinalDestination: React.Dispatch<React.SetStateAction<Location | any>>;
  finalDestinationSelected: Location | any;
  setFinalDestinationSelected: React.Dispatch<
    React.SetStateAction<Location | any>
  >;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  listingDetails: ListingDetails;
  setListingDetails: React.Dispatch<React.SetStateAction<ListingDetails>>;
  handleFinalDestinationSelected: (val: string) => void;
  updateFinalDestinationInputValue: (newValue: string) => void;
  today: string;
  pickupTime: string;
  setPickupTime: React.Dispatch<React.SetStateAction<string>>;
  returnTime: string;
  setReturnTime: React.Dispatch<React.SetStateAction<string>>;
  returnType: boolean;
  setReturnType: React.Dispatch<React.SetStateAction<boolean>>;
  handlePickupTimeChange: (value: any) => void;
  handleReturnTimeChange: (value: any) => void;
  handleFocus: any;
  handleBlur: any;
  placeholderDates: any;
  serviceType: any;
  setServiceType: React.Dispatch<React.SetStateAction<any>>;
  serviceTypeData: any[];
  items: any[];
  topDeals: any;
  handleServiceTypeChange: (event: SelectChangeEvent<string>) => void;
  listingData: any[];
  setListingData: React.Dispatch<React.SetStateAction<any[]>>;
  searchData: SearchData;
  setSearchData: React.Dispatch<React.SetStateAction<SearchData>>;
  handleStatusChange: (id: string) => void;
  fetchWalletData: () => void;
  loading: boolean;
  ref: any;
  fetchData: any;
  hasMore: boolean;
  VAT_RATE: number;
}

const initialContext: DataContextType = {
  menuItems: [],
  setMenuItems: () => {},
  selectedItem: 0,
  setSelectedItem: () => {},
  fromWhereInputValue: "",
  setFromWhereInputValue: () => {},
  fromWhereSelected: "",
  setFromWhereSelected: () => {},
  fromWhere: "",
  setFromWhere: () => {},
  handleFromWhereSelected: () => {},
  updateFromWhereInputValue: () => {},
  finalDestinationInputValue: "",
  setFinalDestinationInputValue: () => {},
  finalDestination: "",
  setFinalDestination: () => {},
  listingDetails: { data: null, showDetails: false },
  setListingDetails: () => {},
  activeTab: "",
  setActiveTab: () => {},
  finalDestinationSelected: null,
  setFinalDestinationSelected: () => {},
  handleFinalDestinationSelected: () => {},
  updateFinalDestinationInputValue: () => {},
  today: new Date().toISOString().slice(0, 16),
  pickupTime: "",
  setPickupTime: () => {},
  returnTime: "",
  setReturnTime: () => {},
  returnType: true,
  setReturnType: () => {},
  handlePickupTimeChange: () => {},
  handleReturnTimeChange: () => {},
  handleFocus: () => {},
  handleBlur: () => {},
  placeholderDates: "",
  serviceType: "",
  setServiceType: () => {},
  serviceTypeData: [],
  items: [],
  topDeals: null,
  handleServiceTypeChange: () => {},
  listingData: [],
  setListingData: () => {},
  searchData: { numberOfItems: 0, data: [] },
  setSearchData: () => {},
  handleStatusChange: () => {},
  fetchWalletData: () => {},
  loading: false,
  ref: null,
  fetchData: () => {},
  hasMore: true,
  VAT_RATE,
};

const DataContext = createContext<DataContextType>(initialContext);

export const DataProvider = ({ children }: { children: ReactNode }) => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [fromWhereInputValue, setFromWhereInputValue] = useState<string>("");
  const [fromWhereSelected, setFromWhereSelected] = useState<Location | any>(
    ""
  );
  const [fromWhere, setFromWhere] = useState<Location | any>("");
  const [finalDestinationInputValue, setFinalDestinationInputValue] =
    useState<string>("");
  const [finalDestination, setFinalDestination] = useState<Location | any>("");
  const [finalDestinationSelected, setFinalDestinationSelected] = useState<
    Location | any
  >("");
  const [pickupTime, setPickupTime] = useState<string>(getDate().start);
  const [returnTime, setReturnTime] = useState<string>("");
  const [returnType, setReturnType] = useState<boolean>(true);
  const [serviceType, setServiceType] = useState<any>(DEFAULT_SERVICE);
  const [serviceTypeData, setServiceTypeData] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [topDeals, setTopDeals] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<string>("");
  const [listingDetails, setListingDetails] = useState<ListingDetails>({
    data: null,
    showDetails: false,
  });
  const [loading, setLoading] = useState(false);
  const [listingData, setListingData] = useState<any>([]);
  const [searchData, setSearchData] = useState<SearchData>({
    numberOfItems: 0,
    data: [],
  });
  const { userData } = useData();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const { ref, inView } = useInView({
    threshold: 1,
  });

  const handlePickupTimeChange = (value: Dayjs | null | string) => {
    if (typeof value === "string") {
      const parsedValue = dayjs(value);

      if (parsedValue.isValid()) {
        setPickupTime(parsedValue.toISOString());
      }
    } else if (value && value.isValid()) {
      setPickupTime(value.toISOString());
    }
  };

  const handleReturnTimeChange = (value: Dayjs | null | string) => {
    if (typeof value === "string") {
      const parsedValue = dayjs(value);

      if (parsedValue.isValid()) {
        setReturnTime(parsedValue.toISOString());
      }
    } else if (value && value.isValid()) {
      setReturnTime(value.toISOString());
    }
  };

  const handleFocus = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.type = "datetime-local";
  };

  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      event.target.type = "text";
    }
  };

  const handleFromWhereSelected = (val: any): void => {
    setFromWhereSelected(val);

    setFromWhere(val);
  };

  const updateFromWhereInputValue = (newValue: string): void => {
    setFromWhereInputValue(newValue);
  };

  const handleFinalDestinationSelected = (val: string): void => {
    setFinalDestinationSelected(val);
    setFinalDestination(val);
  };

  const updateFinalDestinationInputValue = (newValue: string): void => {
    setFinalDestinationInputValue(newValue);
  };

  const handleServiceTypeChange = (event: SelectChangeEvent<string>) => {
    setServiceType(event.target.value);
  };

  const today = new Date().toISOString().slice(0, 16);

  const placeholderDates = getDate();

  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const { data } = await axios.get(`${BASE_URL}/menu/getMenus`);
        setMenuItems(data?.data);
      } catch (error) {
        console.log("error fetching menus --> ", error);
      }
    };

    const fetchCarDetails = async () => {
      try {
        const { data } = await axios.get(`${BASE_URL}/host/topDealToday`);
        setTopDeals(data.data);
        setItems(data.data);
      } catch (error) {
        console.log("error fetching car details --> ", error);
      }
    };

    const fetchServiceType = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/general/getServiceTypes`);

        setServiceTypeData(response.data.data);
      } catch (error) {
        console.log("error fetching service types --> ", error);
      }
    };

    fetchServiceType();
    fetchMenus();
    fetchCarDetails();
  }, []);

  useEffect(() => {
    const isReturnedType = () => {
      const foundServiceType = serviceTypes.find(
        (service) => service.name === serviceType
      );

      if (foundServiceType) {
        return foundServiceType.isReturned;
      }

      return false;
    };

    setReturnType(isReturnedType);
  }, [serviceType, serviceTypeData]);

  useEffect(() => {
    returnType ? setReturnTime(getDate().end) : setReturnTime("");
  }, [returnType]);

  const handleStatusChange = async (cardId?: any) => {
    try {
      await axios.put(
        `${BASE_URL}/host/toggleCarStatus/${cardId}/${userData?.userId}`
      );

      fetchWalletData();
    } catch (error) {
      console.log("error fetching account balance data --> ", error);
    }
  };

  const fetchWalletData = async () => {
    try {
      if (userData?.userId) {
        const { data } = await axios.get(
          `${BASE_URL}/host/getHostCars/${userData?.userId}`
        );

        setListingData(data?.data);
      }
    } catch (error) {
      console.log("error fetching account balance data --> ", error);
    }
  };

  useEffect(() => {
    if (hasMore && !loading) {
      fetchMoreData(page);
    }
  }, [page, hasMore]);

  useEffect(() => {
    if (inView && !loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore, inView, loading]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchMoreData = async (page: number) => {
    setLoading(true);

    const params = getQueryParams();

    try {
      const { data } = await axios.get(`${BASE_URL}/host/searchCarsByLongLat`, {
        params: {
          page,
          limit: FETCH_LIMIT,
          ...params,
        },
      });

      const { totalCount, carDetailsResults: newItems } = data.data;

      if (newItems.length < FETCH_LIMIT) {
        setHasMore(false);
      }

      setSearchData((prevItems: SearchData) =>
        !!prevItems?.data?.length
          ? {
              ...prevItems,
              data: [...prevItems.data, ...newItems],
            }
          : { numberOfItems: totalCount, data: newItems }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setPage(1);
    setHasMore(true);

    const params = getQueryParams();

    try {
      const { data } = await axios.get(`${BASE_URL}/host/searchCarsByLongLat`, {
        params: {
          page: 1,
          limit: FETCH_LIMIT,
          ...params,
        },
      });

      const { totalCount, carDetailsResults } = data.data;

      setSearchData({ numberOfItems: totalCount, data: carDetailsResults });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getQueryParams = () => {
    const returnTime_ = new Date(returnTime).getTime();
    const pickupTime_ = new Date(pickupTime).getTime();

    const { lng: longitude, lat: latitude } = fromWhereSelected || {};

    return {
      longitude: longitude ? String(longitude) : "",
      latitude: latitude ? String(latitude) : "",
      serviceTypeName: serviceType || "",
      pickUpTime: pickupTime_ ? String(pickupTime_) : "",
      returnTime: returnTime_ ? String(returnTime_) : "",
    };
  };

  return (
    <DataContext.Provider
      value={{
        menuItems,
        setMenuItems,
        selectedItem,
        setSelectedItem,
        fromWhereInputValue,
        setFromWhereInputValue,
        fromWhereSelected,
        setFromWhereSelected,
        fromWhere,
        setFromWhere,
        handleFromWhereSelected,
        updateFromWhereInputValue,
        finalDestinationInputValue,
        setFinalDestinationInputValue,
        finalDestination,
        setFinalDestination,
        activeTab,
        setActiveTab,
        listingDetails,
        setListingDetails,
        finalDestinationSelected,
        setFinalDestinationSelected,
        handleFinalDestinationSelected,
        updateFinalDestinationInputValue,
        today,
        pickupTime,
        setPickupTime,
        returnTime,
        setReturnTime,
        returnType,
        setReturnType,
        handlePickupTimeChange,
        handleReturnTimeChange,
        handleFocus,
        handleBlur,
        placeholderDates,
        serviceType,
        setServiceType,
        serviceTypeData,
        items,
        topDeals,
        handleServiceTypeChange,
        listingData,
        setListingData,
        searchData,
        setSearchData,
        handleStatusChange,
        fetchWalletData,
        ref,
        loading,
        fetchData,
        hasMore,
        VAT_RATE,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
