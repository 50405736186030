import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { formatCurrency } from "../../utils/stringFunctions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../context/authContext";
import CustomButton from "../../components/elements/CustomButton";
import { REACT_TOAST_CONFIG, getRemark } from "../../utils/utilsFunctions";

import SearchBox from "../../components/searchbox/SearchBox";
import { useDataContext } from "../../providers/DataProvider";
import DmmDetails from "../../components/details/DmmDetails";
import { MdCancel, MdCheckCircle, MdKeyboardBackspace } from "react-icons/md";
import CarDetailsRating from "../../components/rating/CarDetailsRating";
import DmmDialog from "../../components/modal/DmmDialog";
import { CarDetailsProps, initialCarState } from "../../utils/carDetailsProps";
import { NumericFormat } from "react-number-format";
import { TextField } from "@mui/material";
import { removeNairaAndConvertToInt } from "../../utils/RemoveNairaAndConvertToInt";
import { DmmSpinner } from "../../components/Spinner";
import { useIsMobile } from "../../Hooks/useData";
import useGetReviews from "../../Hooks/useGetReviews";
import EmptyData from "../../assets/icons/EmptyData";
import { formatDate } from "../car-rental/CarRentalBooking";
import { serviceTypesWithQuoteRequired } from "../../utils/constants";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const CarDetails = () => {
  const navigate = useNavigate();
  const [makeOfferModal, setMakeOfferModal] = useState(false);
  const [price, setPrice] = useState(0);
  const [bookLoading, setBookLoading] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);
  const [insufficientFundModal, setInsufficientFundModal] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState<number>(1);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [offeredPrice, setOfferedPrice] = useState<string>("");
  const [balance, setBalance] = useState<number>(0);
  const [vehicleServiceTypeId, setVehicleServiceTypeId] = useState<any>("");
  const [bookingModal, setBookingModal] = useState(false);
  const { state, dispatch } = useAuth();
  const location = useLocation();
  const { carCode } = useParams();
  const [carDetails, setCarDetails] =
    useState<CarDetailsProps>(initialCarState);
  const carData =
    location?.state?.data ?? location?.state?.data?.data ?? location?.state;
  const bottomButtonRef = useRef<HTMLButtonElement>(null);
  const [showFullDetails, setShowFullDetails] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [open, setOpen] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  const { reviews } = useGetReviews(carDetails?._id);

  const {
    fromWhereSelected,
    finalDestinationSelected,
    returnTime,
    pickupTime,
    returnType,
    serviceType,
    VAT_RATE,
  } = useDataContext();

  const handleToggleDetails = () => {
    setShowFullDetails((prev) => !prev);
  };

  useEffect(() => {
    if (textRef.current) {
      setShowButton(
        textRef.current.scrollHeight > textRef.current.clientHeight
      );
    }
  }, [carDetails?.carNotes]);

  const showDestinationBox =
    serviceType.includes("Airport Pickup") ||
    serviceType.includes("Airport Dropoff") ||
    serviceType.includes("Evening Hangout");

  let data = carData;
  const isMobile = useIsMobile();

  const calculateDays = () => {
    const returnDate = new Date(returnTime);
    const pickupDate = new Date(pickupTime);

    const timeDifference = returnDate.getTime() - pickupDate.getTime();

    const daysDifference = timeDifference / (1000 * 3600 * 24);

    const days =
      !isNaN(daysDifference) && daysDifference > 0.1
        ? Math.round(daysDifference)
        : 1;

    setNumberOfDays(days);
    handlePriceChange(days);

    return days;
  };

  const HandleMakeOfferModal = () => {
    if (state?.userId) {
      if (validForm(true)) {
        setMakeOfferModal(true);
      }
    } else {
      dispatch({
        type: "TOGGLELOGIN",
      });
    }
  };

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        const { data } = await axios.get(
          `${BASE_URL}/host/getCarByCarCode/${carCode}`
        );

        setCarDetails(data.data);
      } catch (error) {
        console.error("Error fetching car details:", error);
      }
    };

    if (carCode) {
      fetchCarDetails();
    }
  }, [carCode]);

  const fetchData = async () => {
    const returnTime_ = new Date(returnTime).getTime();

    const pickupTime_ = new Date(pickupTime).getTime();

    try {
      const { lng: longitude, lat: latitude } = fromWhereSelected || {};

      const params = {
        longitude: longitude ? String(longitude) : "",
        latitude: latitude ? String(latitude) : "",
        serviceTypeName: serviceType || "",
        pickUpTime: !isNaN(pickupTime_) ? String(pickupTime_) : "",
        returnTime: !isNaN(returnTime_) ? String(returnTime_) : "",
      };

      const { data } = await axios.get(`${BASE_URL}/host/searchCarsByLongLat`, {
        params: {
          ...params,
        },
      });

      if (data?.data) {
        setOpen(
          data?.data?.carDetailsResults?.filter(
            (val: any) => val._id === carDetails._id
          ).length <= 0
        );
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (carDetails && fromWhereSelected) {
        fetchData();
      }
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromWhereSelected, carDetails]);

  useEffect(() => {
    const fetchServiceType = async () => {
      await axios.get(`${BASE_URL}/general/getServiceTypes`);
    };

    fetchServiceType();
  }, []);

  const HandleMakeOffer = async () => {
    if (!offeredPrice) {
      toast.error("Please input a price", REACT_TOAST_CONFIG);
      return;
    }

    if (!checkBalance(offeredPrice)) {
      toast.error("Insufficient funds to make offer", REACT_TOAST_CONFIG);
      return;
    }

    try {
      if (state?.userId) {
        setOfferLoading(true);
        const response = await axios.post(`${BASE_URL}/offer/userOfferPrice`, {
          carId: carDetails._id,
          hostId: carDetails?.hostID?._id,
          userId: state.userId,
          currentPrice: totalPrice,
          userOfferPrice: removeNairaAndConvertToInt(offeredPrice),
          serviceType: vehicleServiceTypeId,
          startLocation: fromWhereSelected.address,
          endLocation: !!finalDestinationSelected.address
            ? finalDestinationSelected.address
            : fromWhereSelected.address,
          bookingStartDate: pickupTime,
          bookingEndDate: !!returnTime ? returnTime : pickupTime,
        });

        if (!response.data?.isError) {
          toast.success("successful", REACT_TOAST_CONFIG);

          setOfferLoading(false);

          navigate("/profile/bookings");
        }
      } else {
        dispatch({
          type: "TOGGLELOGIN",
        });
      }
    } catch (error) {
      setOfferLoading(false);
      console.error(error);

      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          errorToast((axiosError.response.data as any)?.message);
        } else if (axiosError.request) {
          errorToast("Request made but no response received");
        } else {
          errorToast("An unknown Axios error occurred");
        }
      } else if (typeof error === "string") {
        errorToast(error);
      } else {
        errorToast("An unknown error occurred");
      }
    }
  };

  const errorToast = (message: any) => {
    toast.error(message as string, REACT_TOAST_CONFIG);
  };

  useEffect(() => {
    const getBalance = async () => {
      try {
        if (state?.userId) {
          const { data } = await axios.get(
            `${BASE_URL}/wallet/accountBalance/${state?.userId}`
          );

          setBalance(data?.data?.wallet?.balance);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getBalance();
  }, [data, state?.userId]);

  const handleBookClick = async () => {
    try {
      if (state?.userId) {
        if (validForm()) {
          if (!checkBalance(totalPrice)) {
            toast.error(
              "Insufficient funds to make a booking",
              REACT_TOAST_CONFIG
            );
            return;
          }

          setBookingModal(true);
        }
      } else {
        dispatch({
          type: "TOGGLELOGIN",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validForm = (makeOffer = false) => {
    const formErrors = [];

    if (!!!serviceType) {
      formErrors.push("Service type");
    }

    if (showDestinationBox) {
      if (!!!finalDestinationSelected) {
        formErrors.push("Final destination");
      }
    }

    if (!!!fromWhereSelected) {
      formErrors.push("Pickup location");
    }

    if (!!!pickupTime) {
      formErrors.push("Pickup time");
    }

    if (returnType) {
      if (!!!returnTime) {
        formErrors.push("Return time");
      }
    }

    if (!!formErrors.length) {
      toast.error(
        `Kindly provide ${formErrors.join(", ")} in the form above`,
        REACT_TOAST_CONFIG
      );
      return false;
    }

    return true;
  };

  const checkBalance = (payablePrice: any) => {
    if (state?.userId) {
      return balance > removeNairaAndConvertToInt(payablePrice.toString());
    } else {
      dispatch({
        type: "TOGGLELOGIN",
      });
    }
  };

  const handleBook = async () => {
    try {
      setBookLoading(true);

      const response = await axios.post(`${BASE_URL}/booking/createBooking`, {
        carId: carDetails._id,
        serviceType: vehicleServiceTypeId,
        hostId: carDetails?.hostID?._id,
        userId: state.userId,
        transActionTypeId: "66051f518eb0dce655438c80",
        bookingStartDate: pickupTime,
        bookingEndDate: !!returnTime ? returnTime : pickupTime,
        bookingPrice: totalPrice,
        startLocation: fromWhereSelected.address,
        endLocation: !!finalDestinationSelected.address
          ? finalDestinationSelected.address
          : fromWhereSelected.address,
      });

      if (!response.data?.isError) {
        setBookLoading(false);
        setBookingModal(false);

        toast.success("successful", REACT_TOAST_CONFIG);

        navigate("/car/car-rental-booking", {
          state: {
            carResp: response?.data?.data,
            rentDetails: {
              fromWhereSelected,
              finalDestinationSelected,
              serviceType,
              pickupTime,
              returnTime,
              carDetails,
              price,
              totalPrice: calculateTotalPrice(),
              VAT_RATE,
            },
          },
        });
        // onClose();
      } else {
        toast.error("error", REACT_TOAST_CONFIG);
      }
    } catch {
      setBookLoading(false);
      setBookingModal(false);

      toast.error("error", REACT_TOAST_CONFIG);
    }
  };

  const handlePriceChange = (days: any) => {
    let servicePrice = carDetails?.serviceTypes.filter(
      (val: any) => val.name === serviceType
    )[0]?.price;
    setPrice(servicePrice);

    const totalPrice = servicePrice * (days ?? numberOfDays) * (1 + VAT_RATE);
    setTotalPrice(totalPrice);
    // let price: any;
    const foundServiceType = carDetails?.serviceTypes?.find(
      (val: any) => val?.serviceTypeId === serviceType
      // (val: any) => val?.serviceTypeId === serviceType
    );
    if (foundServiceType) {
    } else {
    }
  };

  const calculateTotalPrice = () => {
    const basePrice = price;
    const totalPrice = basePrice * numberOfDays * (1 + VAT_RATE);
    return totalPrice;
  };

  useEffect(() => {
    calculateDays();
    calculateTotalPrice();
    setVehicleServiceTypeId(
      carDetails.serviceTypes.filter((val: any) => val.name === serviceType)[0]
        ?._id
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceType, pickupTime, returnTime, carDetails]);

  return (
    <div className="max-w-[1480px] w-full flex flex-col items-center justify-center mx-auto">
      <ToastContainer />

      <div className="flex bg-gray-200 py-4 justify-center items-center w-full">
        <div className="w-full max-w-[90%] ">
          <SearchBox
            vehicleServiceTypeData={carDetails?.serviceTypes}
            buttonTitle={"Edit"}
            searchBoxClasses={{
              buttonClass: "bg-white border border-black text-black",
            }}
            applyToolTip={true}
            showMobileVersion
          />
        </div>
      </div>

      <div className="md:max-w-[1350px] w-full pt-2 mx-auto pb-24">
        <div className="flex items-center justify-between h-full pb-2 min-h-[60px]">
          <div
            className="w-fit cursor-pointer px-6 flex justify-start items-center h-full"
            onClick={() => {
              navigate(-1);
            }}
          >
            <MdKeyboardBackspace className="w-7 h-7 mr-2" />
            Back to cars
          </div>
        </div>

        <div className="lg:flex gap-10 w-full">
          <div className="md:flex-row flex flex-col md:justify-center gap-10 w-full">
            <DmmDetails
              data={carDetails}
              price={isNaN(price) ? 0 : +price}
              backToListingButton={false}
              showBookButton={true}
              serviceType={serviceType}
              onClick={() => {
                navigate(-1);
              }}
            >
              <div className="p-6 md:pb-6 pb-2">
                {carDetails?.carNotes && (
                  <div>
                    <div className="max-w-[1100px] w-full my-5">
                      <h3 className="text-base font-semibold mb-3">DETAILS</h3>

                      <p
                        ref={textRef}
                        className={`text- font-light mb-2 text-justify whitespace-normal break-words ${
                          showFullDetails ? "h-auto" : "h-12 overflow-hidden"
                        }`}
                      >
                        {carDetails?.carNotes}
                      </p>

                      {showButton && (
                        <button
                          onClick={handleToggleDetails}
                          className="text-xs text-blue-500 underline cursor-pointer mt-[0px]"
                        >
                          {showFullDetails ? "Show Less" : "Show More"}
                        </button>
                      )}
                    </div>

                    <hr className="w-" />
                  </div>
                )}

                {!!carDetails?.careSpecialFeature.length && (
                  <div>
                    <div className="my-5">
                      <h3 className="text-base font-semibold mb-2">FEATURES</h3>

                      <div className="font-light text-[13px] md:text-[15px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-start justify-start whitespace-normal gap-1">
                        {carDetails?.careSpecialFeature?.map(
                          (feature: string, index: number) => (
                            <div key={index} className="py-2">
                              {feature}
                            </div>
                          )
                        )}
                      </div>
                    </div>

                    <hr className="" />
                  </div>
                )}

                {
                  <div>
                    <div className="flex flex-col md:grid grid-cols-2 lg:grid-cols-4 gap-4 my-5 font-light">
                      <div className="flex justify-start items-center text-[14px] md:text-[17px]">
                        <MdCheckCircle className="text-[#36C814] w-6 h-6 md:w-8 md:h-8 mr-2" />
                        Free Cancellation
                      </div>

                      <div className="flex justify-start items-center text-[14px] md:text-[17px]">
                        {carDetails?.noticePeriod === "Instant booking" ? (
                          <MdCheckCircle className="text-[#36C814] w-6 h-6 md:w-8 md:h-8 mr-2" />
                        ) : (
                          <MdCancel className="text-[#FA5151] w-6 h-6 md:w-8 md:h-8 mr-2" />
                        )}
                        Book Instantly
                      </div>

                      <div className="flex justify-start items-center text-[14px] md:text-[17px]">
                        <MdCheckCircle className="text-[#36C814] w-6 h-6 md:w-8 md:h-8 mr-2" />
                        Unlimited mileage
                      </div>

                      <div className="flex justify-start items-center text-[14px] md:text-[17px]">
                        <MdCheckCircle className="text-[#36C814] w-6 h-6 md:w-8 md:h-8 mr-2" />
                        Great condition
                      </div>
                    </div>

                    <hr className="" />
                  </div>
                }

                <h3 className="text-base font-semibold mt-5">REVIEWS</h3>
                <div>
                  {reviews.length > 0 ? (
                    reviews?.map((val: any, idx: number) => (
                      <React.Fragment key={`review ${idx + 1}`}>
                        <CarDetailsRating
                          title={getRemark(val?.rating)}
                          review={val?.comment}
                          stars={val?.rating}
                          date={formatDate(val?.createdAt)}
                          reviewer={val?.userId?.firstName}
                        />
                        <hr className="w" />
                      </React.Fragment>
                    ))
                  ) : (
                    <div className="py-10">
                      <EmptyData message="No reviews yet" />
                    </div>
                  )}
                </div>

                <div>
                  <p className="text-xs md:text-base text-black lg:hidden flex pt-4">
                    <span className="font-medium underline mr-1">Report</span>{" "}
                    <span className="font-extralight">suspicious listing</span>
                  </p>
                </div>
              </div>

              <>
                <div className="flex justify-between items-center w-full h-full px-6">
                  <div className="w-1/2 lg:flex hidden text-xs lg:text-base text-white h-full items-center">
                    <span className="font-medium underline mr-1">Report</span>
                    <span className="font-extralight">suspicious listing</span>
                  </div>

                  <div className="flex max-md:flex-col items-center justify-center md:justify-between gap-3 lg:w-1/2 lg:border-l lg:pl-5 h-full w-full">
                    {!serviceTypesWithQuoteRequired.includes(serviceType) && (
                      <div className="lg:mr-10 text-white flex-1 w-full text-[20px] font-bold text-center">
                        {totalPrice
                          ? formatCurrency(+totalPrice, true)
                          : price
                          ? price
                          : "₦00.00"}
                      </div>
                    )}

                    {!serviceTypesWithQuoteRequired.includes(serviceType) && (
                      <div className="flex gap-3 w-full justify-center md:justify-end items-center">
                        <button
                          disabled={open}
                          ref={bottomButtonRef}
                          className={`disabled:opacity-50 flex items-center justify-center gap-5 text-sm min-w-[130px] text-black font-semibold rounded-full bg-[#e0c581]`}
                          onClick={() => {
                            handleBookClick();
                          }}
                        >
                          BOOK
                        </button>

                        <button
                          disabled={open}
                          className={`disabled:opacity-50 text-sm text-[#D3B46C] min-w-[130px] font-semibold rounded-full outline outline-1 outline-[#D3B46C]  ${
                            !carDetails.allowClientOfferPrice ? "hidden" : ""
                          }`}
                          onClick={HandleMakeOfferModal}
                        >
                          MAKE OFFER
                        </button>
                      </div>
                    )}

                    {serviceTypesWithQuoteRequired.includes(serviceType) && (
                      <button
                        disabled={open}
                        className={`disabled:opacity-50 text-sm w-full font-semibold rounded-full text-black bg-[#e0c581]`}
                        onClick={HandleMakeOfferModal}
                      >
                        MAKE OFFER
                      </button>
                    )}
                  </div>
                </div>
              </>
            </DmmDetails>
          </div>
        </div>

        <DmmDialog
          title="Enter amount offering"
          open={makeOfferModal}
          onClose={() => {
            setMakeOfferModal(false);
          }}
          fullScreen={isMobile}
        >
          <div>
            <div className="flex flex-col gap-4 my-4">
              <NumericFormat
                value={offeredPrice}
                onChange={(e) => {
                  setOfferedPrice(e.target.value);
                }}
                customInput={TextField}
                label="Enter amount"
                fullWidth
                thousandSeparator={true}
                prefix={"₦"}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
              />
            </div>

            <div className=" w-full flex items-center justify-center ">
              <button
                type="button"
                className={`text-white ${
                  offerLoading ? "bg-[#0E1427]/50" : "bg-[#0E1427]"
                } border rounded-full w-full py-2 hover:shadow-md flex justify-center items-center gap-4`}
                onClick={() => {
                  HandleMakeOffer();
                }}
                disabled={offerLoading}
              >
                {offerLoading && <DmmSpinner size={25} />}
                Send offer to host
              </button>
            </div>
          </div>
        </DmmDialog>

        <DmmDialog
          title="Please confirm!"
          open={bookingModal}
          onClose={() => {
            setBookingModal(false);
          }}
          fullScreen={isMobile}
        >
          <div className="flex flex-col items-center">
            <p className="text-[18px] my-5">
              Are you sure you want to continue this booking?
            </p>

            <div className="flex gap-6 mt-5 mb-3 w-full">
              <button
                type="button"
                className={`text-white ${
                  bookLoading ? "bg-[#0E1427]/50" : "bg-[#0E1427]"
                } border rounded-full w-full py-2 hover:shadow-md flex justify-center items-center gap-4`}
                onClick={handleBook}
                disabled={bookLoading}
              >
                {bookLoading && <DmmSpinner size={25} />}
                Yes
              </button>

              <button
                type="button"
                className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full  py-2 hover:shadow-md w-full"
                onClick={() => {
                  setBookingModal(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </DmmDialog>

        <DmmDialog
          title="Insufficient Funds!"
          open={insufficientFundModal}
          onClose={() => {
            setInsufficientFundModal(false);
          }}
          fullScreen={isMobile}
        >
          <div className="flex flex-col items-center">
            <p className="text-[18px]">Proceed to fund wallet?</p>

            <div className="flex gap-6 mt-5 mb-3 w-full">
              <CustomButton
                type="button"
                className="text-white bg-indigo-950 border-gray-500 border rounded-full py-2 hover:shadow-md w-full"
                onClick={() => {
                  navigate("/profile/wallet");
                }}
              >
                Proceed
              </CustomButton>

              <button
                type="button"
                className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full py-2 hover:shadow-md w-full"
                onClick={() => {
                  setInsufficientFundModal(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </DmmDialog>
      </div>
    </div>
  );
};

export default CarDetails;
