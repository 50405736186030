import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { User } from "../../utils/types";
import axios from "axios";
import { useAuth } from "../../context/authContext";
import { useData, useIsMobile } from "../../Hooks/useData";
import Summary from "../../components/bookingSummary.tsx/Summary";
import OrderSummary from "../../components/bookingSummary.tsx/OrderSummary";
import DmmDialog from "../../components/modal/DmmDialog";
import { useDataContext } from "../../providers/DataProvider";
import { formatCurrency } from "../../utils/stringFunctions";
import CustomButton from "../../components/elements/CustomButton";
import { removeNairaAndConvertToInt } from "../../utils/RemoveNairaAndConvertToInt";
import { REACT_TOAST_CONFIG } from "../../utils/utilsFunctions";
import { ToastContainer, toast } from "react-toastify";
import RescheduleSearchbox from "../../components/rescheduleSearchbox/rescheduleSearchbox";

const BASE_URL = process.env.REACT_APP_BASE_URL;
interface BookingData {
  userId: User;
  bookingPrice: number;
}

export function formatDate(date: any) {
  const inputDate = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };
  return inputDate.toLocaleString("en-US", options);
}

const CarRentalBooking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const { userData } = useData();
  const [cancelModal, setCancelModal] = useState(false);
  const [confirmCancelModal, setConfirmCancelModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [done, setDone] = useState(false);
  const [confirmRescheduleModal, setConfirmRescheduleModal] = useState(false);
  const [cancelBookingModal, setCancelBookingModal] = useState(false);
  const [allServiceTypeData, setAllServiceTypeData] = useState<any[]>([]);
  const [carCity, setCarCity] = useState<any>("");
  const [serviceTypeData, setServiceTypeData] = useState<any[]>([]);
  // const [serviceType, setServiceType] = useState<any>();
  const [city, setCity] = useState<any[]>([]);
  const [numberOfDays, setNumberOfDays] = useState<number>(1);
  const [price, setPrice] = useState(0);
  const [driver, setDriver] = useState<any>({});
  const [rescheduleLoading, setRescheduleLoading] = useState<any>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [vehicleServiceTypeId, setVehicleServiceTypeId] = useState<any>("");
  const { state, dispatch } = useAuth();
  const [balance, setBalance] = useState<number>(0);
  const isMobile = useIsMobile();
  const { fromWhereSelected, returnTime, pickupTime, serviceType, VAT_RATE } =
    useDataContext();

  const { carResp: bookingData, rentDetails } = location?.state ?? {};
  // const { carResp: bookingData, rentDetails } = location?.state || {};

  useEffect(() => {
    const getState = async () => {
      try {
        // Fetch car makes
        const { data } = await axios.get(`${BASE_URL}/general/countries`);

        if (data) {
          const response = await axios.get(
            `${BASE_URL}/general/countries/${data.data[158].code}`
          );

          if (response.data.data) {
            const response = await axios.get(
              `${BASE_URL}/general/cities/Lagos/LA`
            );

            if (response.data.data) {
              setCity(response.data.data);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getState();
  }, []);
  useEffect(() => {
    setDone(false);
    handleEditClicked();
  }, [serviceType, pickupTime, returnTime, fromWhereSelected]);

  const HandleCancelbookingModal = () => {
    setCancelModal((prev) => !prev);
  };

  const HandleCancelbooking = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/booking/cancelBooking/${bookingData[1]?._id}`,
        {
          hostId: rentDetails?.data?.hostID?._id,
          userId: state.userId,
          cancellerId: state.userId,
        }
      );

      if (!response.data?.isError === true) {
        setCancelModal(false);
        setConfirmCancelModal(true);
      }
    } catch (error) {
      setCancelModal(false);
    }
  };

  const checkBalance = (payablePrice: any) => {
    if (state?.userId) {
      return (
        balance + bookingData[1]?.bookingPrice >
        removeNairaAndConvertToInt(payablePrice.toString())
      );
    } else {
      dispatch({
        type: "TOGGLELOGIN",
      });
    }
  };

  useEffect(() => {
    const getBalance = async () => {
      try {
        if (state?.userId) {
          const { data } = await axios.get(
            `${BASE_URL}/wallet/accountBalance/${state?.userId}`
          );

          setBalance(data?.data?.wallet?.balance);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getBalance();
  }, [state?.userId]);

  const HandleRescheduleModal = () => {
    setRescheduleModal(true);
  };

  const HandleRescheduleBookingCheck = async () => {
    setRescheduleLoading(true);
    if (!checkBalance(totalPrice)) {
      toast.error("Insufficient funds to reschedule", REACT_TOAST_CONFIG);
      setRescheduleLoading(false);
      return;
    }
    setRescheduleModal(false);
    setConfirmRescheduleModal(true);
    setRescheduleLoading(false);
  };

  const HandleRescheduleBooking = async () => {
    setRescheduleLoading(true);

    try {
      const response = await axios.put(
        `${BASE_URL}/booking/rescheduleBooking/${bookingData[1]?._id}`,
        {
          newPrice: totalPrice,
          newStartDate: pickupTime,
          newEndDate: !!returnTime ? returnTime : pickupTime,
          userId: state.userId,
          serviceTypeId: rentDetails?.carDetails?.serviceTypes.filter(
            (val: any) => val.name === serviceType
          )[0]?._id,
        }
      );
      if (!response.data?.isError === true) {
        setConfirmRescheduleModal(false);
        setRescheduleModal(false);
        setRescheduleLoading(false);
      }
    } catch (error) {
      toast.error("error", REACT_TOAST_CONFIG);
      console.log(error);
      setRescheduleLoading(false);
    }
    setRescheduleLoading(false);
  };

  useEffect(() => {
    setCarCity(data?.data?.city);
    setServiceTypeData(data?.carId?.serviceTypes);

    const fetchDriver = async () => {
      try {
        if (data?.carId?._id && data?.hostId) {
          const response = await axios.get(
            `${BASE_URL}/host/getDriverDetails/${data?.carId?._id}/${data?.hostId}`
          );
          if (response) {
            setDriver(response.data.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchServiceType = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/general/getServiceTypes`);
        if (response) {
          setAllServiceTypeData(response?.data?.data);
          // setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDriver();
    fetchServiceType();
  }, [data]);

  const handlePriceChange = (days: any) => {
    let servicePrice = rentDetails?.carDetails?.serviceTypes.filter(
      (val: any) => val.name === serviceType
    )[0]?.price;
    setPrice(servicePrice);

    const totalPrice = servicePrice * (days ?? numberOfDays) * (1 + VAT_RATE);
    setTotalPrice(totalPrice);
    // let price: any;
    const foundServiceType = rentDetails?.carDetails?.serviceTypes?.find(
      (val: any) => val?.serviceTypeId === serviceType
      // (val: any) => val?.serviceTypeId === serviceType
    );
  };

  const calculateDays = () => {
    const returnDate = new Date(returnTime);
    const pickupDate = new Date(pickupTime);

    const timeDifference = returnDate.getTime() - pickupDate.getTime();

    const daysDifference = timeDifference / (1000 * 3600 * 24);

    const days =
      !isNaN(daysDifference) && daysDifference > 0.1
        ? Math.round(daysDifference)
        : 1;

    setNumberOfDays(days);
    handlePriceChange(days);
    return days;
  };
  const calculateTotalPrice = () => {
    const basePrice = price;
    const totalPrice = basePrice * numberOfDays * (1 + VAT_RATE);

    return totalPrice;
  };

  const handleEditClicked = () => {
    calculateDays();

    calculateTotalPrice();
    setVehicleServiceTypeId(
      rentDetails?.data?.serviceTypes.filter(
        (val: any) => val.name === serviceType
      )[0]?._id
    );
    setDone(true);
  };

  const summaryData = {
    ...bookingData[1],
    carId: { carMakeID: rentDetails?.carDetails?.carMakeID },
  };

  const orderSummaryData: BookingData = {
    userId: userData as any,
    bookingPrice: bookingData[1]?.bookingPrice,
  };

  return (
    <div>
      <Summary data={summaryData} />

      <div className="mx-auto md:flex md:flex-row flex flex-col justify-center mt-10 w-full max-w-[1480px] lg:gap-20 lg:mb-10">
        <div className="w-full flex flex-col px-6 lg:px-10">
          <div className="flex">
            <h2 className="text-[20px] md:text-[25px]">
              Booking received, awaiting host confirmation!
            </h2>
          </div>

          <p className="text-[15px] md:text-[18px] font-light mt-4 mb-4 py-3">
            Thanks {`${userData?.firstName} ${userData?.lastName}`} for booking
            a vehicle with DMM GO. A confirmation message will be sent to your
            provided contact.
          </p>

          <hr className="mb-3 mt-1" />

          {/* details */}
          <div className=" flex flex-col justify-between">
            <div className="flex justify-between items-center w-full flex-col md:flex-row py-3">
              <div className="flex flex-col md:flex-row md:space-x-24 w-full">
                <div className="text-[14px] md:text-[16px] flex justify-between md:block items-center">
                  <p className="font-light">Booking date:</p>

                  <p className="font-medium">
                    {formatDate(rentDetails?.pickupTime)}
                  </p>
                </div>

                <div className="text-[14px] md:text-[16px] flex justify-between md:block items-center">
                  <p className="font-light">Driver:</p>
                  {driver?.firstName || driver?.lastName ? (
                    <p className="font-medium capitalize">
                      {driver?.firstName} {driver?.lastName}
                    </p>
                  ) : (
                    <p className="font-semibold">None Assigned</p>
                  )}
                </div>

                <div className="text-[14px] md:text-[16px] flex justify-between md:block items-center">
                  <p className="font-light">Service type:</p>
                  <p className="font-medium">{rentDetails?.serviceType}</p>
                </div>

                <div className="text-[14px] md:text-[16px] flex justify-between md:block items-center">
                  <p className="font-light">Status:</p>

                  <p className="font-medium">{bookingData[1]?.status}</p>
                </div>
                <div className="text-[14px] md:text-[16px] flex justify-between md:block items-center">
                  <p className="font-light">Booking number:</p>

                  <p className="font-medium">{bookingData[1]?.bookingNumber}</p>
                </div>
              </div>
            </div>
            <div className=" flex  p-2 hover:border-orange-300 cursor-pointer gap-6 mb-3 mt-3">
              <button
                type="button"
                className="text-amber bg-white-700 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md"
                onClick={HandleRescheduleModal}
              >
                Reschedule
              </button>

              <button
                type="button"
                className="text-red-600 bg-white-700 hover:shadow-md px-4 rounded-full"
                onClick={HandleCancelbookingModal}
              >
                Cancel booking
              </button>
            </div>

            <hr className="mt-3 mb-7" />

            <div className="max-lg:hidden"></div>

            <p className="text-[15px] md:text-[18px] font-light">
              We'll send a successful transaction confirmation to your provided
              contact Kindly reach us on{" "}
              <span className="font-medium">info@dmm.com</span> for anything you
              might need us for
            </p>

            <p className="text-base font-bold mt-4">
              Thanks for booking with us.
            </p>
          </div>
        </div>

        <OrderSummary data={orderSummaryData} />
      </div>
      <ToastContainer />
      {/* <CancelbookingModal isOpen={cancelModal} /> */}
      <DmmDialog
        open={cancelModal}
        onClose={() => {
          setCancelModal(false);
        }}
        title=""
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mb-2">Please confirm!</h1>
          <p className="text-sm">
            Are you sure you want to cancel this booking?
          </p>

          <div className="flex justify-center items-center space-x-16 mt-5 mb-3">
            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={HandleCancelbooking}
            >
              Yes
            </button>
            <button
              type="button"
              className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={() => {
                setCancelModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={confirmCancelModal}
        onClose={() => {
          setConfirmCancelModal(false);
          navigate("/");
        }}
        title=""
        fullScreen={isMobile}
      >
        <div className="flex flex-col items-center">
          <h1 className="text-lg font-semibold mb-2">Booking cancelled!</h1>
          <p className="mt-2 mb-8">
            Your booking has been cancelled successfully.
          </p>
          <button
            type="button"
            className="text-white bg-indigo-950 border-gray-500 border rounded-full px-24 py-1 hover:shadow-md"
            onClick={() => {
              setConfirmCancelModal(false);
              navigate("/");
            }}
          >
            Close and go home
          </button>
        </div>
      </DmmDialog>

      <DmmDialog
        open={rescheduleModal}
        title="Reschedule booking"
        onClose={() => {
          setRescheduleModal(false);
        }}
        fullScreen={isMobile}
      >
        <div className="flex flex-col w-">
          <p className="text-xs">
            Please note that a penalty applies for rescheduling less than{" "}
            <span className="font-bold">24 hours</span> before the trip start
          </p>

          <div className="w-full max-w-[90%] ">
            <RescheduleSearchbox
              vehicleServiceTypeData={
                data?.rentDetails?.carDetails?.serviceTypes
              }
              flexDirection="flex-col"
              searchboxHeight="h-full"
              width="w-full max-w-full"
              buttonTitle={"Edit"}
              searchBoxClasses={{
                buttonClass: "bg-white border border-black text-black",
              }}
              applyToolTip={true}
              showMobileVersion
            />
          </div>

          <div className="flex flex-col w-full my-3">
            <div className=" flex gap-2 ml-4">
              <p>Price</p>
              <p className=" font-medium">{formatCurrency(totalPrice)}</p>
            </div>
            <div className="w-full">
              <CustomButton
                loading={rescheduleLoading}
                type="button"
                className="text-white bg-[#0E1427] border-gray-500 border rounded-full px-10 py-2 hover:shadow-md mt-10 w-full"
                // onClick={handleEditClicked}
                onClick={HandleRescheduleBookingCheck}
              >
                Reschedule
              </CustomButton>
            </div>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={confirmRescheduleModal}
        title=""
        onClose={() => {
          setConfirmRescheduleModal(false);
        }}
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mt-[15px] mb-2">
            Please confirm!
          </h1>
          <p>Are you sure you want to reschedule this booking?</p>
          <div className="flex items-center space-x-16 mt-5 mb-3">
            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-16 py-1 hover:shadow-md"
              onClick={HandleRescheduleBooking}
            >
              Yes
            </button>
            <button
              type="button"
              className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full px-16 py-1 hover:shadow-md"
              onClick={() => {
                setConfirmRescheduleModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={cancelBookingModal}
        title=""
        onClose={() => {
          setCancelBookingModal(false);
        }}
        fullScreen={isMobile}
      >
        <div>
          <h1 className="text-lg font-semibold mb-2">Booking rescheduled!</h1>
          <p className="mt-2 mb-8">
            Your booking has been rescheduled successfully.
          </p>
          <button
            type="button"
            className="text-white bg-indigo-950 border-gray-500 border rounded-full px-24 py-1 hover:shadow-md"
            onClick={() => {
              setConfirmCancelModal(false);
              navigate("/");
            }}
          >
            Close and go home
          </button>
        </div>
      </DmmDialog>
    </div>
  );
};

export default CarRentalBooking;
