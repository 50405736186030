import React, { ChangeEvent, FC, useState } from "react";
import axios from "axios";
import { useData } from "../../Hooks/useData";

interface handleNextClickProps {
  handleNextClick: () => void;
  handleSkipClick?: () => void;
  setForms: (inputFields: any) => void;
}

const TruckDriverDetails: FC<handleNextClickProps> = ({
  handleNextClick,
  handleSkipClick,
  setForms,
}) => {
  const [inputFields, setInputFields] = useState({
    fullName: "",
    phoneNum: "",
    licenseNum: "",
    location: "",
  });

  const { userData } = useData();
  const role = userData?.role?.name;

  const handleFileChange = (
    fieldName: any,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      handleUpload(fieldName, file);
    }
  };

  const handleUpload = async (fieldName: any, file: any) => {
    try {
      const formData = new FormData();
      formData.append("image", file);

      // Send a POST request to the ImgBB API to upload the image
      const response = await axios.post(
        `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMGBB_KEY}`,
        formData
      );

      // Handle the response from the API
      const { data } = response.data;
      setInputFields({
        ...inputFields,
        [fieldName]: data.url,
      });

      // Save the URL details to your database
      // You can send another request to your backend server to save the URL details
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, fieldName: any) => {
    setInputFields({
      ...inputFields,
      [fieldName]: e.target.value,
    });
  };
  const handleAppendForm = () => {
    setForms(inputFields);
  };

  return (
    <div className="mx-auto flex flex-col gap-8 w-[1140px]">
      <div className="flex flex-col gap-2">
        <h1 className=" font-semibold">Driver details</h1>
        <p className=" font-light">
          Please note all details provided must exactly match that on your
          passport or government issued photo identification.
        </p>
      </div>

      <div className=" flex gap-8">
        <div className=" flex flex-col w-1/2 gap-4">
          <input
            type="text"
            placeholder="Full name"
            className=" border-2 mb-2 rounded-lg p-2"
            onChange={(e) => handleOnChange(e, "fullName")}
          />
          <input
            type="text"
            placeholder="Phone number"
            className=" border-2 mb-2 rounded-lg p-2"
            onChange={(e) => handleOnChange(e, "phoneNum")}
          />
          <label
            htmlFor="select_eqp"
            className=" border-2 mb-2 rounded-lg p-2 flex flex-col text-xs"
          >
            {" "}
            Driver's personal protective equipment
            <select name="protective_eqp" id="" >
              <option value=" ">Select equipment</option>
            </select>
          </label>

          <div className=" border-2 mb-2 rounded-lg  flex justify-between gap-6 p-2 pr-1">
            <div>
              Upload drivers lincense{" "}
              <span className=" text-black">(image, pdf)</span>{" "}
            </div>
            <input
              type="file"
              placeholder=" Drivers lincense"
              className="  rounded-lg w-[15rem] border-2"
              onChange={(e) => handleFileChange("driver's license", e)}
            />
          </div>
        </div>

        <div className=" flex flex-col w-1/2 gap-4">
          <input
            type="text"
            placeholder="Current location"
            className=" border-2 mb-2 rounded-lg p-2"
            onChange={(e) => handleOnChange(e, "location")}
          />
          <input
            type="text"
            placeholder="Drivers license number"
            className=" border-2 mb-2 rounded-lg p-2"
          />
          <div className=" border-2 mb-2 rounded-lg  flex justify-between gap-6 p-2 pr-1">
            <div>
              Upload picture <span className=" text-black">(image, pdf)</span>{" "}
            </div>
            <input
              type="file"
              placeholder="upload pictures"
              className="  rounded-lg w-[15rem] border-2"
              onChange={(e) => handleFileChange("upload pictures", e)}
              multiple={true}
            />
          </div>
        </div>
      </div>

      <div>
        <div className=" flex gap-4">
          <button
            className=" bg-black text-white px-5 py-1 rounded-full"
            onClick={() => {
              handleNextClick && handleNextClick();
              handleAppendForm();
            }}
          >
            Next
          </button>
          <button
            className=" border-2  text-black px-5 py-1 rounded-full"
            onClick={handleSkipClick}
          >
            Skip
          </button>
        </div>

        {role === "Host" && (
          <p className="font-light mt-2 mb-20">
            Please note that a driver must be assigned to each booking before
            such booking can be confirmed
          </p>
        )}
      </div>
    </div>
  );
};

export default TruckDriverDetails;
