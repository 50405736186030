import React, { useState } from "react";
import DriversDetails from "./DriversDetails";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useData } from "../../Hooks/useData";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AssignDriverFormDataSchema } from "../../validations/formDataSchema";
import { REACT_TOAST_CONFIG } from "../../utils/utilsFunctions";
import ListingButton from "../../components/listingProcess.tsx/ListingButton";
import { extractAssignDriverInformation } from "../../utils/extractInformation";
import { z } from "zod";
import { DmmSpinner } from "../../components/Spinner";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export type Inputs = z.infer<typeof AssignDriverFormDataSchema>;

const AssignDriver = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,

    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(AssignDriverFormDataSchema),
    mode: "onBlur",
  });

  let carId = location?.state;

  const navigate = useNavigate();
  const { userData } = useData();
  const { carCode } = useParams();

  const onInvalid = (errors: any) => console.error(errors);

  const processForm: SubmitHandler<Inputs> = async (data: any) => {
    const formattedData = extractAssignDriverInformation(data);

    console.log("Formatted Data:", formattedData.driver);

    setIsLoading(true);

    try {
      const result = await axios.post(
        `${BASE_URL}/host/assignDriver/${carId}/${userData?.userId}`,
        formattedData.driver,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status >= 200 && result.status < 300) {
        setIsLoading(false);

        toast.success(`Driver assigned successfully!`, REACT_TOAST_CONFIG);

        navigate(`/profile/listings/${carCode}`);

        reset();
      } else {
        setIsLoading(false);

        toast.error(`${result?.data?.message}`, REACT_TOAST_CONFIG);

        console.error("Error:", result.statusText);
      }
    } catch (err) {
      setIsLoading(false);

      toast.error(
        `${(err as any).response?.data?.error || "An error occurred"}`,
        REACT_TOAST_CONFIG
      );

      console.log("error", err);
    }
  };

  return (
    <div className=" flex flex-col pb-8 gap-2 items-center w-full ">
      <div className=" w-full rounded-full flex items-center justify-between md:max-w-[1140px] mx-auto ">
        <div className="flex flex-col w-full">
          <form
            onSubmit={handleSubmit(processForm, onInvalid)}
            className="w-full flex justify-center items-center"
          >
            <DriversDetails
              errors={errors}
              control={control}
              watch={watch}
              register={register}
            />
          </form>

          <div className="w-full justify-start items-center gap-4 md:gap-8 px-6 lg:px-0 flex flex-col md:flex-row max-w-[1140px] mt-10">
            <ListingButton
              name="Assign Driver"
              type="submit"
              handleClick={handleSubmit(processForm, onInvalid)}
              disabled={isLoading}
            >
              {isLoading && <DmmSpinner size={25} />}
            </ListingButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignDriver;
