import { removeNairaAndConvertToInt } from "./RemoveNairaAndConvertToInt";
import { dateToEpoch, epochToDateString } from "./utilsFunctions";
import { ServiceType } from "../pages/car-rental/ServicesPricing";
import {
  exteriorColors,
  interiorColors,
  OTHERS_SPECIFY_MESSAGE,
  serviceTypesWithId,
} from "./constants";

interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  picture?: string;
}

interface Host {
  registerCompany: boolean;
  cacDocument: string[];
  hostDateOfBirth: any;
  passport?: string[];
  idType: string;
  validIDCard: string[];
  utilityBill: string[];
  licenseNumber?: string;
  rCNumber?: string;
  businessName?: string;
}

interface Car {
  carMake: string;
  carMode: string;
  carYear: number;
  vehicleType: string[];
  registerationNumber: string;
  canGuestDriveYourCar: boolean;
  availableForInterState: boolean;
  numberOfDoors: number;
  carExteriorColor: string;
  carInteriorColor: string;
  careSpecialFeature: string[];
  estimatedVehicleValue: number;
  numberOfPassanger: number;
  address: string;
  addressLatitude: number;
  addressLongitude: number;
  wasCarUpgraded: boolean;
  upgradedYear: string;
  vehicleInsurance: string;
  vehicleLicense: string;
  proofOfOwnership: string;
  roadWorthiness: string;
  carImages: string[];
  carVideo: string;
  notes: string;
  avilability: boolean;
  dayAvailable: string[];
  noticePeriod: string;
  serviceTypes: any[];
  allowDMMSetPrice: boolean;
  allowClientOfferPrice: boolean;
}

interface Bank {
  accountName: string;
  accountNumber: string;
  bankName: string;
  sortCode: string;
}

interface Driver {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  stateOfOrigin: string;
  driverLicense: string;
  languages: string[];
  dateOfBirth: any;
  driverLicenseUploadURL: string;
  statesFamiliar: string[];
  driverPictureURL: string;
  email?: string;
}

interface Declaration {
  myCarInACPerfectCondition: boolean;
  myCarHasNoMechanicalOrElectricalIssue: boolean;
  noCosmeticDamage: boolean;
  functionalStereo: boolean;
  functionalWindowWinders: boolean;
  nonSmokingVehicle: boolean;
  noUnusualSound: boolean;
  servicedRegularly: boolean;
  acknowledged: boolean;
  readyForRental: boolean;
  carNeedOtherFixing: boolean;
  commentStateOfCar: string;
}

interface FormData {
  [key: string]: any;
}

const handleBooleanResponse = (response: string) => {
  return response === "true";
};

const handleURL = (response: any[]) => {
  if (response?.length === 1) {
    return response[0];
  } else if (response?.length > 1) {
    return response;
  }

  return "";
};

// Format Data for Car Listing
export const extractInformation = (formData: FormData) => {
  // Extract user profile information
  const userProfile: UserProfile = {
    firstName: formData["firstName"],
    lastName: formData["lastName"],
    email: formData["email"],
    phoneNumber: formData["phoneNumber"],
    address: formData["address"],
    picture: handleURL(formData["picture"]),
  };

  // Extract host information
  const host: Host = {
    registerCompany: formData["companyDetails"]?.registerCompany,
    cacDocument: handleURL(formData["companyDetails"]?.cacDocument),
    hostDateOfBirth: dateToEpoch(formData["hostDateOfBirth"]),
    passport: handleURL(formData["passport"]),
    idType: handleURL(formData["idType"]),
    validIDCard: handleURL(formData["validIDCard"]),
    utilityBill: handleURL(formData["utilityBill"]),
    licenseNumber: formData["licenseNumber"],
    rCNumber: formData["rCNumber"],
    businessName: formData["businessName"],
  };

  // Extract car information
  const car: Car = {
    carMake: formData["carMake"],
    carMode: formData["carModel"].split(" - ").shift(),
    carYear: Number(formData["carYear"]),
    vehicleType: formData["vehicleType"],
    registerationNumber: formData["registrationNumber"],
    canGuestDriveYourCar: handleBooleanResponse(
      formData["canGuestDriveYourCar"]
    ),
    availableForInterState: handleBooleanResponse(
      formData["availableForInterState"]
    ),
    numberOfDoors: Number(formData["numberOfDoors"]),
    carExteriorColor:
      formData["carExteriorColor"]?.primary === OTHERS_SPECIFY_MESSAGE
        ? formData["carExteriorColor"]?.secondary
        : formData["carExteriorColor"]?.primary,
    carInteriorColor:
      formData["carInteriorColor"]?.primary === OTHERS_SPECIFY_MESSAGE
        ? formData["carInteriorColor"]?.secondary
        : formData["carInteriorColor"]?.primary,
    careSpecialFeature: formData["specialFeatures"],
    estimatedVehicleValue: removeNairaAndConvertToInt(
      formData["estimatedCarValue"]
    ),
    numberOfPassanger: Number(formData["numberOfPassengers"]),
    address: formData["location"].address,
    addressLatitude: formData["location"].lat,
    addressLongitude: formData["location"].lng,
    wasCarUpgraded: handleBooleanResponse(formData["wasCarUpgraded"]),
    upgradedYear: formData["upgradedYear"] || "",
    vehicleInsurance: handleURL(formData["carVehicleInsurance"]),
    vehicleLicense: handleURL(formData["carVehicleLicense"]),
    proofOfOwnership: handleURL(formData["proofOfOwnership"]),
    roadWorthiness: handleURL(formData["roadWorthiness"]),
    carImages: handleURL(formData["carImages"]),
    carVideo: handleURL(formData["carVideo"]),
    notes: formData["notes"] || "",
    avilability: handleBooleanResponse(formData["availability"]),
    dayAvailable: formData["daysAvailable"],
    noticePeriod: formData["noticePeriod"],
    serviceTypes: formData["serviceTypes"],
    allowDMMSetPrice: formData["selectedServices"].allowDMMSetPrice,
    allowClientOfferPrice: formData["allowOffer"],
  };

  // Extract bank information
  const bank: Bank = {
    accountName: formData["accountName"],
    accountNumber: formData["accountNum"],
    bankName: formData["bankName"] || " ",
    sortCode: formData["sortCode"],
  };

  // Extract driver information
  const driver: Driver = {
    firstName: formData["driverFirstName"],
    lastName: formData["driverLastName"],
    phoneNumber: formData["driverPhoneNumber"],
    stateOfOrigin: formData["driverStateOfOrigin"],
    driverLicense: formData["driverLicense"],
    statesFamiliar: formData["driverStatesFamiliar"],
    dateOfBirth: dateToEpoch(formData["driverDateOfBirth"]),
    languages: formData["driverLanguages"],
    driverLicenseUploadURL: handleURL(formData["driverLicenseUpload"]),
    driverPictureURL: handleURL(formData["driverPicture"]),
  };

  // Extract declaration information
  const declaration: Declaration = {
    myCarInACPerfectCondition: formData["myCarInACPerfectCondition"],
    myCarHasNoMechanicalOrElectricalIssue:
      formData["myCarHasNoMechanicalOrElectricalIssue"],
    noCosmeticDamage: formData["noCosmeticDamage"],
    functionalStereo: formData["functionalStereo"],
    functionalWindowWinders: formData["functionalWindowWinders"],
    nonSmokingVehicle: formData["nonSmokingVehicle"],
    noUnusualSound: formData["noUnusualSound"],
    servicedRegularly: formData["servicedRegularly"],
    acknowledged: formData["acknowledged"],
    readyForRental: formData["readyForRental"],
    carNeedOtherFixing: formData["carNeedOtherFixing"],
    commentStateOfCar: formData["commentStateOfCar"],
  };

  return {
    userProfile,
    host,
    car,
    bank,
    driver,
    declaration,
  };
};
export const extractUpdateInformation = (formData: { [x: string]: any }) => {
  const car = {
    carMake: formData["carMake"],
    carMode: formData["carModel"].split(" - ").shift(),
    carYear: Number(formData["carYear"]),
    vehicleType: formData["vehicleType"],
    registerationNumber: formData["registrationNumber"],
    canGuestDriveYourCar: handleBooleanResponse(
      formData["canGuestDriveYourCar"]
    ),
    availableForInterState: handleBooleanResponse(
      formData["availableForInterState"]
    ),
    numberOfDoors: Number(formData["numberOfDoors"]),
    careSpecialFeature: formData["specialFeatures"],
    estimatedVehicleValue: removeNairaAndConvertToInt(
      formData["estimatedCarValue"]
    ),
    numberOfPassanger: Number(formData["numberOfPassengers"]),
    address: formData["location"].address,
    addressLatitude: formData["location"].lat,
    addressLongitude: formData["location"].lng,
    wasCarUpgraded: handleBooleanResponse(formData["wasCarUpgraded"]),
    upgradedYear: formData["upgradedYear"] || "",
    vehicleInsurance: handleURL(formData["carVehicleInsurance"]),
    vehicleLicense: handleURL(formData["carVehicleLicense"]),
    proofOfOwnership: handleURL(formData["proofOfOwnership"]),
    roadWorthiness: handleURL(formData["roadWorthiness"]),
    carImages: handleURL(formData["carImages"]),
    carVideo: handleURL(formData["carVideo"]),
    notes: formData["notes"] || "",
    avilability: handleBooleanResponse(formData["availability"]),
    dayAvailable: formData["daysAvailable"],
    noticePeriod: formData["noticePeriod"],
    serviceTypes: formData["serviceTypes"],
    allowDMMSetPrice: formData["selectedServices"].allowDMMSetPrice,
    allowClientOfferPrice: formData["allowOffer"],
  };

  const driver = {
    firstName: formData["driverFirstName"],
    lastName: formData["driverLastName"],
    phoneNumber: formData["driverPhoneNumber"],
    stateOfOrigin: formData["driverStateOfOrigin"],
    driverLicense: formData["driverLicense"],
    statesFamiliar: formData["driverStatesFamiliar"],
    dateOfBirth: dateToEpoch(formData["driverDateOfBirth"]),
    languages: formData["driverLanguages"],
    driverLicenseUploadURL: handleURL(formData["driverLicenseUpload"]),
    driverPictureURL: handleURL(formData["driverPicture"]),
  };

  return {
    car,
    driver,
  };
};

export const extractAssignDriverInformation = (formData: FormData) => {
  // Extract driver information
  const driver: Driver = {
    firstName: formData["driverFirstName"],
    lastName: formData["driverLastName"],
    phoneNumber: formData["driverPhoneNumber"],
    stateOfOrigin: formData["driverStateOfOrigin"],
    driverLicense: formData["driverLicense"],
    statesFamiliar: formData["driverStatesFamiliar"],
    dateOfBirth: dateToEpoch(formData["driverDateOfBirth"]),
    languages: formData["driverLanguages"],
    driverLicenseUploadURL: handleURL(formData["driverLicenseUpload"]),
    driverPictureURL: handleURL(formData["driverPicture"]),
  };

  return {
    driver,
  };
};

export const extractListingInformation = async (formData: any) => {
  const { car, driver } = formData;

  const subscribedServices: string[] = [];

  car?.serviceTypes?.forEach((service: { name: string }) => {
    const foundService = serviceTypesWithId.find(
      (curr: ServiceType) => curr.name === service.name
    );

    if (foundService) {
      subscribedServices.push(service.name);
    }
  });

  const getCarMode = () => {
    return `${car.carMode} - ${car?.carYear.toString()}`;
  };

  // Function to get service prices
  const getServicePrices = () => {
    const servicePrices: { [key: string]: number } = {};

    subscribedServices.forEach((service: string) => {
      const foundServiceType = car.serviceTypes.find(
        (type: { name: string }) => type.name === service
      );

      const foundUniqueId = serviceTypesWithId.find(
        (type: { name: string }) => type.name === service
      );

      if (foundServiceType && foundUniqueId) {
        servicePrices[foundUniqueId.uniqueId] =
          foundServiceType.price.toString();
      }
    });

    return servicePrices;
  };

  const getSubscribedServicesIds = () => {
    const suscribedServicesIds: string[] = [];

    subscribedServices.forEach((service: string) => {
      const foundService = serviceTypesWithId.find(
        (curr: { name: string }) => curr.name === service
      );

      if (foundService && !foundService.isQuoteRequired) {
        suscribedServicesIds.push(foundService.uniqueId);
      }
    });

    return suscribedServicesIds;
  };

  const getServiceTypes = () => {
    const updatedServiceTypes: ServiceType[] = [];

    car?.serviceTypes?.forEach((service: ServiceType) => {
      const foundService = serviceTypesWithId.find(
        (newService: ServiceType) => newService.name === service.name
      );

      if (foundService) {
        const updatedService = { ...service, uniqueId: foundService.uniqueId };

        updatedServiceTypes.push(updatedService);
      }
    });

    return updatedServiceTypes;
  };

  const getCarColor = (field: string, colorOptions: string[]) => {
    const fieldColor = car?.[field];
    if (!fieldColor) {
      return { primary: "" };
    }

    const isPrimaryColor = colorOptions.includes(fieldColor);

    if (isPrimaryColor) {
      return { primary: fieldColor, secondary: "" };
    }

    return {
      primary: OTHERS_SPECIFY_MESSAGE,
      secondary: fieldColor,
    };
  };

  // Return the assembled data
  return {
    ...car,
    carModel: getCarMode(),
    carYear: car.carYear.toString(),
    vehicleType: car.vehicleType?.[0],
    numberOfPassengers: car.numberOfPassanger?.toString(),
    numberOfDoors: car.numberOfDoors.toString(),
    registrationNumber: car.registerationNumber,
    carExteriorColor: getCarColor("carExteriorColor", exteriorColors),
    carInteriorColor: getCarColor("carInteriorColor", interiorColors),
    availableForInterState: car.availableForInterState?.toString(),
    canGuestDriveYourCar: car.canGuestDriveYourCar?.toString(),
    estimatedCarValue: car.estimatedVehicleValue?.toString(),
    wasCarUpgraded: car.wasCarUpgraded?.toString(),
    carVehicleInsurance: [car.vehicleInsurance],
    carVehicleLicense: [car.vehicleLicense],
    proofOfOwnership: [car.proofOfOwnership],
    roadWorthiness: [car.roadWorthiness],
    specialFeatures: car.careSpecialFeature,
    location: {
      address: car.address,
      lat: car.addressLatitude,
      lng: car.addressLongitude,
    },
    allowOffer: car.allowClientOfferPrice,
    daysAvailable: car.dayAvailable,
    availability: car.avilability?.toString(),
    careSpecialFeature: car.careSpecialFeature,
    driverLanguages: driver.languages,
    driverLastName: driver.lastName,
    driverFirstName: driver.firstName,
    driverPhoneNumber: driver.phoneNumber,
    driverStatesFamiliar: driver.statesFamiliar,
    driverStateOfOrigin: driver.stateOfOrigin,
    selectedServices: {
      ...getServicePrices(),
      services: subscribedServices,
      servicesIds: getSubscribedServicesIds(),
      allowDMMSetPrice: car.allowDMMSetPrice,
    },
    serviceTypes: getServiceTypes(),
    driverLicenseUpload: [driver.driverLicenseUploadURL],
    driverLicense: driver.driverLicense,
    driverPicture: [driver.driverPictureURL],
    driverDateOfBirth: epochToDateString(driver.dateOfBirth),
  };
};
