import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import ListingFormHeader from "../../components/listingProcess.tsx/ListingFormHeader";
import Field from "../../components/inputs/Field";
import FileUpload from "../../components/inputs/FileUpload";
import FieldDate from "../../components/inputs/FieldDate";
import FieldSelect from "../../components/inputs/FieldSelect";
import FieldAutocomplete from "../../components/inputs/FieldAutocomplete";
import { driversLanguage } from "../../utils/constants";

const BASE_URL = process.env.REACT_APP_BASE_URL;

interface handleNextClickProps {
  register: any;
  control: any;
  errors: any;
  watch: any;
}

const DriversDetails: FC<handleNextClickProps> = ({
  errors,
  control,
  watch,
  register,
}) => {
  const [countryData, setCountryData] = useState<
    {
      id: number;
      name: string;
      state_code: string;
      latitude: string;
      longitude: string;
      type: string;
    }[]
  >([]);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(`${BASE_URL}/general/countries/NG`);

      setCountryData(response.data.data);
    };
    getData();
  }, []);

  return (
    <div className="w-full flex flex-col items-center gap-8 lg:max-w-[1140px] max-w-[1140px] lg:px-0 px-6">
      <ListingFormHeader
        header="Driver details"
        subHeader="Please note all details provided must exactly match that on your
        passport or government issued photo identification."
      />

      <div className=" w-full grid lg:grid-cols-2 grid-cols-1 gap-4  opacity-70   justify-items-center justify-center">
        <div className=" flex flex-col  lg:w-full w-full gap-4">
          <Field
            type="text"
            label="First Name"
            fieldName="driverFirstName"
            errors={errors}
            control={control}
          />

          <Field
            type="text"
            label="Last Name"
            fieldName="driverLastName"
            errors={errors}
            control={control}
          />

          <Field
            type="text"
            label="Drivers license number"
            fieldName="driverLicense"
            errors={errors}
            control={control}
          />

          <FileUpload
            control={control}
            name="driverLicenseUpload"
            errors={errors}
            watch={watch}
            label="Upload drivers license"
          />

          <FieldAutocomplete
            control={control}
            errors={errors}
            options={countryData.map((val) => val.name)}
            fieldName="driverStatesFamiliar"
            label="States familiar with and can travel to"
            placeholder="Select state(s)"
          />
        </div>

        <div className="flex flex-col lg:w-full w-full gap-4">
          <Field
            type="text"
            label="Phone number"
            fieldName="driverPhoneNumber"
            errors={errors}
            control={control}
          />

          <FieldDate
            label="Date of birth"
            fieldName="driverDateOfBirth"
            errors={errors}
            register={register}
          />

          <FieldSelect
            options={countryData.map((data) => ({
              value: data.name,
              label: data.name,
            }))}
            errors={errors}
            label="State of origin"
            fieldName="driverStateOfOrigin"
            control={control}
          />

          <FileUpload
            control={control}
            name="driverPicture"
            errors={errors}
            watch={watch}
            label="Upload picture"
          />

          <FieldAutocomplete
            control={control}
            errors={errors}
            options={Object.values(driversLanguage)}
            fieldName="driverLanguages"
            label="Driver language(s)"
            placeholder="Select language"
          />
        </div>
      </div>
    </div>
  );
};

export default DriversDetails;
