
import React from "react";
import { FaStar } from "react-icons/fa";
import { formatDate } from "../../pages/car-rental/CarRentalBooking";
import { getRemark } from "../../utils/utilsFunctions";
import { Rating } from "@mui/material";

const CarReviewCard = ({
  review,
}: {
  review: {
    remark: string;
    rating: number;
    carType: string;
    comment: string;
    clientName: string;
    clientLocation: string;
    createdAt: string;
    reviewCarId: any;
    userId: any;
  };
}) => {
  console.log(review);

  return (
    <div className="flex flex-col gap-2 font-light">
      <h2 className="font-medium pt-5">{getRemark(review?.rating)} </h2>

      <div className="flex items-center gap-5 justify-start h-full w-full">
        <p>{review.reviewCarId?.carMakeID?.make}</p>

        <div className="flex gap-1">
          <Rating
            value={review?.rating}
            className="text-[#EFCB76] text-xs"
          />
          <Rating
            value={review?.rating}
            className="text-[#EFCB76] text-xs"
          />
        </div>
      </div>

      <p className="text-[14px] ">{review?.comment}</p>
      {/* <span className="text-xs font-light">{`${formatDate(review?.createdAt)} `}</span> */}

      <p className="text-[14px] capitalize">
        - {review.userId?.firstName}, {review.userId?.lastName}
      </p>

      <hr className="mt-2" />
    </div>
  );
};

export default CarReviewCard;
