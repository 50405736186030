import React, { useEffect, useState } from "react";
import { MdCopyright } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  if (!hydrated) {
    return null;
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="bg-[#0e1427] overflow lg:h-[160px]">
        <div className="container mx-auto md:flex flex-col justify-between p-8 max-w-[1380px]">
          {/* .......top section............ */}
          <div className=" md:flex md:flex-row flex-col  md:items-center md:justify-between text-white opacity-75 w-full">
            <div className="hidden md:flex justify-between items-center w-full max-w-[40%] space-x-3 ">
              <p>Privacy</p>
              <p>Legal Notice</p>
              <p>Terms & Condition</p>
            </div>
            <div className="text-white justify-center space-x-4 flex md:space-x-2">
              <FaFacebookF className="w-8 h-8 border border-1 rounded-full p-[6px] cursor-pointer" />
              <FaInstagram className="w-8 h-8 border border-1 rounded-full p-[6px] cursor-pointer" />
              <FaXTwitter className="w-8 h-8 border border-1 rounded-full p-[6px] cursor-pointer" />
              <FaYoutube className="w-8 h-8 border border-1 rounded-full p-[6px] cursor-pointer" />
            </div>

            <div className="">
              <button
                onClick={scrollToTop}
                className="hidden md:flex gap-1 items-center rounded-full outline outline-1 text-white bg-[#0e1427] px-7 py-1 text-md normal-case"
              >
                Back to top
                <IoIosArrowUp className="w-5 h-5" />
              </button>
            </div>
          </div>
          <div className="flex my-6 justify-between md:hidden text-white  opacity-75  items-center w-full md:max-w-[40%] space-x-3  ">
            <p className="basis-[33%]">Privacy</p>
            <p className="basis-[33%] text-center">Terms & Condition</p>
            <p className="basis-[33%] justify-self-end text-right ">
              Legal Notice
            </p>
          </div>

          {/* .............bottom section.......... */}
          <div className="text-xs text-white opacity-75 flex mt-4  w-full space-x-3 mb-6 justify-center md:justify-start">
            <div className="flex items-center ">
              <MdCopyright />

              <p>{`DMM ${new Date().getFullYear()}.`}</p>
            </div>
            <p>All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
