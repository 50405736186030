import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../context/authContext";
import { useData } from "../../Hooks/useData";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Notifications = () => {
  const { state } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [readMore, setReadMore] = useState<any>();

  const { userData } = useData();

  const fetchNotifications = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/notification/getNotificationByUserId/${userData?.userId} `
      );

      setNotifications(data.data.reverse());
    } catch (error) {
      console.log("error ", error);
    }
  };
  useEffect(() => {
    fetchNotifications();
  }, [userData]);

  const handleReadMore = async (id: any) => {
    try {
      if (state?.userId) {
        const { data } = await axios.put(
          `${BASE_URL}/notification/updateNotification/${id}`
        );

        fetchNotifications();
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className=" bg-gray-200 flex py-4 justify-center  w-full  h-screen">
        <div className=" w-full md:rounded-full flex flex-col items-center  mx-auto max-w-[1280px] gap-4 p-2 flex-1 ">
          <div className=" w-full flex items-center justify-center">
            <h2 className=" font-bold ">Notifications</h2>
          </div>

          <div className=" mt-[10px] border rounded-md border-[#efcb76] w-full  lg:max-w-[50%] lg:p-[40px] p-5  h-full max-h-[80%] overflow-y-auto">
            {notifications?.map((val: any, i) => (
              <>
                <div className=" flex gap-2 items-center mt-2">
                  <div
                    className={` w-2 h-2 rounded-full border border-yellow-300 ${
                      val?.status === "READ" ? "bg-gray-400 " : " bg-red-800"
                    } `}
                  ></div>
                  <p
                    className={` text-lg ${
                      val?.status === "READ" ? "font-light  " : " font-bold "
                    } `}
                  >
                    {val?.title}
                  </p>
                  <p
                    className=" text-xs capitalize border-2 px-1 border-[#efcb76] rounded-lg cursor-pointer"
                    onClick={() => {
                      if (readMore === i) {
                        setReadMore(null);
                      } else {
                        setReadMore(i);
                        handleReadMore(val?._id);
                      }
                    }}
                  >
                    read more
                  </p>
                </div>

                <div>
                  {readMore === i && (
                    <div>
                      <p className=" ml-[20px]"> {val?.message}</p>
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
