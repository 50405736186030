export const data = [
  {
    id: 1,
    title: "Host details",
    fields: [
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "address",
      "companyDetails",
      "hostDateOfBirth",
      "passport",
      "idType",
      "validIDCard",
      "utilityBill",
    ],
  },
  {
    id: 2,
    title: "Car details",
    fields: [
      "carMake",
      "carModel",
      "carYear",
      "vehicleType",
      "registrationNumber",
      "canGuestDriveYourCar",
      "availableForInterState",
      "numberOfDoors",
      "carExteriorColor",
      "carInteriorColor",
      "specialFeatures",
      "estimatedCarValue",
      "numberOfPassengers",
      "location",
      "wasCarUpgraded",
      "upgradedYear",
    ],
  },
  {
    id: 3,
    title: "Car documents",
    fields: [
      "carVehicleInsurance",
      "carVehicleLicense",
      "proofOfOwnership",
      "roadWorthiness",
      "carImages",
      "shareMoreNotesAboutYourCar",
      "carVideo",
      "notes",
    ],
  },
  {
    id: 4,
    title: "Service & pricing",
    fields: [
      "availability",
      "daysAvailable",
      "noticePeriod",
      "selectedServices",
      "serviceTypes",
      "services",
      "allowOffer",
    ],
  },
  {
    id: 5,
    title: "Drivers details",
    fields: [
      "driverFirstName",
      "driverLastName",
      "driverPhoneNumber",
      "driverDateOfBirth",
      "driverStateOfOrigin",
      "driverLicense",
      "driverLicenseUpload",
      "driverPicture",
      "driverLanguages",
      "driverStatesFamiliar",
    ],
  },
  {
    id: 6,
    title: "Bank details",
    fields: ["accountName", "accountNum", "bankName", "sortCode"],
  },
  {
    id: 7,
    title: "Declaration",
    fields: [
      "myCarInACPerfectCondition",
      "myCarHasNoMechanicalOrElectricalIssue",
      "noCosmeticDamage",
      "functionalStereo",
      "functionalWindowWinders",
      "nonSmokingVehicle",
      "noUnusualSound",
      "servicedRegularly",
      "acknowledged",
      "readyForRental",
      "carNeedOtherFixing",
      "commentStateOfCar",
    ],
  },
];
