import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDataContext } from "../../providers/DataProvider";
import DmmDetails from "../../components/details/DmmDetails";
import { CarDetailsProps, initialCarState } from "../../utils/carDetailsProps";
import axios from "axios";
import { getDateStringFormat } from "../../utils/utilsFunctions";

const BASE_URL = process.env.REACT_APP_BASE_URL;

interface DetailsProps {
  handleDetailsClick?: () => void;
}

const Details: React.FC<DetailsProps> = ({ handleDetailsClick }) => {
  const { carCode } = useParams();
  const [driver, setDriver] = useState<any>({});
  const [carDetails, setCarDetails] =
    useState<CarDetailsProps>(initialCarState);
  const { listingDetails, listingData, setListingDetails, handleStatusChange } =
    useDataContext();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        const { data } = await axios.get(
          `${BASE_URL}/host/getCarByCarCode/${carCode}`
        );
        setCarDetails(data.data);
      } catch (error) {
        console.error("Error fetching car details:", error);
      }
    };

    const fetchDriver = async () => {
      try {
        if (carDetails?.carMakeID?._id && carDetails?.hostID?._id) {
          const response = await axios.get(
            `${BASE_URL}/host/getDriverDetails/${carDetails?._id}/${carDetails?.hostID?._id}`
          );
          console.log("fetch Driver---->", response.data);

          if (response) {
            setDriver(response.data.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (carCode) {
      fetchCarDetails();
      fetchDriver();
    }
  }, [carCode]);

  const car = listingData.find((car) => car.carCode === carCode);

  console.log(carDetails);

  return (
    <>
      <DmmDetails
        data={carDetails}
        price={carDetails?.serviceTypes[0]?.price}
        backToListingButton={true}
        onClick={() => {
          setListingDetails({ ...listingDetails, showDetails: false });
        }}
      >
        <div className="flex flex-1 flex-col w-full px-4 py-2 md:p-10 gap-4 font-light text-[13px] md:text-[15px]">
          <div className=" flex flex-col w-full gap-4">
            <div className="flex max-md:flex-col w-full">
              <p className="md:w-[20%] font-medium">Status</p>
              <p
                className={`md:w-[80%] font-medium ${
                  carDetails?.status ? "text-green-600" : "text-red-600"
                }`}
              >
                {carDetails?.status || "INACTIVE"}
              </p>
            </div>

            <hr />

            <div className="flex max-md:flex-col w-full">
              <p className="md:w-[20%] font-medium">Date</p>
              <p className="md:w-[80%]">
                {(carDetails?.createdAt &&
                  getDateStringFormat(carDetails?.createdAt)) ||
                  ""}
              </p>
            </div>

            <hr />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex max-md:flex-col w-full">
              <p className="md:w-[20%] font-medium">Production year</p>
              <p className="md:w-[80%]">{carDetails?.carMakeID?.year || ""}</p>
            </div>

            <hr />

            <div className="flex max-md:flex-col w-full mt-3 mb-3">
              <p className="md:w-[20%] font-medium">Features</p>
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 md:w-[80%]">
                {carDetails?.careSpecialFeature.map((val: any, idx: number) => (
                  <p key={idx} className="">
                    {val}
                  </p>
                ))}
              </div>
            </div>

            <hr />

            <div className="flex max-md:flex-col w-full">
              <p className="md:w-[20%] font-medium">Doors</p>
              <p className="md:w-[80%]">{`${
                carDetails?.numberOfDoors || 4
              } Doors`}</p>
            </div>

            <hr />

            <div className="flex max-md:flex-col">
              <p className="md:w-[20%] font-medium">Self Drive</p>
              <p className="md:w-[80%]">
                {carDetails?.canGuestDriveYourCar ? "Yes" : "No"}
              </p>
            </div>

            <hr />

            <div className="flex max-md:flex-col">
              <p className="md:w-[20%] font-medium">Driver Assigned</p>
              <p className="md:w-[80%]">
                {driver?.lastName && driver?.firstName
                  ? ` ${driver?.firstName} ${driver?.lastName}`
                  : "No"}
              </p>
            </div>

            <hr />
          </div>

          <p>
            {carDetails?.carNotes ||
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."}
          </p>
        </div>

        <>
          <p className="hidden md:flex text-white flex-1 text-[15px] w-full">
            <span className="underline ">Report</span>{" "}
            <span className="font-light ml-1">suspicious listings</span>
          </p>

          <div className="flex max-md:flex-col items-center w-full justify-end px-10 gap-4 flex-1">
            <button
              className="bg-white border rounded-full px-5 py-2 text-[14px] max-md:w-full min-w-[150px]"
              onClick={() =>
                navigate("/car/edit-list-vehicle", { state: carDetails })
              }
            >
              Edit
            </button>

            <button
              className="bg-white border rounded-full px-5 py-2 text-[14px] max-md:w-full min-w-[150px]"
              onClick={() => {
                navigate("assign-driver", { state: carDetails?._id });
              }}
            >
              Assign Driver
            </button>

            <div
              className={` border border-1 border-[#ADAFB0] rounded-full text-[12px] md:text-sm max-md:w-full min-w-[150px] h-[40px] p-[2px] bg-white text-center shadow-md flex ${
                car?.status === "ACTIVE" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`${
                  car?.status === "ACTIVE" ? "bg-green-600" : "bg-red-600"
                } w-[80%] rounded-full text-[#F4F4F4] cursor-pointer font-light flex justify-center items-center py-1 `}
                onClick={() => handleStatusChange(carDetails._id || "")}
              >
                {car?.status === "ACTIVE" ? "Active" : "Inactive"}
              </div>
            </div>
          </div>
        </>
      </DmmDetails>
    </>
  );
};

export default Details;
