import React, { useState } from "react";
import axios, { AxiosError } from "axios";
import CustomButton from "../elements/CustomButton";
import { ResendVerificationFormSchema } from "../../validations/formDataSchema";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Field from "../inputs/Field";

const BASE_URL = process.env.REACT_APP_BASE_URL;

type Inputs = z.infer<typeof ResendVerificationFormSchema>;

interface SignupModalProps {}

const ResendVerification: React.FC<SignupModalProps> = () => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(ResendVerificationFormSchema),
    mode: "onBlur",
  });

  const onInvalid = (errors: any) => console.error(errors);
  const [isResendVerificationloading, setIsResendVerificationLoading] =
    useState(false);
  const [axiosErrors, setAxiosErrors] = useState<any>("");
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);

  const handleResetVerification: SubmitHandler<Inputs> = async () => {
    const userEmail = watch("email");
    setIsResendVerificationLoading(true);
    setVerificationEmailSent(false);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/reSendVerificationLink/${userEmail}`
      );

      console.log("response", response);

      if (!response.data?.isError) {
        setVerificationEmailSent(true);
        setIsResendVerificationLoading(false);
      }
    } catch (error) {
      handleAxiosError(error);
      setIsResendVerificationLoading(false);
    }
  };

  const handleAxiosError = (error: any) => {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        setAxiosErrors((axiosError.response.data as any)?.message);
      } else if (axiosError.request) {
        setAxiosErrors("Request made but no response received");
      } else {
        setAxiosErrors("An unknown Axios error occurred");
      }
    } else {
      setAxiosErrors("An unknown error occurred");
    }
  };

  console.log("axiosErrors", axiosErrors);

  return (
    <>
      <form
        className="flex flex-col gap-3 h-full"
        onSubmit={handleSubmit(handleResetVerification, onInvalid)}
      >
        <div className="text-left my-2">
          <p className="text-[14px] font-light">Please enter your email</p>
        </div>

        <Field
          type="text"
          label="Email address"
          fieldName="email"
          errors={errors}
          control={control}
        />

        {!!axiosErrors && (
          <p className="text-[13px] text-red-700 mb-2">{axiosErrors}</p>
        )}

        <CustomButton
          loading={isResendVerificationloading}
          type="submit"
          className="w-full bg-[#0e1427] text-white py-3 hover:bg-gray-700 focus:outline-none rounded-full"
        >
          Resend Verification Email
        </CustomButton>
      </form>

      {verificationEmailSent && (
        <p className="pt-2 text-[14px] ">
          Verification email has been resent to your email.
        </p>
      )}
    </>
  );
};

export default ResendVerification;
