import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useState } from "react";

const DmmCalendarPicker = ({
  pickupTime,
  handleChange,
}: {
  pickupTime: string;
  handleChange: any;
}) => {
  const [open, setOpen] = useState(false);

  const handleIconClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div>
      <DateTimePicker
        value={dayjs(pickupTime)}
        disablePast
        open={open}
        onClose={() => setOpen(false)}
        onChange={handleChange}
        className="max-md:w-full"
        slots={{
          textField: TextField,
        }}
        slotProps={{
          textField: {
            variant: "standard",
            InputProps: {
              disableUnderline: true,
              // Use the dropdown icon here
              endAdornment: (
                <KeyboardArrowDown
                  onClick={handleIconClick}
                  className="cursor-pointer"
                />
              ),
              style: {
                border: "none",
              },
            },
            inputProps: {
              style: {
                padding: 0,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default DmmCalendarPicker;
